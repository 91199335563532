import {combineReducers} from 'redux';
import UsuarioReducer from './UsuarioReducer';
import CaminhoReducer from './CaminhoReducer';
import ContratoReducer from './ContratoReducer';
import FormandoReducer from './FormandoReducer';
import ItemReducer from './ItemReducer';
import ItemFormandoReducer from './ItemFormandoReducer';
import TutorialReducer from './TutorialReducer';
import AcaoReducer from './AcaoReducer';
import EmailReducer from './EmailReducer';
import ProdutoReducer from './ProdutoReducer';
import ConviteReducer from './ConviteReducer';
import ComentarioReducer from './ComentarioReducer';
import PublicacaoReducer from './PublicacaoReducer';
import InstagramReducer from './InstagramReducer';
import PaymentReducer from './PaymentReducer';
import PesquisaReducer from './PesquisaReducer';
import TurmaReducer from './TurmaReducer';

export default combineReducers({
  usuario: UsuarioReducer,
  caminho: CaminhoReducer,
  contrato:ContratoReducer,
  formando: FormandoReducer,
  item: ItemReducer,
  itemFormando:ItemFormandoReducer,
  instagram: InstagramReducer,
  tutorial: TutorialReducer,
  acoes:AcaoReducer,
  email: EmailReducer,
  produto: ProdutoReducer,
  convite: ConviteReducer,
  comentario: ComentarioReducer,
  publicacao: PublicacaoReducer,
  paymentInfo: PaymentReducer,
  pesquisa: PesquisaReducer,
  turmas: TurmaReducer
});
