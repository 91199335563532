import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import Joyride from 'react-joyride';

import {obterContratoPeloUsuario, editarCamposContrato} from '../actions/ContratoActions';
import {obterArrobasPeloContrato, editarArroba, adicionarArroba, deletarArroba} from '../actions/InstagramActions';
import {enviarEmail} from '../actions/EmailActions';
import {cadastrarAcao} from '../actions/AcaoActions';
import TabelaInstagram from '../components/TabelaInstagram';
import { Modal } from 'react-bootstrap';

class Instagram extends React.Component {
  
  state = {
    arrobas: [],
    arrobaSelecionado: {},
    run: false,
    showModalArroba: false,
    showModalExcluirArroba: false,
    steps: [
      {
        content: <div><h3>Bem-vindo(a) a página de cadastro dos perfis do Instagram!</h3> <span style={{textAlign: "center"}}>É aqui que você poderá cadastrar o(s) perfil(s) dos membros da comissão para divulgação do convite!</span></div>,
        placement: 'center',
        target: '#boxInstagram'
      },
      {
        content: <div><h3>Tabela de perfis cadastrados</h3> <span style={{textAlign: "center"}}>É aqui que você poderá ver quais os perfis cadastrados.</span></div>,
        placement: 'bottom',
        target: '#datatableInstagram'
      },
      {
        content: <div><h3>Botão de adição de perfil</h3> <span style={{textAlign: "center"}}>Clique aqui para adicionar um novo perfil.</span></div>,
        placement: 'bottom',
        target: '#btnAdicionarPerfil'
      }
    ]
  }

  async componentWillMount(){
    if (this.props.usuario.tipo === "comissao") {
      if (localStorage.getItem("tutorialInstagram") === null) {
        this.setState({run: true});
        localStorage.setItem("tutorialInstagram", true);
      }
      await this.props.obterContratoPeloUsuario(this.props.usuario.id);
      await this.iniciar();
    }
  }

  async iniciar () {
    await this.props.obterArrobasPeloContrato(this.props.contrato.id);
    if (this.props.arrobas.length > 0) {
      this.setState({arrobas: this.props.arrobas});
    }
  }

  handleClickEditar = (arrobaSelecionado) => {
    this.setState({arrobaSelecionado, showModalArroba: true});
  }
  
  handleClickExcluir = (arrobaSelecionado) => {
    this.setState({arrobaSelecionado, showModalExcluirArroba: true});
  }

  async handleArroba() {
    if (this.state.arrobaSelecionado.id) {
      await this.props.editarArroba(this.state.arrobaSelecionado);
      await this.setState({arrobaSelecionado: {}, showModalArroba: false});
      await this.iniciar();
    } else {
      let arroba = Object.assign({}, this.state.arrobaSelecionado);
      if (arroba.descricao !== undefined) {
        arroba.contrato = this.props.contrato.id;
        await this.props.adicionarArroba(arroba);
        await this.setState({arrobaSelecionado: {}, showModalArroba: false});
        await this.iniciar();
      }
    }
  }

  async removerArroba () {
    await this.props.deletarArroba(this.state.arrobaSelecionado.id);
    await this.setState({arrobaSelecionado: {}, showModalExcluirArroba: false});
    await this.iniciar();
  }

  render () {
    if (this.props.usuario.tipo === "formando") {
      return <Redirect to="/"/>;
    }
    return (
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="box box-solid">
            <div className="box-header">
              <div className="box-title">Instagram</div>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", width: "100%", paddingRight: 10}}>
              <button type="button" className="btn btn-success flat" id="btnAdicionarPerfil" onClick={() => this.setState({showModalArroba: true})}>Adicionar</button>
            </div>
            <div className="box-body" id="boxInstagram">
              <TabelaInstagram data={this.state.arrobas} editar={(arrobaSelecionado) => this.handleClickEditar(arrobaSelecionado)} excluir={(arrobaSelecionado) => this.handleClickExcluir(arrobaSelecionado)} />
            </div>
          </div>
          <Modal show={this.state.showModalArroba} onHide={() => this.setState({showModalArroba: false})}>
            <Modal.Header closeButton>
              <Modal.Title>Instagram</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <label htmlFor="arroba">Perfil do instagram</label>
                <input className="form-control" onChange={(e) => this.setState({arrobaSelecionado: {...this.state.arrobaSelecionado, descricao: e.target.value}})} value={this.state.arrobaSelecionado.descricao} type="text" />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-primary flat" data-dismiss="modal" onClick={this.handleArroba.bind(this)}>Salvar</button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showModalExcluirArroba} onHide={() => this.setState({showModalExcluirArroba: false})}>
            <Modal.Header closeButton>
              <Modal.Title>Remover instagram</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Tem certeza que deseja remover este perfil?
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.removerArroba.bind(this)}>Remover</button>
            </Modal.Footer>
          </Modal>
          <Joyride steps={this.state.steps}
            continuous={true}
            run={this.state.run}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={this.state.steps}
            styles={{
              options: {
                zIndex: 10000,
              },
          }}/>
        </div>
      </div>
      )
    }
  }
  const mapStateToProps = (state) => {
    return {      
      usuario: state.usuario.autenticado,
      contrato: state.contrato.especifico,
      arrobas: state.instagram.todos
    }
  }

  export default connect(mapStateToProps, {obterArrobasPeloContrato, editarArroba, adicionarArroba, deletarArroba, editarCamposContrato, cadastrarAcao, enviarEmail, obterContratoPeloUsuario})(Instagram);
