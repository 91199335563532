const INITIAL_STATE = {
  contract: null,
  client: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SAVE_CONTRACT':
      return {...state, contract: action.contract};
    case 'SAVE_CLIENT':
      return {...state, client: action.client};
    case 'REMOVE_CLIENT':
      return {...state, client: null};
    case 'REMOVE_CONTRACT':
      return {...state, contract: null};
    default:
      return state;
  }
};
