import axios from 'axios';
import { REQUEST_URL, URL_PAGAMENTO } from '../config/urls';
import EmailService from '../services/EmailService';

const requisitar = () => {
  return {
    type: "REQUISITAR_FORMANDO"
  };
}

const erro = (erro) => {
  return {
    type: "ERRO_FORMANDO",
    erro
  }
}

const obterTodos = (formandos) => {
  return {
    type: "OBTER_TODOS_FORMANDOS",
    formandos
  }
}

const obterPeloContrato = (formandos) =>{
  return {
    type: "OBTER_PELO_CONTRATO",
    formandos
  }
}

const novoFormando = (formando) => {
  return {
    type: "NOVO_FORMANDO",
    formando
  }
}

const alterarFormando = (formando) => {
  return {
    type: "ALTERAR_FORMANDO",
    formando
  }
}

const removerFormando = (id) => {
  return {
    type: "REMOVER_FORMANDO",
    id
  }
}

// FUNÇÕES EXPORTADAS

export const adicionarFormando = (formando) => {
  return dispatch => {
    dispatch(requisitar());
    formando.ip = sessionStorage.getItem("ip");
    return axios.post(`${REQUEST_URL}/formandos`, formando)
      .then((response) => {
      dispatch(novoFormando(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const adicionarFormandoECriarUsuario = (formando) => {
  let usuario = {
    login: formando.email,
    senha: Math.random().toString(36).slice(-8),
    nome: formando.nome,
    tipo: 'formando',
    email: formando.email
  }
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/usuarios`, usuario).then((response) => {
      formando.usuario = response.data.id;
      axios.post(`${REQUEST_URL}/formandos`, formando).then(() => {
        if (response.data.enviarLogin) {
          new EmailService().enviar("formando", {usuario: {nome: usuario.nome , email: usuario.email, senha: usuario.senha}}, false);
        }
      }).catch(error => {
        dispatch(erro(error.response.data.error));
      });
      dispatch(novoFormando(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const editarFormandoEUsuario = (formando) => {
  return dispatch => {
    dispatch(requisitar());
    formando.ip = sessionStorage.getItem("ip");
    return axios.patch(`${REQUEST_URL}/usuarios/${formando.usuario}`, {login: formando.email, email: formando.email}).then(() => {
      delete formando.usuario;
      axios.patch(`${REQUEST_URL}/formandos/${formando.id}`, formando).then((response) => {
        dispatch(alterarFormando(response.data));
      }).catch(error => {
        dispatch(erro(error.response.data.error));
      });
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const editarFormando = (formando) => {
  return dispatch => {
    dispatch(requisitar());
    formando.ip = sessionStorage.getItem("ip");
    return axios.patch(`${REQUEST_URL}/formandos/${formando.id}`, formando).then((response) => {
      dispatch(alterarFormando(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const editarFormandoEASAAS = (formando, numContrato) => {
  return dispatch => {
    dispatch(requisitar());
    formando.ip = sessionStorage.getItem("ip");
    return axios.patch(`${REQUEST_URL}/formandos/${formando.id}`, formando).then((response) => {
      axios.post(`${URL_PAGAMENTO}/formando/asaas`, {contrato: numContrato, formandoId: formando.id}, {headers: {"X-Token": sessionStorage.getItem("tokenMapaVendas")}})
      dispatch(alterarFormando(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const deletarFormando = (formandoId) => {
  return async dispatch => {
    dispatch(requisitar());
    await axios.delete(`${REQUEST_URL}/itens_formando?filter={"where":{"formando":${formandoId}}}`).catch(error => {console.log(error)});
    return axios.delete(`${REQUEST_URL}/formandos/${formandoId}`).then(() => {
      dispatch(removerFormando(formandoId));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const obterFormandos = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/formandos`).then((response) => {
      dispatch(obterTodos(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const obterFormandosPeloContrato = (contratoId) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/formandos?filter={"where":{"contrato":${contratoId}}, "include":["itens", "usuario"], "order":"nome ASC"}`).then((response) => {
      dispatch(obterPeloContrato(response.data));
    }).catch(error => {
      dispatch(erro(error));
    });
  }
}

export const editarCamposFormando = (id, formando) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/formandos/${id}`, formando).then((response) => {
      dispatch(alterarFormando(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}