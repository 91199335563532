import React, { useEffect, useState } from "react";

import Clipboard from "react-clipboard.js";
import axios from "axios";
import { toast } from "react-toastify";

import { URL_PAGAMENTO } from "../../config/urls";

export default function TabelaDetalhesCobranca (props) {

  const [carregando, setCarregando] = useState(true);
  const [cobranca, setCobranca] = useState(null);
  const [numParcelas, setNumParcelas] = useState(0);
  const [numParcelasPagas, setNumParcelasPagas] = useState(0);
  const [parcelasCobranca, setParcelasCobranca] = useState([]);
  const [base64, setBase64] = useState("");
  const [copiaCola, setCopiaCola] = useState("");

  useEffect (() => {
    setCobranca(props.pedidoSelecionado.cobranca);
    const cobranca = props.pedidoSelecionado.cobranca;
    setNumParcelas(cobranca.numParcelas);
    setNumParcelasPagas(cobranca.numParcelasPagas);

    if (cobranca.formaPagamento === "Boleto à vista" && !cobranca.cobrancaPaga) {
      getPix(cobranca.extRef, null);
    } else {
      setCarregando(false)
      // setNumParcelas(cobranca.parcelasCobranca.length);
      // const numParcelasPagas = cobranca.parcelasCobranca.filter((parcela) => {
      //   return parcela.dataPagamento != null;
      // }).length;
      // setNumParcelasPagas(numParcelasPagas);
      //
      // cobranca.parcelasCobranca.sort((a, b) => {
      //   return a.numero < b.numero;
      // });
      // setParcelasCobranca(cobranca.parcelas);
      // const numProxParcela = getNumProxParcela(cobranca.parcelas);
      // if (numProxParcela) {
      //   getPix(cobranca.extRef, numProxParcela)
      // } else {
      //   setCarregando(false)
      // }
    }
  }, [props]);

  async function getPix(cobrancaId, numProxParcela) {
    const response = await axios.post(`${URL_PAGAMENTO}/gerar-pix`, {
      cobrancaId: cobrancaId,
      numProxParcela: numProxParcela
    }, {headers: {
        "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
      }
    });

    if (response.data) {
      const data = response.data;
      setBase64(data.encodedImage);
      setCopiaCola(data.payload);
      setCarregando(false);
    }
  }

  function getNumProxParcela(parcelas) {
    let proxParcela = null
    for(let index = 0; index < parcelas.length; index++) {
      let parcela = parcelas[index];
      if(!parcela.pago && proxParcela == null) {
        proxParcela = index + 1;
      }
    }
    return proxParcela;
  }

  function splitReverseJoin (split, join, data) {
    return data.split(split).reverse().join(join);
  }

  return (
    <div>
      <h5>Informações para o pagamento:</h5>
      {
        cobranca != null ?
          <div className="row">
            {
              cobranca.parcelasCobranca.length === 0 ?
                <div className="col-sm-12 col-md-6">
                  <div className="box box-solid">
                    <div className="box-body">
                      {
                        props.pedidoSelecionado.status === "Pedido cancelado" ?
                          <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                            <img src="./images/error.png" alt="Erro" height="75"/>
                            <h4 style={{margin: "10px 0px"}}>Este pedido foi cancelado!</h4>
                          </div>
                          :
                          cobranca.valor > Math.ceil(cobranca.valorJaPago) ?
                            <React.Fragment>
                              {
                                carregando ?
                                  <div key="spinner"><i className="fa fa-spinner fa-spin"></i></div>
                                  : base64.length ?
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <h4><strong>1) Pague com o Pix:</strong></h4>
                                        <br/>
                                        <div key="pix-data">
                                          <ul style={{padding: "0px 18px"}}>
                                            <li>
                                              <h4 style={{marginBottom: 0}}>Escaneie o QRCODE com o aplicativo do seu banco</h4>
                                              <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                  <img src={`data:image/jpeg;base64,${base64}`} alt="QRCODE Pix" width="100%"/>
                                                </div>
                                              </div>
                                            </li>
                                            <li>
                                              <h4 style={{marginBottom: 5}}>Pague com o Pix Copia e Cola</h4>
                                              <div className="row">
                                                <div className="col-xs-8 col-sm-9" style={{paddingRight: 0}}>
                                                  <input type="text" className="form-control" value={copiaCola} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} readOnly />
                                                </div>
                                                <div className="col-xs-4 col-sm-3" style={{paddingLeft: 0}}>
                                                  <Clipboard className="btn btn-info btn-block" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} data-clipboard-text={copiaCola} onSuccess={() => toast.success("Copiado com sucesso!")}>
                                                    <i className="fas fa-clipboard"></i> Copiar
                                                  </Clipboard>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <br/>
                                    </div>
                                    : null
                              }
                              {
                                cobranca.valor >= 50 ?
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <h4><strong>2) Pague por boleto</strong></h4>
                                      <a href={cobranca.linkAcesso} target="_blank"><i className="fa fa-external-link-square-alt"></i> Clique para abrir o boleto</a>
                                    </div>
                                  </div>
                                  : null
                              }
                            </React.Fragment>
                            :
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                              <img src="./images/success.png" alt="Sucesso" height="75"/>
                              {
                                props.pedidoSelecionado.status === "Pagamento vinculado a comissão" ?
                                  <h4 style={{margin: "10px 0px"}}>Pagamento vinculado a comissão</h4>
                                  :
                                  <h4 style={{margin: "10px 0px"}}>Este pedido já está pago!</h4>
                              }
                            </div>
                      }
                    </div>
                  </div>
                </div>
                :
                null
            }

            <div className="col-sm-12 col-md-6">
              <div className="box box-solid">
                <div className="box-body">
                  <div className="table-responsive fixed">
                    <table className="table table-bordered table-striped">
                      <tbody>
                      <tr>
                        <th scope="row">Tipo de pagamento</th>
                        <td>{cobranca.formaPagamento}</td>
                      </tr>
                      <tr>
                        <th scope="row">Valor total</th>
                        <td>{props.formatarMoedaBr(cobranca.valor)}</td>
                      </tr>
                      <tr>
                        <th scope="row">Valor pago</th>
                        <td>{props.formatarMoedaBr(cobranca.valorJaPago)}</td>
                      </tr>
                      {
                        cobranca.formaPagamento !== "Boleto à vista" ?
                          <tr>
                            <th scope="row">Parcelas</th>
                            <td>{numParcelasPagas}/{numParcelas} {numParcelas === 1 ? "paga" : "pagas"}</td>
                          </tr>
                          : null
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {
              cobranca.parcelasCobranca.length > 0 ?
                <div className="col-sm-12 col-md-12">
                  <div className="box box-solid">
                    <div className="box-body">
                      <div className="table-responsive fixed">
                        <table className="table table-bordered table-striped">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Data de vencimento</th>
                            <th>Data de pagamento</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            parcelasCobranca.map((cobranca, i) =>
                              <tr key={i} style={{cursor: "pointer"}}>
                                <td>
                                  <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                    Parcela #{cobranca.numero}
                                    <a href={cobranca.linkAcesso} target="_blank"><i className="fa fa-external-link-square-alt"/> Clique para abrir o boleto</a>
                                  </div>
                                </td>
                                <td>
                                  <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                    {splitReverseJoin("-", "/", cobranca.dataVencimento)}
                                  </div>
                                </td>
                                <td>
                                  <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                    {cobranca.dataPagamento ? splitReverseJoin("/", "-", cobranca.dataPagamento) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
            }
          </div>
          :
          null
      }
    </div>
  );
}                