import React from 'react';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class TabelaItensContratados extends React.Component {

  state = {
    tabelaItens: false,
    itensTabela: {
      header: [],
      body: [],
      total: []
    }
  };

  async componentWillMount () {
    await this.criarHeaderTabelaItens();
    await this.criarBodyTabelaItens();
    await this.removerConviteDoNome();
    this.setState({tabelaItens: true});
  }

  checarHeadersTabelaItens (nome, id, quantidade) {
    let header = this.state.itensTabela.header,
      itemPesquisado = header.find((i) => {return nome === i.produto});
    id = Number(id);
    if (itemPesquisado === undefined || itemPesquisado.produto !== nome) {
      header.push({produto: nome, ids: [id], quantidade: quantidade});
    } else if (itemPesquisado.produto === nome) {
      header.forEach((item) => {
        if (item.produto === nome) {
          item.ids.push(id);
          item.quantidade += quantidade;
        }
      });
    }
    this.setState({itensTabela: {...this.state.itensTabela, header: header}});
  }

  criarBodyTabelaItens () {
    let { formandos } = this.props,
      { itens } = this.props.contrato,
      { header } = this.state.itensTabela,
      total = [];

    console.log(itens);

    header.forEach(() => total.push({quantidade: 0, brinde: 0}));
    formandos.forEach((formando) => {
      formando.itensTabela = [];
      header.forEach(() => formando.itensTabela.push({quantidade: 0, brinde: 0}));
      formando.itens.map((itemFormando) => {
        let itemPesquisado = itens.find((i) => { return i.id === itemFormando.item });
        let itensEmArray = itemPesquisado !== undefined ? itemPesquisado.itens.split(";") : [];

        itensEmArray.forEach((item) => {
          if (item !== "") {
            let itemQuantidade = Number(item.split(" ")[0]),
              itemProduto = item.substring(item.split(" ")[0].length + 1);
            header.forEach((produto, j) => {
              if (produto.produto === itemProduto) {
                let auxQtd = itemPesquisado.observacao === "kit" ? itemFormando.quantidade * itemQuantidade : itemFormando.quantidade;
                if (itemPesquisado.observacao === "brinde") {
                  formando.itensTabela[j].brinde += auxQtd;
                  total[j].brinde += auxQtd;
                } else {
                  formando.itensTabela[j].quantidade += auxQtd;
                  total[j].quantidade += auxQtd;
                }
              }
            });
          }
        });
      });
    });

    this.setState({itensTabela: {...this.state.itensTabela, body: formandos, total: total}});
  }

  async removerConviteDoNome() {
    let { header } = this.state.itensTabela,
      novoHeader = [];

    for (let i = 0; i < header.length; i++) {
      let item = header[i];
      novoHeader.push(item);
      if (item.produto.indexOf("Convite") !== -1) {
        novoHeader[i].produto = novoHeader[i].produto.replace("Convite ", "");
      }
      if (item.produto.indexOf("com") !== -1) {
        novoHeader[i].produto = novoHeader[i].produto.replace("com", "c/");
      }
      if (item.produto.indexOf("sem") !== -1) {
        novoHeader[i].produto = novoHeader[i].produto.replace("sem", "s/");
      }
      if (item.produto.indexOf("Aproveitamento") !== -1) {
        novoHeader[i].produto = novoHeader[i].produto.replace("Aproveitamento", "Aprov.");
      }
    }
    this.setState({itensTabela: {...this.state.itensTabela, header: novoHeader}});
  }

  async criarHeaderTabelaItens () {
    let { itens } = this.props.contrato;
    for (let i = 0; i < itens.length; i++) {
      let item = itens[i];
      if (item.observacao !== "brinde" && !item.produto.includes("Extra")) {
        if (item.observacao === "kit") {
          let auxItens = item.itens.split(";");
          for (let j = 0; j < auxItens.length; j++) {
            if (auxItens[j] !== "") {
              let nome = auxItens[j].substring(auxItens[j].split(" ")[0].length + 1)
              await this.checarHeadersTabelaItens(nome, item.id, item.quantidade * Number(auxItens[j].split(" ")[0]));
            }
          }
        } else {
          await this.checarHeadersTabelaItens(item.produto, item.id, item.quantidade);
        }
      }
    }
  }

  baixar (id) {
    let btn = document.querySelector(`#${id}`);

    if (btn) {
      btn.click();
    }
  }

  formandoPossuiPedidos (itens) {
    let quantidadeMaiorQueZero = false;

    itens.forEach((item) => {
      if (item.quantidade > 0) {
        quantidadeMaiorQueZero = true;
        return;
      }
    });

    return quantidadeMaiorQueZero;
  }

  _renderLabelStatusPagamentoFormando (formando) {
    let valor = 0,
      valorPago = 0,
      cor = "",
      texto = "",
      auxPedidos = formando !== undefined ? formando.pedidos.filter(p => { return p.status !== "Pedido cancelado" }) : [];

    if (auxPedidos.length > 0) {
      auxPedidos.forEach((pedido) => {
        valor += pedido.cobranca.valor;
        valorPago += Math.ceil(pedido.cobranca.valorJaPago);
      });

      if (valorPago >= valor) {
        texto = "pago";
        cor = "#00a65a";
      } else if (valorPago === 0) {
        texto = "não pagou";
        cor = "#dd4b39";
      } else {
        texto = "pagando";
        cor = "#f39c12";
      }

      return <label className="badge" style={{backgroundColor: cor, color: "white", fontSize: 12, marginLeft: 10}}>{texto}</label>;
    }

    return "";
  }

  _renderLabel (valor) {
    let cor = "green",
      texto = "+";

    if (valor > 0 ) {
      cor = "red";
      texto = "-";
    }

    return <label className={`badge bg-${cor}`}>{texto}{valor < 0 ? valor * -1 : valor}</label>;
  }

  _renderResumoContratoMae () {
    return (
      <div className="box box-solid">
        {
          this.state.tabelaItens ?
            <div key="wrapper-produtosContratoMae">
              <div className="box-header" style={{paddingBottom: 0}}><div className="box-title">Produtos do contrato mãe</div></div>
              <div className="box-body">
                <div className="table-responsive fixed">
                  <table className="table" id="tabelaResumo">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Produto</th>
                      <th>Qtd. contratada</th>
                      <th>Qtd. comprada</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.itensTabela.header.length > 0 ?
                        this.state.itensTabela.header.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.produto}</td>
                            <td>{item.quantidade}</td>
                            <td>
                              <div style={{display: "flex", justifyContent: "space-between"}}>
                                {
                                  this.state.itensTabela.total[i] ?
                                    <React.Fragment>
                                      {this.state.itensTabela.total[i].quantidade}
                                      {this._renderLabel(item.quantidade - this.state.itensTabela.total[i].quantidade)}
                                    </React.Fragment>
                                    : null
                                }
                              </div>
                            </td>
                          </tr>
                        ))
                        : <tr><td colSpan={2 + this.state.itensTabela.header.length} style={{ textAlign: "center" }}>Não existem formandos cadastrados!</td></tr>
                    }
                    </tbody>
                  </table>
                </div>
                <div style={{color: "#dd4b39", fontSize: 10}}>Atenção: os produtos podem sofrer reajuste de preços caso a quantidade definida em contrato não seja atingida.</div>
              </div>
            </div>
            : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px 0px" }}><i className="fa fa-spinner fa-spin" style={{marginRight: 5}}></i> Carregando os produtos do contrato</div>
        }
      </div>
    );
  }

  _renderTabelaCancelamento () {
    return (
      <div className="box box-solid">
        {
          this.state.tabelaItens ?
            <div key="wrapper-solicitacaoCancelamento">
              <div className="box-header" style={{paddingBottom: 0}}><div className="box-title">Solicitações de cancelamento</div></div>
              <div className="box-body">
                <div className="table-responsive fixed">
                  <table className="table" id="tabelaResumo">
                    <thead>
                    <tr>
                      <th>Item</th>
                      <th>Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.props.pedidos.length > 0 ?
                        this.props.pedidos.map((formando, i) => {
                          return formando.pedidos.map((pedido, j) => {
                            if (pedido.status === "Pedido em cancelamento") {
                              return (
                                <tr key={`f${i}${j}`}>
                                  <td style={{verticalAlign: "middle"}}>Pedido <strong>#{pedido.pedido}</strong> - {formando.nome}</td>
                                  {
                                    !this.props.contrato.dtAprPlanilha ?
                                      <td>
                                        <button type="button" className="btn btn-link" onClick={this.props.handleClickOpenFormandoEPedido(formando, pedido)}><i className="fa fa-receipt"></i> Ver pedido</button>
                                        <button type="button" className="btn btn-link" onClick={this.props.handleClickModalCancelamento(pedido.pedido, 1)}><i className="fa fa-check"></i> Aceitar</button>
                                        <button type="button" className="btn btn-link" onClick={this.props.handleClickModalCancelamento(pedido.pedido, 0)}><i className="fa fa-times"></i> Recusar</button>
                                      </td>
                                      : null
                                  }
                                </tr>
                              )
                            }
                          })
                        })
                        : <tr><td style={{ textAlign: "center" }}>Não existem solicitações de cancelamento!</td></tr>
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px 0px" }}><i className="fa fa-spinner fa-spin" style={{marginRight: 5}}></i> Carregando as solicitações de cancelamento</div>
        }
      </div>
    );
  }

  _renderTabelaQuantidade () {
    return (
      <div className="box box-solid">
        {
          this.state.tabelaItens ?
            <div key="wrapper-planilhaQuantidade">
              <ReactHTMLTableToExcel className="none" table="tabelaQuantidade" filename="Tabela de quantidades" sheet="tablexls" id="btnTabelaQuantidade" buttonText="download" />
              <div className="box-header" style={{paddingBottom: 0}}>
                <div className="box-title" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  Planilha de quantidades
                  <a className="btnSemFundo" onClick={this.baixar.bind(this, "btnTabelaQuantidade")} style={{fontSize: 14}}><i className="fa fa-download"></i> baixar</a>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive fixed">
                  <table className="table" id="tabelaQuantidade">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Formando</th>
                      { this.state.itensTabela.header.map((item, i) => <th key={i}>{item.produto}</th> ) }
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.itensTabela.body.length > 0 ?
                        this.state.itensTabela.body.map((formando, i) => (
                          <tr key={formando.id}>
                            <td>{i + 1}</td>
                            <td onClick={this.props.handleClickFormando(this.props.pedidos.filter(f => { return f.id === formando.id })[0])} style={{cursor: "pointer"}}>
                              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                  {formando.nome}
                                  {this.formandoPossuiPedidos(formando.itensTabela) ? <button type="button" style={{padding: 0, fontSize: 10}} className="btn btn-link">Clique para ver os pedidos</button> : null}
                                </div>
                                {this._renderLabelStatusPagamentoFormando(this.props.pedidos.filter(f => { return f.id === formando.id })[0])}
                              </div>
                            </td>
                            {
                              formando.itensTabela.map((item, j) =>
                                <td key={j} style={{verticalAlign: "middle"}}>
                                  {item.quantidade}
                                  {item.brinde > 0 ? <span style={{color: "red"}}> + {item.brinde} {item.brinde === 1 ? "brinde" : "brindes"}</span> : null}
                                </td>
                              )
                            }
                          </tr>
                        ))
                        : <tr><td colSpan={2 + this.state.itensTabela.header.length} style={{ textAlign: "center" }}>Não existem formandos cadastrados!</td></tr>
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px 0px" }}><i className="fa fa-spinner fa-spin" style={{marginRight: 5}}></i> Carregando a planilha de quantidades</div>
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-5">
            {this._renderResumoContratoMae()}
          </div>
          <div className="col-sm-12 col-md-7">
            {this._renderTabelaCancelamento()}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {this._renderTabelaQuantidade()}
          </div>
        </div>
      </div>
    )
  }
}

export default TabelaItensContratados;