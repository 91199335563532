import React from 'react';
import StarRatings from 'react-star-ratings';
import { connect } from 'react-redux';
import { cadastrarPesquisa } from '../actions/PesquisaAction';
import { toast } from 'react-toastify';
import EmailServices from '../services/EmailService';
import {EMAIL_PESQUISASATISFACAO} from '../config/urls';

class Pesquisa extends React.Component {
  state = {
    notaAtendimento: 0,
    notaDesign: 0,
    comentario: "",
    carregando: false
  };
  
  componentDidMount () {
    let starRatings = document.querySelectorAll(".star-ratings");
    starRatings.forEach((item) => {
      let starContainer = item.querySelectorAll(".star-container");
      starContainer.forEach((container, i) => {
        container.setAttribute("title", `${this.getTituloEstrela(i)}`);
      });
    })
  }

  getTituloEstrela (i) {
    switch (i) {
      case 0: 
        return "Ruim";
        break;
      case 1: 
        return "Bom";
        break;
      case 2: 
        return "Ótimo";
        break;
      case 3: 
        return "Excelente";
        break;
    }
  }

  changeRatingNotaAtendimento (newRating) {
    this.setState({notaAtendimento: newRating});
  }

  changeRatingNotaDesign (newRating) {
    this.setState({notaDesign: newRating});
  }

  finalizar () { 
    this.setState({carregando: true});
    if (this.props.tipoPublicacao === "Capa Dura" && this.state.notaDesign === 0) {
      toast.error("Responda a pesquisa para finalizar a revisão da publicação!");
      this.setState({carregando: false});
    } else if (this.props.tipoPublicacao === "Paginação" && (this.state.notaDesign === 0 || this.state.notaAtendimento === 0)) {
      toast.error("Responda a pesquisa para finalizar a revisão da publicação!");
      this.setState({carregando: false});
    } else {
      this.props.cadastrarPesquisa({notaAtendimento: this.state.notaAtendimento, notaDesign: this.state.notaDesign, publicacao: this.props.publicacaoId, comentario: this.state.comentario}).then((response) => {
        if ((this.props.tipoPublicacao === "Capa Dura" && this.state.notaDesign <= 2) || (this.props.tipoPublicacao === "Paginação" && (this.state.notaDesign <= 2 || this.state.notaAtendimento <= 2))) {
          new EmailServices().enviar("pesquisaSatisfacao", {numero: this.props.contrato, publicacaoId: this.props.publicacaoId, tipoPublicacao: this.props.tipoPublicacao, designer: this.props.designer, atendente: EMAIL_PESQUISASATISFACAO, notaLayout: this.state.notaDesign, notaAtendimento: this.state.notaAtendimento, comentario: this.state.comentario});
        }
        this.setState({carregando: false});
        this.props.pesquisaFinalizada();
      }).catch((erro) => {
        toast.error("Erro ao responder a pesquisa. Atualize a página e tente novamente!");
        this.setState({carregando: false});
      });
    }
  }

  render () {
    return (
      <div className="modalPesquisa">
        <h2>Avaliação Confidencial</h2>
        <h4>A sua avaliação é muito importante para nós! Avalíe-nos sem constrangimento.</h4>
        <br/>
        <strong>* 1. Até aqui, como você classificaria o layout do seu convite?</strong>
        <br/>
        <StarRatings
          rating={this.state.notaDesign}
          starRatedColor="rgb(255, 184, 108)"
          starHoverColor="rgb(255, 184, 108)"
          changeRating={this.changeRatingNotaDesign.bind(this)}
          numberOfStars={4}
          name='ratingNotaDesign'
        />
        <br/><br/>
        {
          this.props.tipoPublicacao === "Paginação" ?
            <div>
              <strong>* 2. Até aqui, como você classificaria o seu atendimento?</strong>
              <br/>
              <StarRatings
                rating={this.state.notaAtendimento}
                starRatedColor="rgb(255, 184, 108)"
                starHoverColor="rgb(255, 184, 108)"
                changeRating={this.changeRatingNotaAtendimento.bind(this)}
                numberOfStars={4}
                name='ratingNotaAtendimento'
              />
              <br/><br/>
            </div>
          : null
        }
        <label><strong>Utilize o campo abaixo para fazer qualquer comentário:</strong></label>
        <br/>
        <textarea rows="4" className="form-control form-group" value={this.state.comentario} onChange={(e) => {this.setState({comentario: e.target.value})}}/>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <button type="button" className="btn btn-success btn-flat" disabled={this.state.carregando} onClick={this.finalizar.bind(this)}> {this.state.carregando ? <span><i className="fas fa-spinner fa-pulse"></i> Enviando </span> : "Enviar"} </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pesquisa: state.pesquisa
  }
}

export default connect(mapStateToProps, {cadastrarPesquisa})(Pesquisa);
