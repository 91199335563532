import React from 'react';

const alerta = { marginBottom: '1rem', backgroundColor: '#fff', borderTop: '7px solid #ff4d4f', borderRadius: '5px', boxShadow: '0 1px 1px rgba(0,0,0,0.1)', padding: '10px' },
  titulo = { display: 'inline-block', fontSize: '18px', margin: '0', lineHeight: '1', marginBottom: '2rem' },
  mensagem = { display: 'inline-block', fontSize: '15px', margin: '0', lineHeight: '1', marginBottom: '1rem' };

export default class AlertaAprovacao extends React.Component {

  state = {
    aprovado: false
  }

  toggleChange = () => {
    this.setState({
      aprovado: !this.state.aprovado,
    });
  }

  render() {
    return (
      <div style={alerta}>
        <p style={titulo}><i className="fas fa-exclamation-triangle"></i> Confirmação da planilha de quantidades</p>
        <br/>
        <p style={mensagem}>Confirme as quantidades contratadas de cada formando, para o início da produção dos convites.</p>
        <br/>
        <div style={{display: "flex", alignItems: "center"}}>
          <div style={{marginRight: "10px"}} className="md-checkbox">
            <input id="aprovado" checked={this.state.aprovado} onChange={this.toggleChange} type="checkbox"/>
            <label htmlFor="aprovado">Estou de acordo!</label>
          </div>
          <button onClick={() => this.props.aprovar() }
                  disabled={!this.state.aprovado || this.props.progress} className="pure-material-button-contained">
            {
              this.props.progress ?
                <span><i className="fa fa-spinner fa-spin"/></span>
                :
                null
            } Confirmar
          </button>
        </div>
      </div>
    );
  }

}
