import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_ACAO"
  };
}

const novaAcao = (acao) => {
  return {
    type: "NOVA_ACAO",
    acao
  }
}
const obterAcoesContrato = (acoes) => {
  return {
    type: "OBTER_ACOES_CONTRATO",
    acoes
  }
}
const obterAcoesAtor = (acoes) => {
  return {
    type: "OBTER_ACOES_ATOR",
    acoes
  }
}
const alterarAcao = (acao) =>{
  return {
    type: "VISUALIZAR_ACAO",
    acao
  }
}
const obterAcoesAtorNV = (acoes) => {
  return {
    type: "OBTER_ACOES_ATOR_NV",
    acoes
  }
}
const erro = (erro) => {
  if(erro.statusCode===401){
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  }else{
    return {
      type: "ERRO_ACAO",
      erro
    }
  }
}

export const cadastrarAcao = (acao) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/acoes`, acao).then((response) => {
      dispatch(novaAcao(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterAcoesDoContrato = (contratoId) =>{
  return dispatch =>{
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/acoes?filter={"where":{"contrato":${contratoId}}}`).then((response) => {
      dispatch(obterAcoesContrato(response.data));
    }).catch(error => {
      dispatch(erro(error));
    });
  }
}
export const obterAcoesDoAtor = (atorId) =>{
  return dispatch =>{
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/acoes?filter={"where":{"ator":${atorId}}}`).then((response) => {
      dispatch(obterAcoesAtor(response.data));
    }).catch(error => {
      dispatch(erro(error));
    });
  }
}
export const obterAcoesDoAtorNaoVisualizado = (atorId) =>{
  return dispatch =>{
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/acoes?filter={"where":{"ator":${atorId}, "visualizado":false ,"tipo":"notificacao"}}`).then((response) => {
      dispatch(obterAcoesAtorNV(response.data));
    }).catch(error => {
      dispatch(erro(error));
    });
  }
}
export const visualizarAção = (id) => {
  let acao ={
    visualizado:true
  }
  return dispatch => {
    dispatch(requisitar());
      return axios.patch(`${REQUEST_URL}/acoes/${id}`, acao).then((response) => {
        dispatch(alterarAcao(response.data));
      }).catch(error => {
        dispatch(erro(error.response.data.error));
      });
  }
}
