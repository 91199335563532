import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

export default function TabelaDistribuicao (props) {

  const [data, setData] = useState([]);
  const [idFormando, setIdFormando] = useState(null);
  const [idItem, setIdItem] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [quantidadeAntiga, setQuantidadeAntiga] = useState(0);
  const [atualizarTabela, setAtualizarTabela] = useState(false);
  const [colHeaders, setColHeaders] = useState([]);

  useEffect (() => {
    setData(props.newData);
    setColHeaders(props.colHeaders);
  }, [props]);

  useEffect (() => {
    if (atualizarTabela) {
      let auxData = data;

      auxData.forEach((formandoEstado) => {
        if (formandoEstado.id === idFormando) {
          formandoEstado.itens.forEach((itemEstado) => {
            if (itemEstado.id === idItem) {
              itemEstado.quantidade = quantidade;
            }
          });
        }
      }); 
 
      setData(auxData);
      setAtualizarTabela(false);
    }
  }, [atualizarTabela]);

  function handleChangeQuantidade (formando, item, event) {
    const { value } = event.target,
      idItem = item.id,
      idFormando = formando.id,
      quantidadeAntiga = formando.itens.filter((itemFormando) => { return itemFormando.produto === item.produto })[0].quantidade;

    setIdFormando(idFormando);
    setIdItem(idItem);
    setQuantidade(Number(value));
    setQuantidadeAntiga(quantidadeAntiga);
    setAtualizarTabela(true);
  }

  function handleBlurQuantidade (formando, item, event) {
    const novaQuantidade = event.target.value;
    let itemSelecionado = colHeaders.filter((it) => { return item.produto === it.produto })[0],
      itemMontado = {
        id: item.id,
        formando: formando.id,
        item: item.idProduto,
        quantidade: novaQuantidade,
        preco: props.qualDadoCarregar === "extra" ? item.valorAdendo : item.preco,
        tipo: props.qualDadoCarregar
      };

    if ((itemSelecionado.qtd + quantidadeAntiga - Number(novaQuantidade)) >= 0) {
      if (itemMontado.id > 0 && novaQuantidade === "0") {
        props.atualizarTabela({item: itemMontado, funcao: "remover"});
      } else {
        if (itemMontado.id <= 0) {
          delete itemMontado.id;
        }
        if (novaQuantidade > "0") {
          props.atualizarTabela({item: itemMontado, funcao: "adicionar"});
        }
      }
    } else {
      setQuantidade(0);
      setAtualizarTabela(true);
      props.atualizarTabela({funcao: "atualizar"});
      toast.error("Você tentou distribuir uma quantidade superior a que está disponível!");
    }
  }

  return (
    <div className="table-responsive fixed">
      <table id="tabelaDistribuicaoItens" className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Formando</th>
            { 
              props.colHeaders.map((colHeader, i) => ( 
                <th key={i} title={colHeader.produto}>
                  <div style={{ padding: "0px 2px" }} className="alinhar-linha-around"> 
                    <div style={{ textTransform: "uppercase", fontSize: 10 }}>{colHeader.produto}</div>
                    <div style={{ marginLeft: 15 }}>{colHeader.qtd} </div>
                  </div>
                </th> 
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((formando, i) => (
              <tr key={i}>
                <td>{formando.nome}</td>
                {
                  formando.itens.map((item, j) => ( 
                    <td id="noPadding" key={j}>
                      {
                        props.block ?
                          <div className="inputNumberPersonalizado blocked">{item.quantidade}</div>
                        : <input type="number" className="inputNumberPersonalizado" value={item.quantidade} onChange={(e) => handleChangeQuantidade(formando, item, e)} onBlur={(e) => handleBlurQuantidade(formando, item, e)} />
                      }
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div> 
  );
}