import React from 'react';
import { connect } from 'react-redux';
import {
  descobrirContrato,
  obterContratoPeloUsuario,
} from '../actions/ContratoActions';
import BoxPublicacoes from '../components/BoxPublicacoes';

class AprovacaoConvite extends React.Component {
  state = {
    publicacoes: {
      paginacao: [],
      capaDura: [],
      embalagens: [],
      encarte: [],
      conviteSimples: [],
      senhaDeEntrada: [],
      missal: [],
      outrosProdutos: [],
      personalizadas: [],
    },
    historico: [],
  };

  componentWillMount() {
    this.setState({ buscando: true });

    if (this.props.autenticado.tipo === 'formando') {
      this.props.history.push('/');
    } else {
      this.props
        .descobrirContrato(
          this.props.autenticado.tipo,
          this.props.autenticado.id
        )
        .then(() => {
          let publicacoes = {
            paginacao: [],
            capaDura: [],
            embalagens: [],
            encarte: [],
            conviteSimples: [],
            senhaDeEntrada: [],
            missal: [],
            outrosProdutos: [],
            personalizadas: [],
          };
          this.props.contrato.publicacoes.forEach((publicacao) => {
            if (publicacao.visivel) {
              switch (publicacao.tipo) {
                case 'Paginação':
                  publicacoes.paginacao.push(publicacao);
                  break;
                case 'Capa Dura':
                  publicacoes.capaDura.push(publicacao);
                  break;
                case 'Embalagem':
                  publicacoes.embalagens.push(publicacao);
                  break;
                case 'Encarte':
                  publicacoes.encarte.push(publicacao);
                  break;
                case 'Convite Simples':
                  publicacoes.conviteSimples.push(publicacao);
                  break;
                case 'Senha de Entrada':
                  publicacoes.senhaDeEntrada.push(publicacao);
                  break;
                case 'Missal':
                  publicacoes.missal.push(publicacao);
                  break;
                case 'Outros Produtos':
                  publicacoes.outrosProdutos.push(publicacao);
                  break;
                case 'Personalizadas':
                  publicacoes.personalizadas.push(publicacao);
                  break;
                default:
                  publicacoes.outrosProdutos.push(publicacao);
              }
            }
          });
          publicacoes.paginacao = publicacoes.paginacao.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.capaDura = publicacoes.capaDura.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.embalagens = publicacoes.embalagens.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.encarte = publicacoes.encarte.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.conviteSimples = publicacoes.conviteSimples.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.senhaDeEntrada = publicacoes.senhaDeEntrada.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.missal = publicacoes.missal.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.outrosProdutos = publicacoes.outrosProdutos.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          publicacoes.personalizadas = publicacoes.personalizadas.sort(
            (a, b) =>
              new Date(b.dataPublicacao).getTime() -
              new Date(a.dataPublicacao).getTime()
          );
          this.setState({ publicacoes, buscando: false });
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.buscando ? (
          <span>
            <i className='fa fa-spin fa-spinner'></i> Carregando
          </span>
        ) : this.props.contrato.publicacoes.length === 0 ? (
          <div className='alert alert-info alert-dismissible'>
            <button
              type='button'
              className='close'
              data-dismiss='alert'
              aria-hidden='true'
            >
              ×
            </button>
            <h4>
              <i className='fa fa-info-circle'></i> Nada publicado por enquanto!
            </h4>
            Assim que o seu convite estiver ficando pronto você poderá aprová-lo
            aqui!
          </div>
        ) : this.props.autenticado.tipo === 'formando' &&
          this.state.publicacoes.personalizadas.length === 0 ? (
          <div className='alert alert-info alert-dismissible'>
            <button
              type='button'
              className='close'
              data-dismiss='alert'
              aria-hidden='true'
            >
              ×
            </button>
            <h4>
              <i className='fa fa-info-circle'></i> Nada publicado por enquanto!
            </h4>
            Assim que as suas páginas personalizadas ficarem prontas você poderá
            aprová-las aqui!
          </div>
        ) : null}
        <div className='row'>
          {this.state.publicacoes.paginacao.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.paginacao}
              tipo='paginacao'
              titulo='Paginação'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.capaDura.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.capaDura}
              tipo='capa-dura'
              titulo='Capa Dura'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.embalagens.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.embalagens}
              tipo='embalagens'
              titulo='Embalagens'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.encarte.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.encarte}
              tipo='encarte'
              titulo='Encarte'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.conviteSimples.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.conviteSimples}
              tipo='convite-simples'
              titulo='Convite Simples'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.senhaDeEntrada.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.senhaDeEntrada}
              tipo='senha-de-entrada'
              titulo='Senha de Entrada'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.missal.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.missal}
              titulo='Missal'
              tipo='missal'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.outrosProdutos.length > 0 &&
          this.props.autenticado.tipo === 'comissao' ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.outrosProdutos}
              tipo='outros-produtos'
              titulo='Outros Produtos'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
            />
          ) : null}
          {this.state.publicacoes.personalizadas.length > 0 ? (
            <BoxPublicacoes
              publicacoes={this.state.publicacoes.personalizadas}
              tipo='personalizadas'
              titulo='Personalizadas'
              historico={(publicacoes) =>
                this.setState({ historico: publicacoes })
              }
              tipoUsuario={this.props.autenticado.tipo}
            />
          ) : null}
          <div
            ref={(modal) => {
              this.modal = modal;
            }}
            className='modal fade in'
            id='modal-historico'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='close'
                  >
                    <i className='fa fa-times'></i>
                  </button>
                  <h4 className='modal-title'>Histórico de Publicações</h4>
                </div>
                <div className='modal-body'>
                  {this.state.historico.length === 0 ? (
                    <span>Não há publicações</span>
                  ) : (
                    <ul className='list-group'>
                      {this.state.historico
                        .sort(
                          (a, b) =>
                            new Date(a.dataPublicacao).getTime() +
                            new Date(b.dataPublicacao).getTime()
                        )
                        .map((publicacao) => (
                          <li key={publicacao.id} className='list-group-item'>
                            <a
                              style={{ cursor: 'pointer' }}
                              data-dismiss='modal'
                              onClick={() =>
                                this.props.history.push(
                                  `/publicacoes/historico/${publicacao.id}`
                                )
                              }
                            >
                              {new Date(
                                publicacao.dataPublicacao
                              ).toLocaleString()}
                              <i className='fa fa-arrow-circle-right pull-right'></i>
                            </a>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contrato: state.contrato.especifico,
    autenticado: state.usuario.autenticado,
    numeroContrato: state.usuario.numeroContrato,
  };
};

export default connect(mapStateToProps, {
  obterContratoPeloUsuario,
  descobrirContrato,
})(AprovacaoConvite);
