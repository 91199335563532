import React from 'react';
import {PageFlip, Page} from '../components/PageFlip';
import {Tabs, Tab} from 'react-bootstrap';
import Joyride from 'react-joyride';

export default class OrientacoesFotograficas extends React.Component {
  state = {
    paginas: [],
    run: false,
    steps: [
      {
        content: <div><h3>Bem-vindo(a) a página de orientações fotográficas!</h3> <span style={{textAlign: "center"}}>Preparamos alguns arquivos que vão te ajudar a nos enviar os arquivos corretamente.</span></div>,
        placement: 'center',
        target: '#divContent'
      }
    ]
  }
  
  componentWillMount () {
    if (localStorage.getItem("tutorialOrientacoesFotograficas") === null) {
      this.setState({run: true});
      localStorage.setItem("tutorialOrientacoesFotograficas", true);
    }
    let paginas = [];
    // Só existem 19 páginas, de 0 a 18
    for (let i = 0; i < 19; i++) {
      paginas.push(i);
    }
    this.setState({paginas});
  }

  componentDidMount () {
    try {
      window.recarregarPageFlip();
    } catch (e) {
      console.log("Este contrato não possui um convite disponível para a aprovação!");
    }
  }

  render () {
    return (
      <div>
        <div id="divContent">
          <h2><strong>Olá, Comissão de Formatura!</strong></h2>
          <h3 style={{textAlign: "justify"}}>Segue abaixo dois materiais que preparamos para orientar vocês na regulação do equipamento fotográfico, captação de imagens e do enquadramento correto de fotografias para aplicação em Convites de Formatura que nós adotamos. É importante seguir esses materiais para conseguirmos manter um padrão, tornando o convite mais harmônico e equilibrado.</h3>
          <br/>
          <div style={{backgroundColor: "white", padding: 15, borderRadius: 5, border: "1px solid #ddd"}}>
            <Tabs id="tab" defaultActiveKey="pdf">
              <Tab eventKey="pdf" title="PDF">
                <br/>
                <h4>Escolha como você gostaria de abrir o material:</h4>
                <ul>
                  <a style={{cursor: "pointer"}} href="" data-target="#modal-pageflip" data-toggle="modal"><li className="hidden-md hidden-sm hidden-xs">Visualizar aqui;</li></a>
                  <a style={{cursor: "pointer"}} href="https://drive.google.com/file/d/1l66GU30n1Gpjb1hliD70sOmgp3ZeYN6t/view?usp=sharing" target="_blank" rel="noopener noreferrer"><li className="hidden-lg">Visualizar;</li></a>
                  <a style={{cursor: "pointer"}} href="/pdfs/Orientações Fotográficas.pdf" download="Orientações Fotográficas.pdf"><li>Fazer o download.</li></a>
                </ul>
              </Tab>
              <Tab eventKey="video" title="Vídeo">
                <br/>
                <iframe title="iframeYoutube" width="100%" height="400px" src="https://www.youtube.com/embed/CA-ltw7Eqwo" frameBorder="0" allowFullScreen></iframe>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="modal fade in" id="modal-pageflip">
          <div className="modal-dialog modal-lg" style={{width:"90%"}}>
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="close">
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <PageFlip>
                  {
                    this.state.paginas.map((item, i) => {
                      return (
                        <Page key={i} isVegetal={false}>
                          <img alt="Página do pdf" style={{width: "100%"}} src={`https://alpha-agencia.s3-sa-east-1.amazonaws.com/orientacoes-fotograficas/${i}.jpg`} />
                        </Page>
                      )
                    })
                  }
                </PageFlip>
              </div>
            </div>
          </div>
        </div>
        <Joyride steps={this.state.steps}
          continuous={true}
          run={this.state.run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={this.state.steps}
          styles={{
            options: {
              zIndex: 10000,
            },
        }}/>
      </div>
    )
  }
}