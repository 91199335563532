import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_TUTORIAL"
  };
}



const erro = (erro) => {
    return {
      type: "ERRO_TUTORIAL",
      erro
    }
}


const obter = (tutorial) => {
  return {
    type: "OBTER_TUTORIAL",
    tutorial
  }
}

export const obterTutorial = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/tutoriais/findOne`).then((response) => {
      dispatch(obter(response.data));
    }).catch(error => {
      console.error(error);
      dispatch(erro(error));
    });
  }
}
