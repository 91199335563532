import React, {useEffect, useState} from "react";
import TabelaPedidosFormando from "./TabelaPedidosFormando";
import axios from "axios";
import {URL_PAGAMENTO} from "../../config/urls";

export default function PedidosPendentes(props) {

  const [progress, setProgress] = useState(false)

  useEffect(() => {}, [props])

  function formatarMoedaBr(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  const confirmarProducao = (pedidoID) => {
    if (window.confirm(`Você confirma a produção do pedido #${pedidoID}?`)) {
      setProgress(true)
      axios.post(`${URL_PAGAMENTO}/confirmar-producao`, {
        pedidoID: pedidoID
      }, {
        headers: {
          "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
        }
      })
        .then((response) => {
          props.refresh()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => setProgress(false))
    }
  }

  return (
    <div>
      <p>Há pedidos que ainda não foram pagos.</p>

      <p><strong>Como proceder?</strong></p>
      <ul>
        <li>Você pode solicitar o cancelamento do pedido com a Alpha.</li>
        <li>Se o formando ainda quiser continuar com a compra, você pode confirmar a produção do pedido.</li>
      </ul>

      {
        !progress && props.formandos.length > 0 ?
          <div className="row">
            {
              props.formandos.map((formando, index) =>
                <div key={index} className="col-md-12" style={{marginBottom: '1rem'}}>
                  <strong><p>{formando.nome} / <span style={{fontSize: 8}}>{formando.curso}</span></p></strong>
                  <TabelaPedidosFormando
                    handleClickPedido={props.abrirPedido}
                    formatarMoedaBr={formatarMoedaBr}
                    confirmarProducao={confirmarProducao}
                    data={formando.pedidos} />
                </div>
              )
            }
          </div>
          :
          <div>Aguarde um momento...</div>
      }
    </div>
  )

}