import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todos: [],
  carregando: false,
  erro: null,
  ultimo: null,
  todosDistribuiveis:[]
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_ITEM':
      return {...state, carregando: true};
    case 'ERRO_ITEM':
      toast.error(action.erro.message, 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_TODOS_ITENS':
      return {...state, carregando: false, todos: action.itens};
    case 'OBTER_TODOS_ITENS_DISTRIBUIVEIS':
      return {...state, carregando: false, todosDistribuiveis: action.itens};
    case 'NOVO_ITEM':
      toast.success("Produto(s) adicionados com sucesso!", 5000);
      return {...state, carregando: false, todos: state.todos.concat(action.item), ultimo: action.item};
    case 'ALTERAR_ITEM':
      return {...state, carregando: false};
    case 'REMOVER_ITEM':
      //toast.success("Item removido com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((item) => {return item.id!==action.id})};
    default:
      return state;
  }
};
