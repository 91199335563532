import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const enviar = () => {
  return {
    type: "ENVIAR_EMAIL"
  }
}

const enviado = () => {
  return {
    type: "ENVIADO_EMAIL"
  }
}

const erro = (erro) => {
  if(erro.statusCode===401){
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  }else{
    return {
      type: "ERRO_EMAIL",
      erro
    }
  }
}

export const enviarEmail = (email) => {
  return dispatch => {
    dispatch(enviar());
    return axios.post(`${REQUEST_URL}/email`, email).then((response) => {
      dispatch(enviado(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
