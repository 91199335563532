import React from 'react';

class Deletado extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  render(){
    return(
      <div style={{textAlign:"center"}}>
        <h3 style={{color: 'white'}}>Desculpe, este contrato foi deletado :(</h3>
      </div>
    )
  }
}
export default Deletado;
