import React from "react";

export default function TabelaPedidosFormando (props) {

  return (
    <div className="table-responsive fixed">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Tipo de pagamento</th>
            <th>Valor total</th>
            <th>Valor pago</th>
            {
              props.confirmarProducao !== undefined ?
                <th>Confirmar produção</th>
                :
                null
            }
          </tr>
        </thead>
        <tbody>
          {
            props.data.length > 0 ?
              props.data.map((pedido, i) => 
                <tr key={i} style={{cursor: "pointer"}}>
                  <td>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                      #{pedido.pedido}
                      <button type="button" style={{padding: 0, fontSize: 10}}
                              onClick={props.handleClickPedido(pedido)}
                              className="btn btn-link">
                        Clique aqui para abrir o pedido
                      </button>
                    </div>
                  </td>
                  <td>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      {pedido.cobranca.formaPagamento}
                      {
                        pedido.status === "Pedido cancelado" ?
                          <span style={{backgroundColor: "#dd4b39", borderRadius: 25, padding: "5px 10px", fontSize: 8, fontWeight: "bolder", color: "white"}}> CANCELADO</span>
                        : pedido.status === "Pedido em cancelamento" ?
                          <span style={{backgroundColor: "#f39c12", borderRadius: 25, padding: "5px 10px", fontSize: 8, fontWeight: "bolder", color: "white"}}> CANCELAMENTO SOLICITADO</span>
                        : null
                      }
                    </div>
                  </td>
                  <td>{props.formatarMoedaBr(pedido.cobranca.valor)}</td>
                  <td>{props.formatarMoedaBr(pedido.cobranca.valorJaPago)}</td>
                  {
                    props.confirmarProducao !== undefined ?
                      <td>
                        <button type="button" className="btn btn-link"
                                onClick={() => props.confirmarProducao(pedido.pedido)}>
                          Confirmar produção
                        </button>
                      </td>
                      :
                      null
                  }
                </tr>
              )
            : <tr><td colSpan={4} style={{textAlign: "center"}}>Este formando não realizou nenhuma compra</td></tr>
          }
        </tbody>
      </table>
    </div>
  );
}                