import axios from 'axios';
import { URL_TELEGRAMLOG_API } from '../config/urls';

export default class TelegramService {
  novo (type, messages) {
    let data = {"type": type, "messages": ["COMISSAO", ...messages]};
    delete axios.defaults.headers.common["Authorization"];
    axios.post(URL_TELEGRAMLOG_API, data);
    axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};
  }
}