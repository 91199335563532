import React from 'react';
import {connect} from 'react-redux';

import {obterPublicacao} from '../actions/PublicacaoActions';
import {obterContratoPeloUsuario} from '../actions/ContratoActions';
import {FTP_URL} from '../config/urls';

class HistoricoPublicacao extends React.Component {
  state = {
    pagina: 0,
    paginas: []
  }

  componentWillMount(){
    document.addEventListener('keydown', this.onKeyPress);
    this.props.obterPublicacao(this.props.match.params.id).then(() => {
      let paginas = this.props.publicacao.paginas;
      let tipos = {};
      paginas.forEach(pagina => {
        if(!tipos[pagina.tipo]){
          tipos[pagina.tipo] = [];
        }
        tipos[pagina.tipo].push(pagina);
      });
      let paginasOrdenadas = [];
      Object.values(tipos).forEach(paginasArray => {
        paginasArray.sort((a, b) => a.numero - b.numero);
        paginasOrdenadas = paginasOrdenadas.concat(paginasArray);
      });
      this.setState({paginas: paginasOrdenadas});
    });
  }

  componentDidUpdate(){
    let alfinetes = Array.from(document.querySelectorAll('.alfinete'));
    alfinetes.forEach(alfinete => {
      alfinete.parentNode.removeChild(alfinete);
    });
    this.state.paginas.forEach(pagina => {
      pagina.comentarios.forEach((comentario, i) => {
        let imagem = document.querySelector(`#pagina${pagina.id}`),
          wrapper = document.createElement('div'),
          alfinete = document.createElement('img');
        wrapper.style.position = "absolute";
        wrapper.style.left = `calc(${comentario.posX})`;
        wrapper.style.top = `calc(${comentario.posY})`;
        wrapper.style.fontFamily = "Arial, Helvetica, sans-serif";
        wrapper.innerHTML = `<p style="color: #bbb; background-color: rgba(0,0,0,1); padding: 2px 8px; border-radius: 25px; position: absolute">${i + 1}</p>`;
        alfinete.style.position = "absolute";
        alfinete.style.bottom = "0px";
        alfinete.style.left = "-2px";
        alfinete.src = "/images/pin-icon.png";
        alfinete.style.width = "25px";
        wrapper.classList.add("alfinete");
        wrapper.appendChild(alfinete);
        imagem.appendChild(wrapper);
      });
    });
  }

  proximaPagina(){
    if (this.state.pagina < this.state.paginas.length - 1) {
      this.setState({ pagina: this.state.pagina + 1 });
    }
  }

  voltarPagina(){
    if (this.state.pagina > 0) {
      this.setState({ pagina: this.state.pagina - 1 });
    }
  }

  onKeyPress = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        this.voltarPagina();
        break;
      case 'ArrowRight':
        this.proximaPagina();
        break;
    }
  }

  render(){
    const pagina = this.state.paginas[this.state.pagina] ? this.state.paginas[this.state.pagina] : null;

    return(
      <div className="box box-solid" style={{backgroundColor: "white", marginBottom: 0, boxShadow: "0 0 0 0"}}>
        <div className="box-body" style={{padding: "15px 0px"}}>
          <div className="col-xs-12 col-sm-12 col-md-8" style={{textAlign: "center", marginBottom: 25}}>
            {
              this.state.paginas.map((pagina, i) => (
                <div key={i} style={{padding: 0, display: pagina.id===this.state.paginas[this.state.pagina].id ? "" : "none"}}>
                  <h3 style={{marginTop: 0, marginBottom: 10, textTransform: "uppercase", fontWeight: 100}}>{`${pagina.tipo} - Página ${pagina.numero}`}</h3>
                  <div style={{display: 'flex', justifyContent: "center"}}>
                    <div id={`pagina${pagina ? pagina.id : ""}`} style={{width: "fit-content", height: "100%", position: "relative", boxShadow: "4px 4px 15px rgba(0, 0, 0, .24)"}}>
                      <img src={pagina ? `${FTP_URL}${pagina.publicacao}/${pagina.nome}` : null} style={{maxWidth: "100%", maxHeight: 600}} />
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4" style={{marginBottom: 25}}>
            <div style={{textAlign: "center", maxHeight: 650, display: "flex", flex: 1, flexDirection: "column", alignItems: "center", boxShadow: "4px 4px 15px rgba(0, 0, 0, 0.24)"}}>
              {
                pagina && !pagina.aprovada ?
                  pagina.comentarios.length === 0 ?
                    <h4>Página aberta para aprovação ou correções</h4>
                  : <React.Fragment>
                    <h3 style={{margin: "15px 0px 10px", fontWeight: 600}}>CORREÇÕES</h3>
                    <div style={{flex: 1, overflowY: "auto", width: "100%", height: "auto", maxHeight: 480, padding: "0px 20px"}}>
                      { 
                        pagina.comentarios.map((comentario, i) => (
                          <div key={i} className="box box-success" style={{display: "block !important", border: "0 none", borderRadius: 0, boxShadow: "0 0 0 0"}}>
                            <div className="box-header" style={{padding: "10px 10px 0px"}}>
                              <h3 className="box-title">Correção {i+1}</h3>
                              {
                                comentario.corrigido ?
                                  <div className="box-tools pull-right">
                                    <i className="fa fa-check" style={{color: "#00a65a"}}></i>
                                  </div>
                                : null
                              }
                            </div>
                            <div className="box-body" style={{padding: "0px 5px"}}>
                              <textarea style={{maxWidth: "100%", width: "100%", minWidth: "100%"}} disabled>{comentario.texto}</textarea>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </React.Fragment>
                : pagina && pagina.aprovada ?
                  <h4>Página aprovada em {new Date(pagina.dataAprovacao).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</h4>
                : null
              }
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div style={{width: "100%", display: "flex", flexDirection: "row", overflowX: "scroll", backgroundColor: "rgb(34, 45, 50)", padding: 15}}>
              { 
                this.state.paginas.map((pa, i) => (
                  <div key={i} style={{width: "auto", padding: 10, textAlign: "center"}}>
                    <div onClick={() => this.setState({pagina: i})} style={{position: "relative", height: 100, cursor: "pointer"}}>
                      {
                        pa.aprovada ?
                          <div style={{backgroundColor: "rgba(0, 0, 0, 0.6)", position: "absolute", left: `0`, top: `0`, height: "100%", width: "100%", zIndex: 2, transform: pa.id===this.state.paginas[this.state.pagina].id ? "scale(1.2)" :""}}>
                            <i className="fa fa-check" style={{color: "#00a65a", fontSize: 36, position: "absolute", left: "calc(50% - 18px)", top: "calc(50% - 18px)"}}></i>
                          </div>
                        : null
                      }
                      <img src={`${FTP_URL}${pa.publicacao}/${pa.nome}`} style={{borderWidth: 1, borderColor: "black", height: "100%",transform: pa.id===this.state.paginas[this.state.pagina].id ? "scale(1.2)" :""}} />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    publicacao: state.publicacao.especifica,
    contrato: state.contrato.especifico,
    autenticado: state.usuario.autenticado,
    publicacoes: state.publicacao.todas
  }
}

export default connect(mapStateToProps, {obterPublicacao, obterContratoPeloUsuario})(HistoricoPublicacao);
