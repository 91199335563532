import React from "react";

export default function TabelaItensPedido (props) {

  return (
    <React.Fragment>
      <h5>Itens adquiridos neste pedido:</h5>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="box box-solid">
            <div className="box-body">
              <div className="table-responsive fixed">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantidade</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      props.pedidoSelecionado.itens.length > 0 ?
                        props.pedidoSelecionado.itens.map((item, i) => 
                          <tr key={i}>
                            <td>{item.nome}</td>
                            <td>{item.quantidade}</td>
                            <td>{props.formatarMoedaBr(item.valor)}</td>
                          </tr>
                        )
                      : <tr><td colSpan={2} style={{textAlign: "center"}}>Este formando não realizou nenhuma compra</td></tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}