import React from 'react';

import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {Modal} from 'react-bootstrap';

import Preview from '../components/Preview';
import {PageFlip, Page} from '../components/PageFlip';
import Pesquisa from '../components/Pesquisa';
import {obterFormandosPeloContrato} from '../actions/FormandoActions';
import {obterPublicacoesPeloTipo, editarPublicacao} from '../actions/PublicacaoActions';
import {adicionarComentario, editarComentario, deletarComentario} from '../actions/ComentarioActions';
import {cadastrarAcao} from '../actions/AcaoActions';
import {descobrirContrato} from '../actions/ContratoActions';
import {aprovarPagina, estornarPagina} from '../actions/PaginaActions';
import EmailServices from '../services/EmailService';
import {FTP_URL} from '../config/urls';

class Publicacao extends React.Component {
  state = {
    pagina: 0,
    paginas: [],
    remover: [],
    editar: [],
    historico: {},
    paginaHistorico: null,
    carregando: false,
    carregandoAprovada: false,
    renderPageFlip:false,
    ultimaPub:{},
    lente: false,
    expandImagem: false,
    backgroundImage: "",
    backgroundPosition: '0% 0%',
    imagemSelecionada: "",
    salvar: false,
    renderizarParaCelulares: false,
    showModalPesquisa: false
  }

  componentWillMount(){
    document.addEventListener('keydown', this.onKeyPress);
    if (window.innerWidth < 768) {
      this.setState({renderizarParaCelulares: true});
    }
    this.obterPublicacoes();
  }

  componentDidMount () {
    window.recarregarPageFlip();
  }

  async obterPublicacoes(){
    await this.props.descobrirContrato(this.props.autenticado.tipo, this.props.autenticado.tipo === "formando" ? this.props.numeroContrato : this.props.autenticado.id).then(async () => {
      await this.props.obterFormandosPeloContrato(this.props.contrato.id);
      let tipo = "";
      switch(this.props.match.params.tipo){
        case 'paginacao':
          tipo = "Paginação";
          break;
        case 'capa-dura':
          tipo = "Capa Dura";
          break;
        case 'embalagens':
          tipo = "Embalagem";
          break;
        case 'encarte':
          tipo = "Encarte";
          break;
        case 'convite-simples':
          tipo = "Convite Simples";
          break;
        case 'senha-de-entrada':
          tipo = "Senha de Entrada";
          break;
        case 'missal':
          tipo = "Missal";
          break;
        case 'outros-produtos':
          tipo = "Outros Produtos";
          break;
        case 'personalizadas':
          tipo = "Personalizadas";
          break;
        default:
          tipo = "Outros Produtos";
          break;
      }
      await this.props.obterPublicacoesPeloTipo(tipo, this.props.contrato.id).then(async () => {
        let publicacoes = this.props.publicacoes,
          paginas = [],
          historico = {},
          todasPaginas = {};
        publicacoes = publicacoes.filter(publicacao => publicacao.visivel);
        publicacoes.sort((a, b)=>{return new Date(b.dataPublicacao).getTime() - new Date(a.dataPublicacao).getTime()});
        //salva qual a ultima publicação pra editar mais tarde
        this.setState({ultimaPub: publicacoes[0]});
        publicacoes.forEach((publicacao, i) => {
          publicacao.paginas.forEach(pagina => {
            if (!todasPaginas[pagina.nome]) {
              todasPaginas[pagina.nome] = [];
            }
            if (publicacao.bloqueada || publicacao.dataAprovacao) {
              pagina.bloqueada = true;
            } else {
              pagina.bloqueada = false;
            }
            todasPaginas[pagina.nome].push(pagina);
          });
        });

        let auxFormandos = this.props.formandos;

        auxFormandos.forEach((f) => { f.nps = f.nome; f.nps = f.nps.normalize('NFD').replace(/[\u0300-\u036f]/g, ""); });

        if (tipo === "Personalizadas" && this.props.autenticado.tipo === "formando") {
          let indexFormandoLogado = 0,
            auxTodasPaginas = {},
            formandos = await this.props.formandos.sort((a, b) => { return a.nome.localeCompare(b.nome)}),
            keysTodasPaginas = Object.keys(todasPaginas);

          keysTodasPaginas = keysTodasPaginas.sort((a, b) => { return a > b ? 1 : -1});

          formandos.forEach((formando, i) => {
            if (formando.usuario && formando.usuario.id === this.props.autenticado.id) {
              indexFormandoLogado = i;
            }
          });

          for (let i = indexFormandoLogado; i < keysTodasPaginas.length; i = i + formandos.length) {
            let key = keysTodasPaginas[i];
            auxTodasPaginas[key] = todasPaginas[key];
          }
          
          todasPaginas = auxTodasPaginas;
        }
        Object.values(todasPaginas).forEach(paginasArray => { paginas.push(paginasArray[0]) });
        for (let key in todasPaginas) {
          todasPaginas[key].shift();
        }
        for (let key in todasPaginas) {
          if (todasPaginas[key].length > 0) {
            historico[key] = todasPaginas[key];
          }
        }
        paginas.sort((a, b) => a.tipo - b.tipo);
        paginas.sort((a, b) => a.numero - b.numero);
        this.setState({paginas, historico}, () => {
          this.setState({renderPageFlip: true, exibirBotaoFinalizar: this.publicacaoAprovada()});
        });
      });
    });
  }

  componentDidUpdate(){
    let alfinetes = Array.from(document.querySelectorAll('.alfinete'));
    alfinetes.forEach(alfinete => {
      alfinete.parentNode.removeChild(alfinete);
    });
    this.state.paginas.forEach(pagina => {
      pagina.comentarios.forEach((comentario, i) => {
        let imagem = document.querySelector(`#pagina${pagina.id}`),
          wrapper = document.createElement('div'),
          alfinete = document.createElement('img'),
          self = this;
        wrapper.style.position = "absolute";
        wrapper.style.left = `calc(${comentario.posX})`;
        wrapper.style.top = `calc(${comentario.posY})`;
        wrapper.style.fontFamily = "Arial, Helvetica, sans-serif";
        wrapper.innerHTML = `<p style="color: #bbb; background-color: rgba(0,0,0,1); padding: 2px 8px; border-radius: 25px; position: absolute">${i + 1}</p>`;
        alfinete.style.position = "absolute";
        alfinete.style.bottom = "0px";
        alfinete.style.left = "-2px";
        alfinete.src = "/images/pin-icon.png";
        alfinete.style.width = "25px";
        wrapper.classList.add("alfinete");
        wrapper.appendChild(alfinete);
        imagem.appendChild(wrapper);
        alfinete.onclick = function () {
          if (self.state.ultimaPub.dataAprovacao === null) {
            self.setState({lente: true}, () => {
              self.refs[`${pagina.id}_${comentario.posX}_${comentario.posY}`].focus();
              self.refs[`${pagina.id}_${comentario.posX}_${comentario.posY}`].parentNode.parentNode.scrollIntoView({block: 'end', behavior: 'smooth'});
              self.refs[`${pagina.id}_${comentario.posX}_${comentario.posY}`].parentNode.parentNode.parentNode.style.zIndex = 5;
            });
          }
        }
      });
    });
    if (this.state.salvar) {
      this.setState({salvar: false});
      this.finalizarRevisao();
    }
  }

  adicionarMarcacao(event){
    if (!this.state.carregandoAprovada) {
      let pagina = this.state.paginas[this.state.pagina],
        paginas = this.state.paginas,
        posX = (event.nativeEvent.offsetX / event.target.offsetWidth) * 100 + "%",
        posY = (event.nativeEvent.offsetY / event.target.offsetHeight) * 100 + "%";
      if (this.state.ultimaPub.dataAprovacao === null) {
        pagina.comentarios.push({texto: "", posX, posY, pagina: pagina.id});
        for (let i = 0; i < paginas.length; i++) {
          if (paginas[i].id === pagina.id) {
            paginas[i] = pagina;
            break;
          }
        }
        this.setState({paginas, lente: true}, () => {
          this.setState({exibirBotaoFinalizar: this.publicacaoAprovada()});
          this.refs[`${pagina.id}_${posX}_${posY}`].focus();
          this.refs[`${pagina.id}_${posX}_${posY}`].parentNode.parentNode.scrollIntoView({block: 'end', behavior: 'smooth'});
          this.refs[`${pagina.id}_${posX}_${posY}`].parentNode.parentNode.parentNode.style.zIndex = 5;
        });
      }
    }
  }

  proximaPagina(){
    if(this.state.pagina<this.state.paginas.length-1)
      this.setState({pagina: this.state.pagina+1});
  }

  voltarPagina(){
    if(this.state.pagina>0)
      this.setState({pagina: this.state.pagina-1});
  }

  verificarSeExisteComentarios (paginas) {
    let valor = false;
    if (paginas.length > 0) {
      paginas.forEach(function (pagina) {
        if (pagina.comentarios.length > 0) {
          valor = true;
        } 
      });
      return valor;
    } 
  }

  verificarSeExistemPaginasNaoAprovadas (paginas) {
    let valor = false;
    if (paginas.length > 0) {
      paginas.forEach(function (pagina) {
        if (!pagina.aprovada) {
          valor = true;
        } 
      });
      return valor;
    } 
  }

  publicacaoAprovada () {
    let aprovada = true;
    let paginas = this.state.paginas;
    if (paginas.length > 0) {
      paginas.forEach(function (pagina) {
        if (!pagina.aprovada && pagina.comentarios.length === 0) {
          aprovada = false;
        }
      });
      return aprovada;
    }
  }

  getComentarioProps(comentario) {
    let comentarioEncontrado = null;
    this.props.comentarios.forEach((comentarioProps) => {
      if (comentarioProps.posX === comentario.posX && comentarioProps.posY === comentario.posY) {
        comentarioEncontrado = comentarioProps;
      }
    });
    return comentarioEncontrado;
  }

  finalizarRevisao () {
    this.setState({carregando: true});
    let numAprov = 0,
      naoAprov = [],
      paginas = this.state.paginas,
      existemComentarios = false;  
    paginas.forEach((pagina) => {
      if (pagina.comentarios && pagina.comentarios.length > 0) {
        existemComentarios = true;
      }
      if (pagina.aprovada || existemComentarios) {
        numAprov++;
      } else {
        naoAprov.push(pagina);
      }
    });
    if(numAprov === this.state.paginas.length) {
      let info = existemComentarios ? "aprovou parcialmente" : "aprovou", 
        editarPub = {dataAprovacao: new Date(), status: existemComentarios ? "aprovado com correções" : "aprovado", bloqueada: 1},
        acao = {
          ator: this.props.contrato.dono.id ,
          texto: `A comissão ${info} a sua publicação de ${this.state.ultimaPub.tipo} para o contrato ${this.props.contrato.numero}`,
          data: new Date(),
          contrato: this.props.contrato.id,
          tipo: "comissao",
          visualizado: false
        };
      new EmailServices().enviar("updateAcaoConviteComissaoToDesigner", {numero: this.props.contrato.numero, designer: {email: this.props.contrato.dono.email}, publicacao: {tipo: this.state.ultimaPub.tipo, info: info}});
      this.props.cadastrarAcao(acao);   
      this.props.editarPublicacao(this.state.ultimaPub.id, editarPub).then(()=>{      
        this.obterPublicacoes();
        toast.success("Alterações salvas com sucesso!", 5000);
        this.setState({carregando: false});
      }).catch(()=>{
        toast.success("Algo deu errado! Verifique sua conexão e tente novamente", 2000);
        this.setState({carregando: false});
      });
    }
  }

  onKeyPress = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        this.voltarPagina();
        break;
      case 'ArrowRight':
        this.proximaPagina();
        break;
    }
  }

  handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.pageX - left) / width * 100;
    const y = (e.pageY - top) / height * 100;
    this.setState({ backgroundPosition: `${x}% ${y}%`, backgroundImage: this.state.imagemSelecionada });
  }

  removerBackgroundImg () {
    document.querySelector("#figura-expandImg").style.backgroundImage = "";
  }

  adicionarBackgroundImg () {
    document.querySelector("#figura-expandImg").style.backgroundImage = this.state.imagemSelecionada;
  }

  aprovarPagina = async (pagina) => {
    this.setState({carregandoAprovada: true});
    
    let { paginas } = this.state;

    pagina.aprovada = true;
    pagina.dataAprovacao = new Date();
    
    for (let i = 0; i < paginas.length; i++) {
      if (paginas[i].id === pagina.id){
        paginas[i] = pagina;
        await this.props.aprovarPagina(pagina.id);
        break;
      }
    }

    this.setState({carregandoAprovada: false, exibirBotaoFinalizar: this.publicacaoAprovada()});
  }

  estornarPagina = async (pagina) => {
    this.setState({carregandoAprovada: true});

    let {paginas} = this.state;

    pagina.aprovada = false;
    pagina.dataAprovacao = null;
    
    for (let i = 0; i < paginas.length; i++) {
      if (paginas[i].id === pagina.id) {
        paginas[i] = pagina;
        await this.props.estornarPagina(pagina.id);
        break;
      }
    }

    this.setState({carregandoAprovada: false, exibirBotaoFinalizar: this.publicacaoAprovada()});
  }

  visualizarAlteracao = (pagina) => {
    let alfinetes = Array.from(document.querySelectorAll('.alfinete-historico'));
    alfinetes.forEach(alfinete => {
      alfinete.parentNode.removeChild(alfinete);
    });
    pagina.comentarios.forEach((comentario, i) => {
      let imagem = document.querySelector(`#wrapper-historico`),
        wrapper = document.createElement('div'),
        alfinete = document.createElement('img');
      wrapper.style.position = "absolute";
      wrapper.style.left = `calc(${comentario.posX})`;
      wrapper.style.top = `calc(${comentario.posY})`;
      wrapper.style.fontFamily = "Arial, Helvetica, sans-serif";
      wrapper.innerHTML = `<p style="color: #bbb; background-color: rgba(0,0,0,1); padding: 2px 8px; border-radius: 25px; position: absolute; top: -25px; left: 2px;">${i + 1}</p>`;
      alfinete.style.position = "absolute";
      alfinete.style.bottom = "18px";
      alfinete.style.left = "-2px";
      alfinete.src = "/images/pin-icon.png";
      alfinete.style.width = "25px";
      wrapper.classList.add("alfinete-historico");
      wrapper.appendChild(alfinete);
      imagem.appendChild(wrapper);
    });
    this.setState({paginaHistorico: pagina});
  }

  handleFocusComentarios (ref) {
    this.refs[ref].parentNode.parentNode.parentNode.style.zIndex = 5;
    this.refs[ref].parentNode.parentNode.scrollIntoView({block: 'end', behavior: 'smooth'});
    this.refs[ref].parentNode.querySelector("button").style.display = "block";
    this.setState({lente: true});
  }

  handleChangeComentarios (index, event) {
    let pagina = this.state.paginas[this.state.pagina],
      paginas = this.state.paginas;
    pagina.comentarios[index].data = new Date();
    pagina.comentarios[index].texto = event.target.value;
    for (let j = 0; j < paginas.length; j++) {
      if (paginas[j].id === pagina.id) {
        paginas[j] = pagina;
        break;
      }
    }
    this.setState({paginas, editar: pagina.comentarios[index]});
  }

  async handleClickConfirmarComentario (ref, pagina, comentario) {
    this.refs[ref].parentNode.parentNode.parentNode.style.zIndex = "initial";
    this.refs[ref].parentNode.querySelector("button").style.display = "none";
    this.setState({lente: false});
    if (this.state.editar.id) {
      this.props.editarComentario(this.state.editar, this.state.editar.id);
    } else {
      if (this.state.editar.posX) {
        await this.props.adicionarComentario(this.state.editar);
        pagina.comentarios.forEach((comentario) => {
          let comentarioEstado = this.props.comentarios[this.props.comentarios.length - 1];
          if (comentarioEstado && comentarioEstado.posX === comentario.posX && comentarioEstado.posY === comentario.posY) {
            comentario.id = comentarioEstado.id;
          }
        });
        let paginas = this.state.paginas;
        for (let j = 0; j < paginas.length; j++) {
          if (paginas[j].id === pagina.id) {
            paginas[j] = pagina;
            break;
          }
        }
        this.setState({paginas});
      }
    }
    if (comentario.texto.length === 0) {
      this.handleClickRemoverComentarios(pagina, comentario);
    }
  }

  handleClickRemoverComentarios (pagina, comentario) {
    let paginas = this.state.paginas;
    for (let j = 0; j < paginas.length; j++) {
      if (paginas[j].id === pagina.id) {
        pagina.comentarios.forEach((c) => {
          if (c === comentario) {
            pagina.comentarios = pagina.comentarios.filter(co => co !== c);
          }
        });
        paginas[j] = pagina;
        this.setState({paginas}, () => { 
          if (comentario.id !== undefined) {
            this.props.deletarComentario(comentario.id);
          } else {
            let removerComentario = this.getComentarioProps(comentario);
            if (removerComentario !== null) {
              this.props.deletarComentario(removerComentario.id);
            }
          }
        });
        break;
      }  
    }
    this.setState({exibirBotaoFinalizar: this.publicacaoAprovada(), lente: false});
  }

  apresentarPesquisaAntesDeFinalizar () {
    if ((this.state.ultimaPub.tipo === "Paginação" || this.state.ultimaPub.tipo === "Capa Dura") && !this.state.ultimaPub.pesquisas) {
      this.setState({showModalPesquisa: true});
    } else {
      this.setState({salvar: true});
    }
  }

  selecionarPagina (i) {
    this.fecharWrapPaginas();
    this.setState({pagina: i});
  }

  abrirWrapPaginas () {
    if (this.state.renderizarParaCelulares) {
      document.querySelector("#contentWrapPagina").style.height = "calc(100vh + 100.75px)";
      document.querySelector("#stuffWrapPagina").style.minheight = "35%";
      document.querySelector("#divWrapPagina").style.minHeight = "150px";
      document.querySelector("#conteudoPaginas").style.minHeight = "150px";
      document.querySelector("#divWrapPagina").style.height = "auto";
      document.querySelector("#conteudoPaginas").style.height = "auto";
    }
  }

  fecharWrapPaginas () {
    if (this.state.renderizarParaCelulares) {
      document.querySelector("#contentWrapPagina").style.height = "0px";
      document.querySelector("#stuffWrapPagina").style.height = "auto";
      document.querySelector("#divWrapPagina").style.minHeight = "0px";
      document.querySelector("#conteudoPaginas").style.minHeight = "0px";
      document.querySelector("#divWrapPagina").style.height = "0px";
      document.querySelector("#conteudoPaginas").style.height = "0px";
    }
  }

  _renderPaginas () {
    return (
      <div id="conteudoPaginas" style={{width: "100%", height: this.state.renderizarParaCelulares ? 0 : "auto", display: "flex", flexDirection: "row", overflowX: "auto", backgroundColor: "rgb(34, 45, 50)", padding: 15}}>
        {
          this.state.paginas.map((pa, i) => (
            <div key={i} style={{width: "auto", padding: 10, textAlign: "center"}}>
              <div onClick={this.selecionarPagina.bind(this, i)} style={{position: "relative", height: 100, cursor: "pointer"}}>
                {
                  pa.aprovada ?
                    <div style={{backgroundColor: "rgba(0, 0, 0, 0.6)", display: "flex", justifyContent: "center", position: "absolute", left: `0`, top: `0`, height: "100%", width: "100%", zIndex: 2, transform: pa.id===this.state.paginas[this.state.pagina].id ? "scale(1.2)" :""}}>
                      <i className="fas fa-check" style={{color: "#00a65a", width: 30, position: "absolute", display: "flex", justifyContent: "center", height: "100%", flexDirection: "row", alignItems: "center"}}></i>
                    </div>
                  : !pa.aprovada && pa.bloqueada ?
                    <div style={{backgroundColor: "rgba(0, 0, 0, 0.6)", display: "flex", justifyContent: "center", position: "absolute", left: `0`, top: `0`, height: "100%", width: "100%", zIndex: 2, transform: pa.id===this.state.paginas[this.state.pagina].id ? "scale(1.2)" :""}}>
                      <i className="fas fa-lock" style={{color: "#ccc", width: 30, position: "absolute", display: "flex", justifyContent: "center", height: "100%", flexDirection: "row", alignItems: "center"}}></i>
                    </div>
                  : pa.comentarios.length > 0 ?
                    <div style={{color: "#ccc", backgroundColor: "rgba(0, 0, 0, 0.8)", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", left: `0`, top: `0`, height: "100%", width: "100%", zIndex: 2, transform: pa.id===this.state.paginas[this.state.pagina].id ? "scale(1.2)" :""}}>
                      <span style={{fontWeight: 600, marginRight: 5, fontSize: 19, fontFamily: "sans-serif"}}>{pa.comentarios.length}</span> <i className="fas fa-pen"></i>
                    </div>
                  : null
                }
                <img alt="Página do convite" src={`${FTP_URL}${pa.publicacao}/${pa.nome}`} style={{borderWidth: 1, borderColor: "black", height: "100%",transform: pa.id===this.state.paginas[this.state.pagina].id ? "scale(1.2)" :""}} />
              </div>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                {
                  this.state.historico[pa.nome] ? this.state.historico[pa.nome].map((historico, i) => (
                    <button key={i} onClick={() => this.visualizarAlteracao(historico)} data-target="#historico" data-toggle="modal" type="button" className="btn btn-link btnLink" style={{color: "white", marginTop: 10}}><i className="fa fa-search"></i> Alteração {i+1}</button>
                  )) : null
                }
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  _renderAlert(){
    if (this.state.ultimaPub && this.state.ultimaPub.dataAprovacao) {
      return (
        <div className="alert alert-success alert-dismissible">
          <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
          Publicação aprovada! Você pode conferir abaixo a última versão dessa publicação.
        </div>
      )
    } else {
      return (
        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true" style={{marginBottom: 15}}>
          <div className="panel panel-primary" style={{borderRadius: 0}}>
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <div className="panel-heading" role="tab" id="headingOne" style={{backgroundColor: "#337ab7", borderRadius: 0}}>
                <h4 className="panel-title" style={{color: "white"}}><i className="fas fa-question-circle"></i> Como fazer a aprovação?</h4>
              </div>
            </a>
            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
              <div className="panel-body">
                <p>{this.props.autenticado.tipo === "formando" ? "Para aprovar as suas páginas personalizadas é só seguir as instruções abaixo:" : "Para aprovar o seu convite é só seguir as instruções abaixo:"}</p>
                <ol>
                  <li>Verifique se todos os dados e layout estão corretos.</li>
                  <small><em>Você pode ampliar a imagem clicando em <i className="fas fa-expand-arrows-alt"></i> ou <i className="fas fa-search-plus"></i>.</em></small>
                  <br/><small><em>Você pode visualizar o convite clicando em <i className="fas fa-book"></i>.</em></small>
                  <li>Se estiver tudo certo, clique no botão “Aprovar sem alterações”.</li>
                  <li>Se quiser solicitar alguma alteração, clique sobre imagem no local que deseja alterar. Uma caixa de texto aparecerá. Informe as alterações desejadas e clique no botão "Ok".</li>
                  <li>Todas as páginas deverão ser corrigidas ou aprovadas.</li>
                  {this.props.autenticado.tipo === "comissao" ? <li>Após aprovar ou corrigir todas as páginas, o botão "Finalizar revisão” aparecerá no canto inferior direito da página. Lembre-se de finalizar a revisão.</li> : null}
                </ol>
                <p><strong>IMPORTANTE: todas as informações contidas no convite são de responsabilidade da comissão de formatura. A Alpha não faz a revisão dos textos, nomes e fotos, então muita atenção nesse momento!</strong></p>
              </div>        
            </div>
          </div>
        </div>
      )
    }
  }

  render(){
    const pagina = this.state.paginas[this.state.pagina] ? this.state.paginas[this.state.pagina] : null;
    if (this.state.paginas.length === 0) {
      return <span><i className="fa fa-spin fa-spinner"></i> Carregando</span>
    }
    return (
      <div style={{position: "relative"}}>
        {this._renderAlert()}
        <div className="box box-solid" style={{position: "relative", marginBottom: 0, backgroundColor: this.state.renderizarParaCelulares ? "transparent" : "white", boxShadow: "0 0 0 0"}}>
          <div className="box-body" style={{padding: "15px 0px", margin: this.state.renderizarParaCelulares ? "0px -15px" : 0}}>
            <div className="col-xs-12 col-sm-12 col-md-8" style={{textAlign: "center", marginBottom: 25}}>
              {
                this.state.paginas.map((pagina, i) => (
                  <div key={i} onMouseEnter={() => {this.setState({imagemSelecionada: `url(${FTP_URL}${pagina.publicacao}/${pagina.nome})`})}} style={{padding: "0px", display: pagina.id === this.state.paginas[this.state.pagina].id ? "" : "none"}}>
                    <h3 style={{marginTop: 0, marginBottom: 10, textTransform: "uppercase", fontWeight: 100}}>{`${pagina.tipo} - Página ${pagina.numero}`}</h3>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <div style={{width: "fit-content", boxShadow: "4px 4px 15px rgba(0, 0, 0, .24)"}}>
                        <div id={`pagina${pagina ? pagina.id : ""}`} style={{width: "fit-content", textAlign: "center", height: "100%", position: "relative"}}>
                          <img alt="Página do convite" src={pagina ? `${FTP_URL}${pagina.publicacao}/${pagina.nome}` : null} style={{maxWidth: "100%", maxHeight: 600}} onClick={!pagina.aprovada && !pagina.bloqueada ? this.adicionarMarcacao.bind(this) : () => {}} />
                        </div>
                      </div>
                    </div>
                    <i className="fas fa-expand-arrows-alt" data-target="#expand" data-toggle="modal" style={{color: "#333", fontSize: 18, marginTop: 8, cursor: "pointer"}}></i>
                    <i className="fas fa-search-plus hidden-xs hidden-sm" data-target="#expandImg" data-toggle="modal" style={{color: "#333", fontSize: 18, marginTop: 8, cursor: "pointer", marginLeft: 25}}></i>
                    <i className="fas fa-book hidden-md hidden-sm hidden-xs" data-target="#modal-pageflip" data-toggle="modal" style={{color: "#333", fontSize: 18, marginTop: 8, cursor: "pointer", marginLeft: 25}}></i>
                  </div>
                ))
              }
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4" style={{position: "relative", marginBottom: 25}}>
              <div style={{textAlign: "center", backgroundColor: "white", maxHeight: 650, display: "flex", flex: 1, flexDirection: "column", alignItems: "center", boxShadow: this.state.renderizarParaCelulares ? "0 1px 1px rgba(0,0,0,.05)" : "4px 4px 15px rgba(0,0,0,.24)"}}>
                <div style={{width: "100%"}}>
                  <div style={{height: (pagina.comentarios.length > 0 ? 0 : 25)}}></div>
                  {
                    !pagina.bloqueada && pagina.comentarios.length === 0 && !this.state.ultimaPub.dataAprovacao ?
                      !pagina.aprovada ?
                        <button onClick={() => this.aprovarPagina(pagina)} className="btn btn-success btn-flat" disabled={this.state.carregandoAprovada} style={{marginTop: "-12px", marginBottom: "12px"}}>
                          {this.state.carregandoAprovada ? <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Estornando</span> : "Aprovar sem alterações"}
                        </button>
                      : <button onClick={() => this.estornarPagina(pagina)} className="btn btn-success btn-flat" disabled={this.state.carregandoAprovada} style={{marginTop: "-12px", marginBottom: "12px"}}>
                        {this.state.carregandoAprovada ? <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Aprovando</span> : "Estornar aprovação"}
                      </button>
                    : <div>
                      {
                        pagina && pagina.comentarios.length === 0 && pagina.aprovada ?
                          <h4 style={{margin: "-12px 0px 12px"}}>Página aprovada em {new Date(pagina.dataAprovacao).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</h4>
                        : pagina && !pagina.aprovada ? 
                          <div>
                            <h3 style={{margin: "15px 0px 10px", fontWeight: 600}}>CORREÇÕES</h3>
                            <div style={{flex: 1, overflowY: "auto", width: "100%", height: "auto", maxHeight: 480, padding: "0px 20px"}}>
                              {
                                pagina.comentarios.map((comentario, i) => (
                                  <div key={i} className="box box-success" style={{display: "block !important", border: "0 none", borderRadius: 0, boxShadow: "0 0 0 0"}}>
                                    <div className="box-header" style={{padding: "10px 10px 0px"}}>
                                      <h3 className="box-title">Correção {i+1}</h3>
                                      <div className="box-tools pull-right">
                                        {
                                          this.state.ultimaPub.dataAprovacao === null && !pagina.bloqueada ?
                                            <button className="btn btn-box-tool" onClick={this.handleClickRemoverComentarios.bind(this, pagina, comentario)}><i className="fa fa-times"></i></button>
                                          : null
                                        }
                                      </div>
                                    </div>
                                    <div className="box-body" style={{padding: "0px 5px"}}>
                                      {
                                        this.state.ultimaPub.dataAprovacao === null && !pagina.bloqueada ? 
                                          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                            <textarea value={comentario.texto} style={{maxWidth: "100%", width: "100%", minWidth: "100%"}}
                                              ref={`${pagina.id}_${comentario.posX}_${comentario.posY}`} 
                                              onChange={this.handleChangeComentarios.bind(this, i)}
                                              onFocus={this.handleFocusComentarios.bind(this, `${pagina.id}_${comentario.posX}_${comentario.posY}`)} 
                                            />
                                            <button className="btn btn-success btn-sm" style={{display: "none", margin: "10px auto"}} id={"btnFecharComentario" + i} onClick={this.handleClickConfirmarComentario.bind(this, `${pagina.id}_${comentario.posX}_${comentario.posY}`, pagina, comentario)}>OK</button>
                                          </div>
                                        : <div><textarea ref={`${pagina.id}_${comentario.posX}_${comentario.posY}`} disabled value={comentario.texto} style={{maxWidth: "100%", width: "100%", minWidth: "100%"}}/></div>
                                      }
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        : null
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
            {
              !this.state.renderizarParaCelulares ?
                <div className="col-xs-12 col-md-12 col-sm-12">{this._renderPaginas()}</div>
              : null
            }
          </div>
          {
            this.props.autenticado.tipo === "comissao" ?
              <div>
                { 
                  this.state.exibirBotaoFinalizar ?
                    this.state.ultimaPub && !this.state.ultimaPub.dataAprovacao ? 
                      <button className="pull-right btn btn-flat btn-danger btn-lg botaoFinalizar" style={{zIndex: 3, boxShadow: "0px 3px 15px rgba(0,0,0,.4)"}} disabled={this.state.carregando || this.state.carregandoAprovada} data-toggle="modal" data-target="#modal-confirmacao">
                        {this.state.carregando ? <span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Finalizando</span> : "Finalizar revisão"} 
                      </button>       
                    //: this.state.ultimaPub.bloqueada ?
                    //  <button className="pull-right btn btn-flat btn-danger btn-lg botaoFinalizar" style={{zIndex: 3}} disabled>Página bloqueada</button>
                    : null
                  : null
                }
              </div>
            : null
          }
          <Preview paginas={this.state.paginas} />
          <div className="modal fade in" id="historico">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="close">
                    <i className="fa fa-times"></i>
                  </button>
                  <h4 className="modal-title">Alterações</h4>
                </div>
                <div className="modal-body">
                  <div>
                    <div id="wrapper-historico" style={{position: "relative"}}>
                      <img alt="Página do convite" style={{width: "100%", maxHeight: 430}} src={this.state.paginaHistorico ? `${FTP_URL}${this.state.paginaHistorico.publicacao}/${this.state.paginaHistorico.nome}` : ""}/>
                    </div>
                    <h3>Correções Solicitadas</h3>
                    <ul className="list-group">
                      {
                        this.state.paginaHistorico && this.state.paginaHistorico.comentarios.length>0 ? this.state.paginaHistorico.comentarios.map((comentario, i) => (
                          <li key={comentario.id} className="list-group-item">{i+1} - {comentario.texto}</li>
                        )) : null
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade in" id="expand">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <span style={{fontSize: 20}}>{`${pagina.tipo} - Página ${pagina.numero}`}</span>
                  <button type="button" className="close" data-dismiss="modal" aria-label="close">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="">
                  {
                    this.state.paginas.map((pagina, i) => (
                      <div key={i} style={{backgroundColor: "#222d32", display: pagina.id===this.state.paginas[this.state.pagina].id ? "" : "none"}}>
                        <div style={{display: 'flex', flex: 1, justifyContent: "center", flexDirection: 'row', alignItems: "center"}}>
                          <div id={`pagina${pagina ? pagina.id : ""}`} style={{width: "fit-content", textAlign: "center", height: "100%", position: "relative"}}>
                            <img alt="Página do convite" src={pagina ? `${FTP_URL}${pagina.publicacao}/${pagina.nome}` : null} style={{maxWidth: "100%"}} />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade in" id="expandImg" style={{padding: 0}}>
            <div className="modal-dialog modal-lg" style={{width:"100vw", height: "100vh", display: "flex", flexDirection: "column", padding: "30px", margin: 0}}>
              <div style={{display: "flex", justifyContent: "space-between", marginBottom: 30}}>
                <h3 style={{color: "#fff", fontWeight: 600}}>{`${pagina.tipo} - Página ${pagina.numero}`}</h3>
                <button type="button" className="close" data-dismiss="modal" aria-label="close" style={{color: "#fff", opacity: 1}}><i className="fa fa-times"></i></button>
              </div>
              <h5 style={{textAlign: "center", color: "#fff"}}>Passe o mouse pela imagem</h5>
              <figure id="figura-expandImg" onMouseEnter={this.adicionarBackgroundImg.bind(this)} onMouseLeave={this.removerBackgroundImg.bind(this)} onMouseMove={this.handleMouseMove.bind(this)} style={{backgroundImage: this.state.backgroundImage, backgroundPosition: this.state.backgroundPosition}}>
                <img src={`${FTP_URL}${pagina.publicacao}/${pagina.nome}`}/>
              </figure>
            </div>
          </div>
          <div className="modal fade in" id="modal-pageflip">
            <div className="modal-dialog modal-lg" style={{width:"90%"}}>
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="close">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="modal-body">
                  {
                  this.state.renderPageFlip ?
                  <PageFlip>
                    {
                      this.state.paginas.map((pagina, i) => {
                        if(pagina.tipo === "Vegetal"){
                          return [
                            <Page key={i} isVegetal={true}>
                              <img alt="Página do convite" style={{width:"100%",opacity: 0.8}} src={pagina ? `${FTP_URL}${pagina.publicacao}/${pagina.nome}` : null} />
                            </Page>,
                            <Page key={i+"_1"} isVegetal={true}>
                              <img alt="Página do convite" style={{width:"100%", WebkitTransform: "scaleX(-1)", transform: "scaleX(-1)", filter: "FlipH", msFilter: "FlipH", opacity: 0.8}} src={pagina ? `${FTP_URL}${pagina.publicacao}/${pagina.nome}` : null} />
                            </Page>
                          ]
                        }else{
                          return (
                            <Page key={i} isVegetal={false}>
                              <img alt="Página do convite" style={{width:"100%"}} src={pagina ? `${FTP_URL}${pagina.publicacao}/${pagina.nome}` : null} />
                            </Page>
                          )
                        }
                      })
                    }
                  </PageFlip>
                  :<div>Aguarde...</div>
                }
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade in" id="modal-confirmacao">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="close">
                    <i className="fa fa-times"></i>
                  </button>
                  <h4 className="modal-title">Confirmação</h4>
                </div>
                <div className="modal-body">
                  <p>Depois de finalizar a revisão você não poderá mais fazer nenhuma alteração. Tem certeza que deseja finalizar?</p>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-flat btn-warning pull-right" data-dismiss="modal" onClick={this.apresentarPesquisaAntesDeFinalizar.bind(this)}>Confirmar</button>
                  <button className="btn btn-flat pull-left" data-dismiss="modal">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.showModalPesquisa !== false} dialogClassName="modal-40w">
            <Modal.Body>
              <Pesquisa 
                publicacaoId={this.state.ultimaPub.id} 
                tipoPublicacao={this.state.ultimaPub.tipo}
                contrato={this.props.contrato.numero} 
                designer={this.props.contrato.dono.nome} 
                pesquisaFinalizada={() => {this.setState({salvar: true, showModalPesquisa: false})}} 
              />
            </Modal.Body>
          </Modal>
        </div>
        {
          this.state.renderizarParaCelulares ? 
            <React.Fragment>
              <div id="contentWrapPagina" style={{width: "100vw", height: 0, backgroundColor: "rgba(0,0,0,.9)", position: "fixed", zIndex: 999, margin: "0px -15px", top: -100.75, display: "flex", alignItems: "flex-end"}}>
                <div id="stuffWrapPagina" style={{width: "100%", transition: ".5s", bottom: 0, display: "flex", alignItems: "flex-end", justifyContent: "flex-end", flexDirection: "column"}}>
                  <div onClick={this.fecharWrapPaginas.bind(this)}><i className="fa fa-times" style={{width: 35, height: 35, fontWeight: 600, marginRight: 10, transition: ".5s", color: "white", border: "1px solid white", borderRadius: 25, fontSize: 16, padding: "5px 11px", marginBottom: 10}}></i></div>
                  <div id="divWrapPagina" style={{width: "100%", boxShadow: "0px -2px 10px rgba(0,0,0,.4)", transition: ".5s", height: 0}}>{this._renderPaginas()}</div>
                </div>
              </div>
              <div onClick={this.abrirWrapPaginas.bind(this)} style={{backgroundColor: "#222D32", color: "white", boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.4)", position: "fixed", width: "auto", bottom: -15, left: 15, fontSize: 16, padding: "6px 9px 25px"}}><i className="fas fa-chevron-up" style={{marginRight: 5}}></i> Páginas</div>
            </React.Fragment>
          : null
        }
        <div style={{display: (this.state.lente ? "block" : "none"), position: "absolute", backgroundColor: "rgba(0,0,0,.9)", width: "calc(100% + 30px)", height: "calc(100% + 30px)", top: "0px", left: "0px", zIndex: 4, margin: -15}}></div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    publicacao: state.publicacao.especifica,
    contrato: state.contrato.especifico,
    autenticado: state.usuario.autenticado,
    publicacoes: state.publicacao.todas,
    comentarios: state.comentario.todos,
    formandos: state.formando.todos,
    numeroContrato: state.usuario.numeroContrato
  }
}

export default connect(mapStateToProps, {
  cadastrarAcao,
  obterFormandosPeloContrato,
  aprovarPagina,
  estornarPagina,
  editarPublicacao,
  obterPublicacoesPeloTipo,
  adicionarComentario,
  editarComentario,
  deletarComentario,
  descobrirContrato
})(Publicacao);
