export const caminhoAtual = (caminho) => {
  return{
    type: "CAMINHO_ATUAL",
    caminho
  }
}

export const definirTitulo = (titulo) => {
  return{
    type: "DEFINIR_TITULO",
    titulo
  }
}
