import axios from 'axios';
import { REQUEST_URL } from '../config/urls';
import { toast } from 'react-toastify';

const requisitar = () => {
    return {
        type: "REQUISITAR_ARROBAS"
    };
}

const obterTodos = (arrobas) => {
    return {
        type: "OBTER_TODOS_ARROBAS",
        arrobas
    }
}

const novoArroba = (arroba) => {
    return {
        type: "NOVO_ARROBA",
        arroba
    }
}

const alterarArroba = (arroba) => {
    return {
        type: "ALTERAR_ARROBA",
        arroba
    }
}

const removerArroba = (id) => {
    return {
        type: "REMOVER_ARROBA",
        id
    }
}
const erro = (erro) => {
    if (erro.statusCode === 401) {
        axios.defaults.headers.common['Authorization'] = "";
        return {
            type: "LOGOUT"
        }
    } else {
        return {
            type: "ERRO_ARROBA",
            erro
        }
    }
}

export const adicionarArroba = (arroba) => {
    return dispatch => {
        dispatch(requisitar());
        arroba.ip = sessionStorage.getItem("ip");
        return axios.post(`${REQUEST_URL}/instagrams`, arroba).then((response) => {
            dispatch(novoArroba(response.data));
        }).catch(error => {
            dispatch(erro(error.response.data.error));
        });
    }
}

export const editarArroba = (arroba) => {
    return dispatch => {
        dispatch(requisitar());
        arroba.ip = sessionStorage.getItem("ip");
        return axios.patch(`${REQUEST_URL}/instagrams`, arroba).then((response) => {
            dispatch(alterarArroba(response.data));
        }).catch(error => {
            dispatch(erro(error.response.data.error));
        });
    }
}

export const deletarArroba = (id) => {
    return dispatch => {
        dispatch(requisitar());
        return axios.delete(`${REQUEST_URL}/instagrams/${id}`).then((response) => {
            dispatch(removerArroba(id));
        }).catch(error => { 
            dispatch(erro(error.response.data.error));
        });
    }
}

export const obterArrobasPeloContrato = (id) => {
    return dispatch => {
        dispatch(requisitar());
        return axios.get(`${REQUEST_URL}/instagrams?filter={"where":{"contrato":${id}}}`).then((response) => {
            dispatch(obterTodos(response.data));
        }).catch(error => {
            dispatch(erro(error.response ? error.response.data.error : error ));
        });
    }
}