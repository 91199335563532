import React, {useEffect, useState} from "react";
import CardsValores from "../components/meusPedidos/CardsValores";
import axios from "axios";
import {URL_PAGAMENTO} from "../config/urls";
import {toast} from "react-toastify";
import TelegramService from "../services/TelegramService";
import TabelaItensPedido from "../components/meusPedidos/TabelaItensPedido";
import TabelaDetalhesCobranca from "../components/meusPedidos/TabelaDetalhesCobranca";

export default function PedidosComissao (props) {

  const [pedidos, setPedidos] = useState([]);
  const [pedido, setPedido] = useState(null)
  const [valores, setValores] = useState({
    pago: 0,
    pendente: 0,
    total: 0
  });

  useEffect(() => {
    buscarPedidos();
  }, []);


  const buscarPedidos = () => {
    axios.get(`${URL_PAGAMENTO}/comissao/pedidos`, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
      }
    })
      .then((response) => {
        setPedidos(response.data);
        let dashboard = {
          ...valores
        }
        response.data.map((pedido) => {
          dashboard.pago += pedido.cobranca.valorJaPago;
          dashboard.total += pedido.cobranca.valor;
        });
        dashboard.pendente = dashboard.total - dashboard.pago;
        setValores(dashboard);
      })
      .catch((erro) => {
        toast.error("Não foi possível buscar os pedidos da comissão.");
        new TelegramService().novo("ERROR", [
          JSON.stringify(erro),
          "Não foi possível buscar os pedidos da comissão.",
          `usuarioLogado: ${sessionStorage.getItem("usuarioLogadoComissao")}`
        ]);
      });
  }

  const formatarMoedaBr = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  return (
    <div>
      <div className="row">
        <CardsValores
          valorPago={valores.pago}
          valorPendente={valores.pendente}
          valorTotal={valores.total} />
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="box box-solid">
            <div className="box-header"><div className="box-title">Pedidos da comissão</div></div>
            <div className="box-body">
              <div className="table-responsive fixed">
                <table className="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Id</th>
                    <th>Tipo de pagamento</th>
                    <th>Valor total</th>
                    <th>Valor pago</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    pedidos.map((pedido, i) =>
                      <tr key={i} style={{cursor: "pointer"}} onClick={() => setPedido(pedido)} >
                        <td>
                          <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                            #{pedido.pedido}
                            <button type="button" style={{padding: 0, fontSize: 10}} className="btn btn-link">Clique para abrir o pedido</button>
                          </div>
                        </td>
                        <td>
                          <div style={{display: "flex", justifyContent: "space-between"}}>
                            {pedido.cobranca.formaPagamento}
                          </div>
                        </td>
                        <td>{formatarMoedaBr(pedido.cobranca.valor)}</td>
                        <td>{formatarMoedaBr(pedido.cobranca.valorJaPago)}</td>
                      </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        pedido ?
          <div className={`sidenav animated`}>
            <div className="off-canvas-title">
              <h3 style={{ display: "flex" }}>
                <span style={{ marginRight: 5 }}>Informações do pedido</span>
                <strong style={{ display: "flex", alignItems: "center" }}>
                  #{pedido.pedido}
                </strong>
              </h3>
              <button type="button" onClick={() => setPedido(null)} style={{ position: "absolute", top: 15, right: 15, padding: "7px 12px 5px", borderRadius: 25, boxShadow: "0px 1px 5px rgba(0,0,0,.12)", backgroundColor: "transparent" }} className="btn btn-default btn-lg"><i className="fas fa-times" /></button>
            </div>
            <div className="sidenav-content">
              <TabelaItensPedido formatarMoedaBr={formatarMoedaBr} pedidoSelecionado={pedido} />
              <TabelaDetalhesCobranca formatarMoedaBr={formatarMoedaBr} pedidoSelecionado={pedido} />
            </div>
          </div>
          :
          null
      }
    </div>
  );

}