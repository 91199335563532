import React from 'react';
import {obterConvite} from '../actions/ConviteActions';
import {obterContratoPeloUsuario} from '../actions/ContratoActions';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

class Publicacoes extends React.Component {
  componentWillMount(){
    if(this.props.contrato.id && this.props.contrato.id>0){
      this.props.obterConvite(this.props.contrato.id);
    }else{
      this.props.obterContratoPeloUsuario(this.props.usuario.id).then(() => {
        this.props.obterConvite(this.props.contrato.id);
      });
    }
  }

  render(){
    let publicacoes = this.props.convite ? this.props.convite.publicacoes : [];
    publicacoes.sort((a, b) => b.id-a.id);
    return(
      <div className="box box-solid" style={{marginTop: 30}}>
        <div className="box-body">
          <h2>Publicações</h2>
          <ul className="list-group">
            {
              publicacoes.length>0 ? publicacoes.map((publicacao, i) => (
                <li className="list-group-item" key={publicacao.id} >
                  <Link to={`/publicacoes/${publicacao.id}`}>
                    <div>
                      {Math.abs(i-publicacoes.length)} - {publicacao.tipo}<span className="pull-right">{new Date(publicacao.dataPublicacao).toLocaleDateString()}</span>
                    </div>
                  </Link>
                </li>
              )) : <h3>Não há publicações.</h3>
            }
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contrato: state.contrato.especifico,
    usuario: state.usuario.autenticado,
    convite: state.convite.especifico
  }
}

export default connect(mapStateToProps, {obterConvite, obterContratoPeloUsuario})(Publicacoes);
