import axios from 'axios';
import {REQUEST_URL} from '../config/urls';
import FirebaseService from '../services/FirebaseService';
import EmailServices from '../services/EmailService';

const requisitar = () => {
  return {
    type: "REQUISITAR_USUARIO"
  };
}

const autenticou = (resposta) => {
  return {
    type: "AUTENTICOU",
    usuario: resposta.usuario,
    token: resposta.token.id,
    contrato: resposta.numeroContrato
  }
}

const autenticouCookie = (usuario, numeroContrato) => {
  return {
    type: "AUTENTICOU_COOKIE",
    usuario: usuario,
    contrato: numeroContrato
  }
}

const erroAutenticacao = () => {
  return {
    type: "ERRO_AUTENTICACAO"
  }
}

const erro = (erro) => {
  if(erro.statusCode===401){
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  }else{
    return {
      type: "ERRO_USUARIO",
      erro
    }
  }
}

const obterTodos = (usuarios) => {
  return {
    type: "OBTER_TODOS_USUARIOS",
    usuarios
  }
}

const novoUsuario = (usuario) => {
  return {
    type: "NOVO_USUARIO",
    usuario
  }
}

const alterarUsuario = (usuario) => {
  return {
    type: "ALTERAR_USUARIO",
    usuario
  }
}

const removerUsuario = (id) => {
  return {
    type: "REMOVER_USUARIO",
    id
  }
}

const obterEspecifico = (usuario) => {
  return {
    type: "OBTER_USUARIO_ESPECIFICO",
    usuario
  }
}

const novoUsuarioFormando = (usuario) => {
  return {
    type: "NOVO_USUARIO_FORMANDO",
    usuario
  }
}
// FUNÇÕES EXPORTADAS

export const login = (credenciais) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/usuarios/login`, credenciais).then(response => {
      try {  
        let resposta = response.data;
        axios.defaults.headers.common['Authorization'] = resposta.token.id;
        sessionStorage.setItem('token', resposta.token.id);
        sessionStorage.setItem('tokenMapaVendas', resposta.tokenMapaVendas);
        sessionStorage.setItem("tokenPagamento", resposta.tokenPagamento);
        sessionStorage.setItem('usuarioLogadoComissao', JSON.stringify(resposta.usuario));
        // Nome aleatório para prevenir que seja identificado e alterado 
        // Chave responsável por armazenar o número do contrato para pesquisa quando houver um formando logado no sistema
        sessionStorage.setItem('dsakjh', resposta.numeroContrato);
        dispatch(autenticou(resposta));
      } catch (e) {
        FirebaseService.addLog(`error`, {
          data: new Date().toISOString(),
          status: `error`,
          message: `Erro no try. Usuário com o email: ${credenciais.login}. Erro: ${e.message}`
        });
      };
    }).catch((error) => {
      if(error.response) {
        FirebaseService.addLog(`error`, {
          data: new Date().toISOString(),
          status: `error`,
          message: `Erro ao autenticar. Usuário com o email: ${credenciais.login}. Erro: ${error.response.data.error.message} com StatusCode: ${error.response.data.error.statusCode}`
        });
        dispatch(erroAutenticacao(error.response.data.error));
      } else {
        FirebaseService.addLog(`error`, {
          data: new Date().toISOString(),
          status: `error`,
          message: `Erro ao autenticar. Usuário com o email: ${credenciais.login}. Erro: ${error}`
        });
        dispatch(erroAutenticacao(error));
      }
    });
  }
}

export const setUsuario = (usuario, numeroContrato) => {
  return dispatch => {
    dispatch(autenticouCookie(usuario, numeroContrato));
  }
}

export const logout = () => {
  axios.defaults.headers.common['Authorization'] = "";
  sessionStorage.removeItem('usuarioLogadoComissao');
  return {
    type: "LOGOUT"
  }
}

export const adicionarUsuario = (usuario) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/usuarios`, usuario).then((response) => {
      dispatch(novoUsuario(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const adicionarUsuarioFormando = (formando) => {
  let usuario = {
    login: formando.email,
    senha: Math.random().toString(36).slice(-8),
    nome: formando.nome,
    tipo: 'formando',
    email: formando.email
  }
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/usuarios`, usuario).then((response) => {
      axios.post(`${REQUEST_URL}/formandos/update?where={"id": ${formando.id}}`, {usuario: response.data.id}).then(() => {
        new EmailServices().enviar("formando", {usuario: {nome: usuario.nome , email: usuario.email, senha: usuario.senha}}, false);
      }).catch(error => {
        dispatch(erro(error.response.data.error));
      });
      dispatch(novoUsuarioFormando(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const editarUsuario = (usuario, id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/usuarios/${id}`, usuario).then((response) => {
      dispatch(alterarUsuario(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const deletarUsuario = (id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.delete(`${REQUEST_URL}/usuarios/${id}`).then(() => {
      dispatch(removerUsuario(id));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const obterUsuarioPeloId = (id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/usuarios/${id}`).then((response) => {
      dispatch(obterEspecifico(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const obterUsuarios = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/usuarios`).then((response) => {
      dispatch(obterTodos(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
