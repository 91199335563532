import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const erro = (erro) => {
  return {
    type: "ERRO_TURMAS",
    erro
  }
}

const salvar = (turmas) => {
  return {
    type: "CONSULTAR_TURMAS",
    turmas
  }
}

export const consultarTurmas = (turma) => {
  return dispatch => {
    return axios.post(`${REQUEST_URL}/turmas?filter={"include":["formandos"]}`, turma).then((response) => {
      dispatch(salvar(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}