import React from 'react';

export class PageFlip extends React.Component {
  componentDidMount() {
    window.iniciarPageFlip();
  }
  shouldComponentUpdate(nextProps, nextState){
    return true;
  }
  componentWillUnmount() {
    // window.destruirPageFlip();
  }
  render() {
    return <div id="pageflip">{this.props.children}</div>
  }
}

export  class Page extends React.Component {
  shouldComponentUpdate(nextProps, nextState){
    return false;
  }
  render() {
      return <div data-transparent-page={this.props.isVegetal}  className="page">{this.props.children}</div>;  
  }
}
export  class Cover extends React.Component {
  shouldComponentUpdate(nextProps, nextState){
    return false;
  }
  render() {
    return <div className="cover">{this.props.children}</div>;
  }
}