import React from "react";

export default function CardsValores (props) {

  const formatarMoedaBr = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  return (
    <React.Fragment>
      <div className="col-lg-4 col-md-6 col-sm-12" name="confirmacao">
        <div className="small-box bg-green">
          <div className="inner">
            <h3 style={{textAlign: "left", color: "white"}}>{formatarMoedaBr(props.valorPago)}</h3>
            <p style={{textAlign: "left"}}>Valor pago</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12" name="processando">
        <div className="small-box bg-yellow">
          <div className="inner">
            <h3 style={{textAlign: "left", color: "white"}}>{formatarMoedaBr(props.valorPendente)}</h3>
            <p style={{textAlign: "left"}}>Valor pendente</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12" name="aguardando">
        <div className="small-box bg-aqua">
          <div className="inner">
            <h3 style={{textAlign: "left", color: "white"}}>{formatarMoedaBr(props.valorTotal)}</h3>
            <p style={{textAlign: "left"}}>Valor total</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}