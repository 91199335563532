import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {login} from '../../actions/UsuarioActions';
import {descobrirContrato} from '../../actions/ContratoActions';
import {REQUEST_URL, URL_API_IP} from '../../config/urls';
import {toast} from 'react-toastify';
import axios from 'axios';
import './styles.css';
const queryString = require('query-string');
class Login extends React.Component {
  constructor(props){
    super(props);
    document.title = "Login :: Alpha Convites";
    this.state = {
      credentials: {
        login: "",
        senha: "",
        tipo: "comissao"
      },
      recuperarSenha:false,
      recuperarEmail:"",
      carregando:false
    };
  }
  async componentDidMount(){
    const parsed = queryString.parse(this.props.location.search);
    
    this.setState({
      credentials: {
        login: parsed.email ? parsed.email : "",
        senha: parsed.senha ? parsed.senha : "",
        tipo: "comissao"
      }
    });

    delete axios.defaults.headers.common["Authorization"];
    
    await axios.get(`${REQUEST_URL}/ip/address`, {headers: {}}).then((response) => {
      sessionStorage.setItem("ip", response.data.ip);
    });
    
    axios.defaults.headers.common = {'Authorization': sessionStorage.getItem("token")};

    if (parsed.alphaAdmin) await this.logar();
  }
  onChange(event){
    const field = event.target.name;
    const credentials = this.state.credentials;
    credentials[field] = event.target.value.trim();
    return this.setState({credentials});
  }
  async handleFormSubmit(e){
    e.preventDefault();
    await this.logar();
  }
  async logar() {
    this.setState({carregando: true});
    await this.props.login(this.state.credentials);
    if (this.props.usuario) {
      await this.props.descobrirContrato(this.props.usuario.tipo, this.props.usuario.tipo === "formando" ? this.props.numeroContrato : this.props.usuario.id).then(async () => {
        this.setState({carregando: false});
      });
    }     
    this.setState({carregando: false});
  }
  handleFormRecuperar(event){
    this.setState({carregando:true});
    event.preventDefault();
    axios.post(REQUEST_URL+"/recuperarSenha", {email:this.state.recuperarEmail, tipo:"comissao"}).then((response)=>{
      this.setState({carregando:false});
      toast.success("Te enviamos um e-mail com as informações de redefinição da senha!");
    }).catch((error)=>{
      this.setState({carregando:false});
      toast.error("Não existe nenhum cadastro ativo com este e-mail.");
    });
  }

  render(){
    // if(this.props.usuario){
    //   if (this.props.contrato !== undefined && this.props.contrato.termoAprovado === "true") {
    //     return (
    //       <Redirect to="/formandos" />
    //     );
    //   } 
    //   else {
    //     return (
    //       <Redirect to="/termo-aceite" />
    //     );
    //   }
    // }
    if(this.props.usuario){
      return (
        <Redirect to="/" />
      );
    }
    return (
      <div className="boxLogin">
      {
        this.state.recuperarSenha ?  
          <form onSubmit={this.handleFormRecuperar.bind(this)}>
            <div className="form-signin">
              <img src="images/logo-alpha-convite.png" alt="Logo da Alpha"/>
              <h2>Recuperação de senha</h2>
              <br/><br/>
              <label htmlFor="recSenha" style={{textAlign: "left", width: "100%"}}>E-mail</label>
              <input type="text" name="login" id="recSenha" value={this.state.recuperarEmail} onChange={(e)=>this.setState({recuperarEmail:e.target.value.trim()})} className="form-control" placeholder="john.doe@alphaeditora.com.br" required />
              <div style={{width: "100%", margin: "15px 0 30px"}}>
                <p>Já tem uma senha? <a className="btn btn-link" onClick={()=>this.setState({recuperarSenha:!this.state.recuperarSenha})} style={{boxShadow: "0 0 0 0", outline: "0 none", padding: "0px 0px 3px 0px", border: "0 none"}}>Clique aqui</a> e faça o login.</p> 
              </div>
              <button type="submit" className="btn btn-lg bg-black btn-block flat" disabled={this.state.carregando}>{this.state.carregando ? <span><i className="fa fa-spin fa-fw fa-spinner"></i> Redefinindo</span> : "Redefinir senha"}</button>
            </div>
          </form>
        :
          <form onChange={this.onChange.bind(this)} onSubmit={this.handleFormSubmit.bind(this)}>
            <div className="form-signin">
              <img src="images/logo-alpha-convite.png" alt="Logo da Alpha"/>
              <h2>Área do Formando</h2>
              <br/><br/>
              <label htmlFor="login" style={{textAlign: "left", width: "100%"}}>E-mail</label>
              <input type="text" name="login" id="login" value={this.state.credentials.login} className="form-control input-lg form-group" placeholder="john.doe@alphaeditora.com.br" required />
              <label htmlFor="senha" style={{textAlign: "left", width: "100%"}}>Senha</label>
              <input type="password" name="senha" id="senha" value={this.state.credentials.senha} className="form-control input-lg" placeholder="••••••" required/>
              <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                <a className="btn btn-link" style={{margin:"0px -10px 30px 0px", boxShadow: "0 0 0 0", outline: "0 none"}} onClick={()=>this.setState({recuperarSenha:!this.state.recuperarSenha})}>Esqueceu sua senha?</a>
              </div> 
              <button type="submit" className="btn btn-lg bg-black btn-block flat" disabled={this.state.carregando}>{this.state.carregando ? <span><i className="fa fa-spin fa-fw fa-spinner"></i> Entrando</span> : "Entrar"}</button>
            </div>
          </form>
        }
        <p><a href="https://alphaconvites.com.br" target="_blank">Alpha Convites</a> &copy; Todos os direitos reservados</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    carregando: state.usuario.carregando,
    erro: state.usuario.erro,
    contrato: state.contrato.especifico,
    numeroContrato: state.usuario.numeroContrato
  };
};

export default connect(mapStateToProps, {login, descobrirContrato})(Login);
