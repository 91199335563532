import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { descobrirContrato } from '../actions/ContratoActions';
import { obterFormandosPeloContrato } from '../actions/FormandoActions';
import { obterArrobasPeloContrato } from '../actions/InstagramActions';

import $ from 'jquery';

class Menu extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentWillMount() {
    await this.props
      .descobrirContrato(
        this.props.usuario.tipo,
        this.props.usuario.tipo === 'formando'
          ? this.props.numeroContrato
          : this.props.usuario.id
      )
      .then(async () => {
        await this.props.obterFormandosPeloContrato(this.props.contrato.id);
        await this.props.obterArrobasPeloContrato(this.props.contrato.id);
      });
  }

  fecharMenu() {
    $('#overlay-menuPrincipal').css('display', 'none');
    $('body').removeClass('sidebar-open');
  }

  _renderMenuComissao() {
    return (
      <ul className='sidebar-menu'>
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/formandos' ? 'active' : null}
        >
          <Link to='/formandos'>
            <i className='fa fa-graduation-cap' />
            <span>
              {' '}
              Formandos
              {this.props.contrato.cadastrouFormandos === 1 ? (
                <small
                  className='label label-success'
                  style={{ marginLeft: 5 }}
                >
                  feito
                </small>
              ) : (
                <small className='label label-danger' style={{ marginLeft: 5 }}>
                  aberto
                </small>
              )}
            </span>
          </Link>
        </li>
        {this.props.contrato.pagamento_comissao ? (
          <li
            onClick={this.fecharMenu.bind(this)}
            className={
              this.props.path === '/distribuir-itens' ? 'active' : null
            }
          >
            <Link to='/distribuir-itens'>
              <i className='fas fa-tags' />
              <span>
                {' '}
                Distribuir itens
                {this.props.contrato.compras_comissao_distribuidas ? (
                  <small
                    className='label label-success'
                    style={{ marginLeft: 5 }}
                  >
                    feito
                  </small>
                ) : (
                  <small
                    className='label label-danger'
                    style={{ marginLeft: 5 }}
                  >
                    aberto
                  </small>
                )}
              </span>
            </Link>
          </li>
        ) : null}
        {this.props.contrato.sistemaPagto === 1 &&
        this.props.contrato.distribuiuBrindes !== null ? (
          <li
            onClick={this.fecharMenu.bind(this)}
            className={
              this.props.path === '/distribuir-brindes' ? 'active' : null
            }
          >
            <Link to='/distribuir-brindes'>
              <i className='fa fa-gifts' />
              <span>
                {' '}
                Distribuir brindes
                {this.props.contrato.distribuiuBrindes === 1 ? (
                  <small
                    className='label label-success'
                    style={{ marginLeft: 5 }}
                  >
                    feito
                  </small>
                ) : (
                  <small
                    className='label label-danger'
                    style={{ marginLeft: 5 }}
                  >
                    aberto
                  </small>
                )}
              </span>
            </Link>
          </li>
        ) : null}
        {this.props.contrato.sistemaPagto === 1 ? (
          <li
            onClick={this.fecharMenu.bind(this)}
            className={this.props.path === '/entrega' ? 'active' : null}
          >
            <Link to='/entrega'>
              <i className='fa fa-truck' />
              <span>
                {' '}
                Faturamento/entrega
                {this.props.contrato.enderecoPreenchido ? (
                  <small
                    className='label label-success'
                    style={{ marginLeft: 5 }}
                  >
                    feito
                  </small>
                ) : (
                  <small
                    className='label label-danger'
                    style={{ marginLeft: 5 }}
                  >
                    aberto
                  </small>
                )}
              </span>
            </Link>
          </li>
        ) : null}
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/instagram' ? 'active' : null}
        >
          <Link to='/instagram'>
            <i className='fab fa-instagram' />
            <span>
              {' '}
              Instagram
              {this.props.arrobas.length > 0 ? (
                <small
                  className='label label-success'
                  style={{ marginLeft: 5 }}
                >
                  feito
                </small>
              ) : (
                <small className='label label-danger' style={{ marginLeft: 5 }}>
                  aberto
                </small>
              )}
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          id='aprovacaoConvite'
          className={this.props.path === '/aprovacao-convite' ? 'active' : null}
        >
          <Link to='/aprovacao-convite'>
            <i className='fa fa-envelope' />
            <span>
              {' '}
              Aprovação do convite
              <small className='label label-danger' style={{ marginLeft: 5 }}>
                aberto
              </small>
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/' ? 'active' : null}
        >
          <Link to='/'>
            <i className='fas fa-file-alt' />
            <span>
              {' '}
              Contrato
              <small className='label label-info' style={{ marginLeft: 5 }}>
                info
              </small>
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          id='orientacoesFotograficas'
          className={
            this.props.path === '/orientacoes-fotograficas' ? 'active' : null
          }
        >
          <Link to='/orientacoes-fotograficas'>
            <i className='fa fa-camera' />
            <span>
              {' '}
              Orientações fotográficas
              <small className='label label-info' style={{ marginLeft: 5 }}>
                info
              </small>
            </span>
          </Link>
        </li>
        {this.props.contrato.sistemaPagto === 1 ? (
          <li
            onClick={this.fecharMenu.bind(this)}
            className={this.props.path === '/meus-pedidos' ? 'active' : null}
          >
            <Link to='/meus-pedidos'>
              <i className='fa fa-shopping-cart' />
              <span>
                {' '}
                Pedidos
                <small className='label label-info' style={{ marginLeft: 5 }}>
                  info
                </small>
              </span>
            </Link>
          </li>
        ) : null}
        {this.props.contrato.pagamento_comissao ? (
          <li
            onClick={this.fecharMenu.bind(this)}
            className={
              this.props.path === '/pedidos-comissao' ? 'active' : null
            }
          >
            <Link to='/pedidos-comissao'>
              <i className='fas fa-shopping-bag' />
              <span>Pedidos da comissão</span>
            </Link>
          </li>
        ) : null}
      </ul>
    );
  }

  _renderMenuFormando() {
    return (
      <ul className='sidebar-menu'>
        {this.props.contrato.sistemaPagto === 1 ? (
          <li
            onClick={this.fecharMenu.bind(this)}
            className={this.props.path === '/comprar' ? 'active' : null}
          >
            <Link to='/comprar'>
              <i className='fa fa-shopping-cart' />
              <span>
                {' '}
                Comprar itens
                {this.props.contrato.distribuiuItens === 1 ? (
                  <small
                    className='label label-success'
                    style={{ marginLeft: 5 }}
                  >
                    feito
                  </small>
                ) : (
                  <small
                    className='label label-danger'
                    style={{ marginLeft: 5 }}
                  >
                    aberto
                  </small>
                )}
              </span>
            </Link>
          </li>
        ) : null}
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/' ? 'active' : null}
        >
          <Link to='/'>
            <i className='fas fa-file-alt' />
            <span>
              {' '}
              Contrato
              <small className='label label-info' style={{ marginLeft: 5 }}>
                info
              </small>
            </span>
          </Link>
        </li>
        {this.props.contrato.sistemaPagto === 1 ? (
          <li
            onClick={this.fecharMenu.bind(this)}
            className={this.props.path === '/meus-pedidos' ? 'active' : null}
          >
            <Link to='/meus-pedidos'>
              <i className='fas fa-receipt'></i>
              <span>
                {' '}
                Meus pedidos
                <small className='label label-info' style={{ marginLeft: 5 }}>
                  info
                </small>
              </span>
            </Link>
          </li>
        ) : null}
      </ul>
    );
  }

  _renderMenuAlphaE() {
    return (
      <ul className='sidebar-menu'>
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/formandos' ? 'active' : null}
        >
          <Link to='/formandos'>
            <i className='fa fa-graduation-cap' />
            <span>
              {' '}
              Formandos
              {this.props.contrato.cadastrouFormandos === 1 ? (
                <small
                  className='label label-success'
                  style={{ marginLeft: 5 }}
                >
                  feito
                </small>
              ) : (
                <small className='label label-danger' style={{ marginLeft: 5 }}>
                  aberto
                </small>
              )}
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/itens' ? 'active' : null}
        >
          <Link to='/distribuir-itens'>
            <i className='fa fa-shopping-cart' />
            <span>
              {' '}
              Distribuir itens
              {this.props.contrato.distribuiuItens === 1 ? (
                <small
                  className='label label-success'
                  style={{ marginLeft: 5 }}
                >
                  feito
                </small>
              ) : (
                <small className='label label-danger' style={{ marginLeft: 5 }}>
                  aberto
                </small>
              )}
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/instagram' ? 'active' : null}
        >
          <Link to='/instagram'>
            <i className='fab fa-instagram' />
            <span>
              {' '}
              Instagram
              {this.props.arrobas.length > 0 ? (
                <small
                  className='label label-success'
                  style={{ marginLeft: 5 }}
                >
                  feito
                </small>
              ) : (
                <small className='label label-danger' style={{ marginLeft: 5 }}>
                  aberto
                </small>
              )}
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          id='aprovacaoConvite'
          className={this.props.path === '/aprovacao-convite' ? 'active' : null}
        >
          <Link to='/aprovacao-convite'>
            <i className='fa fa-envelope' />
            <span>
              {' '}
              Aprovação do convite
              <small className='label label-danger' style={{ marginLeft: 5 }}>
                aberto
              </small>
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          className={this.props.path === '/' ? 'active' : null}
        >
          <Link to='/'>
            <i className='fas fa-file-alt' />
            <span>
              {' '}
              Contrato
              <small className='label label-info' style={{ marginLeft: 5 }}>
                info
              </small>
            </span>
          </Link>
        </li>
        <li
          onClick={this.fecharMenu.bind(this)}
          id='orientacoesFotograficas'
          className={
            this.props.path === '/orientacoes-fotograficas' ? 'active' : null
          }
        >
          <Link to='/orientacoes-fotograficas'>
            <i className='fa fa-camera' />
            <span>
              {' '}
              Orientações fotográficas
              <small className='label label-info' style={{ marginLeft: 5 }}>
                info
              </small>
            </span>
          </Link>
        </li>
      </ul>
    );
  }

  render() {
    return (
      <aside className='main-sidebar'>
        <section className='sidebar'>
          {!this.props.contrato.contratante ||
          this.props.contrato.contratante === null ||
          !this.props.contrato.contratante.includes('Alpha-e')
            ? this.props.usuario.tipo === 'comissao'
              ? this._renderMenuComissao()
              : this.props.usuario.tipo === 'formando'
              ? this._renderMenuFormando()
              : null
            : this._renderMenuAlphaE()}
        </section>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    numeroContrato: state.usuario.numeroContrato,
    contrato: state.contrato.especifico,
    caminho: state.caminho.atual,
    formandos: state.formando.todos,
    arrobas: state.instagram.todos,
  };
};

export default connect(mapStateToProps, {
  obterArrobasPeloContrato,
  descobrirContrato,
  obterFormandosPeloContrato,
})(Menu);
