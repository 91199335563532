import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import InputMask from 'react-input-mask';
import {Modal} from 'react-bootstrap';
import Joyride from 'react-joyride';

import {editarContrato, salvarEndereco, obterContratoPeloUsuario} from '../actions/ContratoActions';
import {cadastrarAcao} from '../actions/AcaoActions';
import EmailService from '../services/EmailService';

class InformacoesEntrega extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      itens:[],
      formandos:[],
      itensFormando:[],
      endereco:{
        nome: "",
        cpf: "",
        rg: "",
        celular: "",
        email: "",
        rua: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",         
        contrato: ""
      },
      classInputs:{
        nome:"",
        cpf:"",
        rg: "",
        celular: "",
        email: "",
        rua:"",
        bairro:"",
        numero:"",
        cidade:"",
        estado:"",
        cep:""
      },
      showModalNotificacao: false,
      botaoFinalizar: false,
      run: false,
      steps: [
        {
          content: <div><h3>Bem-vindo(a) a página de cadastro das informações de faturamento e entrega!</h3></div>,
          placement: 'center',
          target: '#divContent'
        },
        {
          content: <div><h3>Informações para faturamento e entrega</h3> <span style={{textAlign: "center"}}>Preencha corretamente todos estes campos para faturamento/entrega dos convites.</span></div>,
          placement: 'bottom',
          target: '#divForm'
        },
        {
          content: <div><h3>Finalização da etapa</h3> <span style={{textAlign: "center"}}>Após o preenchimento, finalize a etapa clicando neste botão!</span></div>,
          target: '#btnFinalizarEtapa'
        }
      ]
    }
  }

  async componentWillMount(){
    if (this.props.usuario.tipo === "comissao") {
      await this.props.obterContratoPeloUsuario(this.props.usuario.id);
      if (this.props.contrato.endereco === undefined) {
        this.setState({run: true});
      } else {
        this.setState({endereco: this.props.contrato.endereco, finalizado:true});
      }
    }
  }

  limparClassInputs() {
    let inputs = Object.keys(this.state.classInputs),
      classInputs = this.state.classInputs;
    inputs.forEach((input) => {
      classInputs[input] = "";
    });
    this.setState({ classInputs });
  }

  verificarInputs(){
    let classInputs = this.state.classInputs, 
      inputs = Object.keys(classInputs),
      existemCamposNaoPreenchidos = false;
    inputs.forEach((input) => {
      if (this.state.endereco[input] === null || this.state.endereco[input] === undefined || this.state.endereco[input] === "") {
        classInputs[input] = "has-error"; 
        existemCamposNaoPreenchidos = true;
      }
    });
    this.setState({classInputs});
    if (existemCamposNaoPreenchidos) {
      return true;
    } else {
      return false;
    }
  }

  async salvarInformacoesEntrega() {
    let {endereco} = this.state,
      email = new EmailService();
    endereco.contrato = this.props.contrato.id;
    await this.setState({botaoFinalizar: true});
    if (!this.verificarInputs()) {
      await this.props.salvarEndereco(endereco, this.props.contrato.id)
        .then(() => {
          //notificacao ao admim que foi realizado distribuição de itens
          let acao = {
            ator: this.props.contrato.dono.id,
            texto: "A comissão do contrato " + this.props.contrato.numero + " preencheu as informações de entrega",
            data: new Date(),
            contrato: this.props.contrato.id,
            tipo: "comissao",
            visualizado: false
          }
          email.enviar("updateAcaoComissaoToDesigner", {
            numero: this.props.contrato.numero,
            mensagem: "o cadastro das informações de faturamento/entrega",
            designer: {email: this.props.contrato.dono.email}
          });
          this.props.cadastrarAcao(acao);
        })
        .finally(() => {
          this.setState({botaoFinalizar: false, showModalNotificacao: true});
        })
    }
  }

  alterarForm(event){
    const campo = event.target.name;
    const endereco = this.state.endereco;
    endereco[campo] = event.target.value;
    this.limparClassInputs();
    return this.setState({endereco});
  }

  render () {
    if (this.props.usuario.tipo === "formando") {
      return <Redirect to="/"/>;
    }
    return (
      <div id="divContent">
        {
          this.props.contrato.enderecoPreenchido ?
            <div className="alert alert-success" style={{fontWeight: "bold", fontSize: 14}}>
              <i className="fa fa-check"></i>
              {" "} Etapa concluída!
            </div>
          : <div className="alert alert-info" style={{fontWeight: "bold", fontSize: 14}}>
            <i className="fa fa-info-circle"></i>
            {" "} Os convites serão entregues no endereço abaixo, por isso coloque um endereço válido!
          </div>
        }
        <div className="box box-solid" id="divForm">
          <div className="box-header"><div className="box-title">Informações para o faturamento/entrega:</div></div>
          <div className="box-body">
            <form onChange={this.alterarForm.bind(this)}>
              <div className="row">
                <div className={`form-group ${this.state.classInputs.nome} col-sm-12`}>
                  <label htmlFor="nome">Nome do proprietário</label>
                  <input type="text" name="nome" id="nome" value={this.state.endereco.nome} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
              </div>
              <div className="row">
                <div className={`form-group ${this.state.classInputs.email} col-sm-12 col-md-8`}>
                  <label htmlFor="email">E-mail do proprietário</label>
                  <input type="email" name="email" id="email" value={this.state.endereco.email} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
                <div className={`form-group ${this.state.classInputs.celular} col-sm-12 col-md-4`}>
                  <label htmlFor="celular">Celular do proprietário</label>
                  <InputMask type="text" mask="(99) 9 9999-9999" name="celular" id="celular" value={this.state.endereco.celular} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
              </div>
              <div className="row">
                <div className={`form-group ${this.state.classInputs.cpf} col-sm-12 col-md-6`}>
                  <label htmlFor="cpf">CPF do proprietário</label>
                  <InputMask type="text" mask="999.999.999-99" name="cpf" id="cpf" value={this.state.endereco.cpf} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
                <div className={`form-group ${this.state.classInputs.rg} col-sm-12 col-md-6`}>
                  <label htmlFor="cpf">RG do proprietário</label>
                  <InputMask type="text" name="rg" id="rg" value={this.state.endereco.rg} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
              </div>
              <div className="row">
                <div className={`form-group ${this.state.classInputs.rua} col-sm-10 col-md-8`}>
                  <label htmlFor="rua">Endereço</label>
                  <input type="text" name="rua" id="rua" value={this.state.endereco.rua} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
                <div className={`form-group ${this.state.classInputs.numero} col-sm-2 col-md-4`}>
                  <label htmlFor="numero">Número</label>
                  <input type="number" name="numero" id="numero" value={this.state.endereco.numero} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
              </div>
              <div className="row">
                <div className={`form-group ${this.state.classInputs.bairro} col-sm-6`}>
                  <label htmlFor="bairro">Bairro</label>
                  <input type="text" name="bairro" id="bairro" value={this.state.endereco.bairro} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
                <div className={`form-group col-sm-6`}>
                  <label htmlFor="complemento">Complemento</label>
                  <input type="complemento" name="complemento" id="complemento" value={this.state.endereco.complemento} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
              </div>
              <div className="row">
                <div className={`form-group ${this.state.classInputs.cep} col-sm-12 col-md-4`}>
                  <label htmlFor="cep">CEP</label>
                  <InputMask name="cep" id="cep" value={this.state.endereco.cep} mask="99999-999" maskChar=" " className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
                <div className={`form-group ${this.state.classInputs.cidade} col-sm-10 col-md-6`}>
                  <label htmlFor="cidade">Cidade</label>
                  <input type="text" name="cidade" id="cidade" value={this.state.endereco.cidade} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                </div>
                <div className={`form-group ${this.state.classInputs.estado} col-sm-2`}>
                  <div className={this.state.classInputs.estado}>
                    <label htmlFor="estado">Estado</label>
                    <InputMask type="text" name="estado" id="estado" value={this.state.endereco.estado} mask={'aa'} className="form-control" disabled={this.props.contrato.enderecoPreenchido}/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {
          !this.props.contrato.enderecoPreenchido ?
            <button type="button" onClick={this.salvarInformacoesEntrega.bind(this)} id="btnFinalizarEtapa" className="btn-lg btn btn-danger btn-flat botaoFinalizar" disabled={this.state.botaoFinalizar}>
              {this.state.botaoFinalizar === true ? <span><i className="fa fa-spinner fa-pulse fa-fw"></i> Finalizando etapa</span> : "Finalizar etapa"}
            </button>  
          : null
        }
        <Modal show={this.state.showModalNotificacao !== false} onHide={()=>{this.setState({showModalNotificacao: false})}} dialogClassName="modal-80w">
          <Modal.Body>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
              <img src="./images/success.png" alt="Sucesso" height="75"/>
              <h2 style={{textAlign: "center"}}>Cadastro das informações de faturamento/entrega finalizada!</h2>
              <h4 style={{textAlign: "center"}}>A próxima etapa agora é o cadastro dos perfis do instagram da comissão para a divulgação do convite.</h4>
              <a href="./instagram" className="btn btn-outline-success btn-lg" style={{marginTop: 17}}>Vamos lá!</a>
            </div>
          </Modal.Body>
        </Modal>
        <Joyride steps={this.state.steps}
          continuous={true}
          run={this.state.run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={this.state.steps}
          styles={{
            options: {
              zIndex: 10000,
            },
        }}/>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    contrato: state.contrato.especifico,
    formandos: state.formando.todos,
    itens: state.item.todos
  }
}
export default connect(mapStateToProps, {cadastrarAcao, salvarEndereco, editarContrato, obterContratoPeloUsuario})(InformacoesEntrega);