import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR"
  };
}

const erro = (erro) => {
  if (erro.statusCode === 401) {
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  } else {
    return {
      type: "ERRO",
      erro
    }
  }
}

const adicionar = (pesquisa) => {
  return {
    type: "ADD",
    pesquisa
  }
}

export const cadastrarPesquisa = (pesquisa) => {
  return dispatch => {
    dispatch(requisitar());
    pesquisa.ip = sessionStorage.getItem("ip");
    return axios.post(`${REQUEST_URL}/pesquisas`, pesquisa).then((response) => {
      dispatch(adicionar(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}