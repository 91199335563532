import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todas: [],
  carregando: false,
  erro: null,
  especifica: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_PUBLICACAO':
      return {...state, carregando: true};
    case 'ERRO_PUBLICACAO':
      toast.error(action.erro.message, 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_PUBLICACAO':
      return {...state, carregando: false, especifica: action.publicacao};
    case 'OBTER_PUBLICACOES':
      return {...state, carregando: false, todas: action.publicacoes};
    default:
      return state;
  }
};
