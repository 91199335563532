const INITIAL_STATE = {
  turmas: [],
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ERRO_TURMAS":
      return {...state, erro: action.erro};
    case "CONSULTAR_TURMAS":
      return {...state, turmas: action.turmas};
    default:
      return state;
  }
};
