import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_PAGINA"
  };
}

const erro = (erro) => {
    return {
      type: "ERRO_PAGINA",
      erro
    }
}

export const aprovarPagina = (pagina) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/paginas/${pagina}`, {aprovada: true, ip: sessionStorage.getItem("ip"), dataAprovacao: new Date()}).then((response) => {
      dispatch({type: "APROVAR_PAGINA"});
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const estornarPagina = (pagina) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/paginas/${pagina}`, {aprovada: false, ip: sessionStorage.getItem("ip"), dataAprovacao: null}).then((response) => {
      dispatch({type: "ESTORNAR_PAGINA"});
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
