import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_ITEM"
  };
}

const novoItemFormando = (itemFormando) => {
  return {
    type: "NOVO_ITEM_FORMANDO",
    itemFormando
  }
}

const editarItemFormando = (itemFormando) => {
  return {
    type: "ALTERAR_ITEM_FORMANDO",
    itemFormando
  }
}


/*
const obterTodosContrato = (itens) => {
  return {
    type: "OBTER_TODOS_ITENS",
    itens
  }
}

const obterTodos = (itens) => {
  return {
    type: "OBTER_TODOS_ITENS",
    itens
  }
}
const alterarItemFormando = (itemFormando) => {
  return {
    type: "ALTERAR_ITEM",
    itemFormando
  }
}
*/
const excluirItemFormando = (id) => {
  return {
    type: "REMOVER_ITEM",
    id
  }
}


const erro = (erro) => {
  if(erro.statusCode===401){
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  }else{
    return {
      type: "ERRO_ITEM",
      erro
    }
  }
}
export const adicionarItemFormando = (itemFormando) => {
  return dispatch => {
    dispatch(requisitar());
    if(itemFormando.id){
      itemFormando.ip = sessionStorage.getItem("ip");
      return axios.patch(`${REQUEST_URL}/itens_formando/${itemFormando.id}`, itemFormando).then((response) => {
        dispatch(editarItemFormando(response.data));
      }).catch(error => {
        dispatch(erro(error.response.data.error));
      });
    }else {
      itemFormando.ip = sessionStorage.getItem("ip");
      return axios.post(`${REQUEST_URL}/itens_formando`, itemFormando).then((response) => {
        dispatch(novoItemFormando(response.data));
      }).catch(error => {
        dispatch(erro(error.response.data.error));
      });
    }

  }
}
export const removerItemFormando = (id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.delete(`${REQUEST_URL}/itens_formando/${id}`).then((response) => {
      dispatch(excluirItemFormando(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
