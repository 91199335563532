import axios from 'axios';
import {REQUEST_URL, URL_PAGAMENTO} from '../config/urls';
import EmailServices from "../services/EmailService";

const requisitar = () => {
  return {
    type: "REQUISITAR_CONTRATO"
  };
}
const obterTodos = (contratos) => {
  return {
    type: "OBTER_TODOS_CONTRATOS",
    contratos
  }
}
const obterEspecifico = (contrato) => {
  return {
    type: "OBTER_ESPECIFICO",
    contrato
  }
}
const obterEspecificoPeloUsuario = (contrato) => {
  return {
    type: "OBTER_ESPECIFICO_USUARIO",
    contrato
  }
}
const novoContrato = (contrato) => {
  return {
    type: "NOVO_CONTRATO",
    contrato
  }
}
const salvarEnd= (endereco) => {
  return {
    type: "SALVAR_ENDERECO",
    endereco
  }
}
const alterarContrato = (contrato) => {
  return {
    type: "ALTERAR_CONTRATO",
    contrato
  }
}
const removerContrato = (id) => {
  return {
    type: "REMOVER_CONTRATO",
    id
  }
}
const planilhaAprovada = (status) => {
  return {
    type: "PLANILHA_DISTRIBUICAO_APROVADA",
    status: status
  }
}

const erro = (erro) => {
  if(erro.statusCode===401){
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  }else{
    return {
      type: "ERRO_CONTRATO",
      erro
    }
  }
}


export const adicionarContrato = (contrato) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/contratos`, contrato).then((response) => {
      contrato.itens.forEach((item)=>{
        item.contrato = response.data.id;
        item.produto = item.produto.id;
        axios.post(`${REQUEST_URL}/itens`, item);
      });
      dispatch(novoContrato(response.data));
    }).catch(error => {
      dispatch(erro(error.data.error));
    });
  }
}
export const salvarEndereco = (endereco, contratoId) => {
  return async dispatch => {
    dispatch(requisitar());
    endereco.ip = sessionStorage.getItem("ip");
    return await axios.patch(`${REQUEST_URL}/enderecos`, endereco)
      .then(async (response) => {
        const dataEnd = response.data;
        await axios.put(`${REQUEST_URL}/status-endereco-entrega`, {
          contratoId: contratoId,
          status: true
        })
          .then(async (response) => {
            await axios.post(`${URL_PAGAMENTO}/comissao/gerar-pedido`, {}, {
              headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("tokenPagamento")
              }
            })
              .then(() => dispatch(salvarEnd(dataEnd)))
              .catch(erro("Não foi possível gerar o pedido da comissão."))
          })
          .catch((error) => {
            dispatch(erro(error.response.data.error));
          });
      }).catch(error => {
        dispatch(erro(error.response.data.error));
      });
  }
}
export const editarContrato = (contrato) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/contratos`, contrato).then((response) => {
      dispatch(alterarContrato(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const editarCamposContrato = (id, contrato) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/contratos/${id}`, contrato).then((response) => {
      dispatch(alterarContrato(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const transferirContrato = (contrato, id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/contratos?where[id]=${id}`, contrato).then((response) => {
      dispatch(alterarContrato(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const deletarContrato = (id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.delete(`${REQUEST_URL}/contratos/${id}`).then(() => {
      dispatch(removerContrato(id));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterContratos = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/contratos?filter={"include":["itens","formandos","usuario","dono","turmas"]}`).then((response) => {
      dispatch(obterTodos(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterContrato = (id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/contratos/${id}?filter={"include":["itens","usuario","dono","endereco",{"turmas": ["formandos"]},{"publicacoes": [{"paginas": ["comentarios"]}]}]}`).then((response) => {
      dispatch(obterEspecifico(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterContratosNovos = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/contratos?filter={"where":{"status":"aguardando"},"include":["itens","formandos","usuario","dono","turmas"]}`).then((response) => {
      dispatch(obterTodos(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterContratosConfigurados = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/contratos?filter={"where":{"status":"configurado"},"include":["itens","formandos","usuario","dono","turmas"]}`).then((response) => {
      dispatch(obterTodos(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterContratosConfirmado = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/contratos?filter={"where":{"status":"confirmado"},"include":["itens","formandos","usuario","dono","turmas"]}`).then((response) => {
      dispatch(obterTodos(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterContratosFinalizados = () => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/contratos?filter={"where":{"status":"finalizado"},"include":["itens","formandos","usuario","dono","turmas"]}`).then((response) => {
      dispatch(obterTodos(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterContratoPeloUsuario = (usuarioId) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/contratos/findOne?filter={"where":{"usuario":${usuarioId}},"include":["itens","usuario","dono","endereco",{"turmas": ["formandos"]},{"publicacoes": [{"paginas": ["comentarios"]}]}]}`).then((response) => {
      dispatch(obterEspecificoPeloUsuario(response.data));
    }).catch(error => {
      dispatch(erro(error.response ? error.response.data.error : error ));
    });
  }
}
export const aprovarPlanilhaQuantidades = (contrato) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/confirmar-lista-quantidades`, {
      contratoId: contrato.id
    }).then((response) => {
      new EmailServices().enviar("planilhaConfirmada", contrato.numero).then((response) => {});
      dispatch(planilhaAprovada(true));
    }).catch(error => {
      dispatch(planilhaAprovada(false));
      dispatch(erro("Erro registrar a confirmação da planilha. Por favor, tente novamente!" ));
    });
  }
}
export const descobrirContrato = (campo, valor) => {
  switch (campo) {
    case "comissao":
      return obterContratoPeloUsuario(valor);
      break;
    case "formando":
      return obterContrato(valor);
      break;
  }
}
