const INITIAL_STATE = {
  tutorial: {
    contrato: "",
    itens: "",
    entrega: "",
    formandos: ""
  }
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case "OBTER_TUTORIAL":
      return {...state, tutorial: action.tutorial};
    default:
      return state;
  }
};
