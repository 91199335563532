import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Joyride from 'react-joyride';
import axios from 'axios';
import Clipboard from 'react-clipboard.js';

import {
  obterItensTipoFTab,
  editarItem,
  adicionarItem,
} from '../actions/ItemActions';
import {
  obterFormandosPeloContrato,
  editarCamposFormando,
} from '../actions/FormandoActions';
import { cadastrarAcao } from '../actions/AcaoActions';
import {
  adicionarItemFormando,
  removerItemFormando,
} from '../actions/ItemFormandoActions';
import {
  editarContrato,
  descobrirContrato,
  editarCamposContrato,
} from '../actions/ContratoActions';
import { URL_PAGAMENTO } from '../config/urls';
import MapaService from '../services/MapaService';
import TelegramService from '../services/TelegramService';
import ModalPagamento from '../components/ModalPagamento';

class DistribuirItensFormando extends React.Component {
  state = {
    data: [],
    itens: [],
    valorPedido: 0,
    clienteMapa: {},
    formando: {},
    pix: {
      base64: '',
      copiaCola: '',
      carregando: true,
      showModal: false,
    },
    itemSelecionado: {},
    showModalInformacoes: false,
    showModalNotificacao: false,
    showModalDocumentoNaoCadastrado: false,
    showModalTermo: false,
    showModalPagamento: false,
    botaoFinalizar: false,
    run: false,
    tab: ['avulso', 'kit', 'itemFuturo'],
    steps: [
      {
        content: (
          <div>
            <h3>Bem-vindo(a) a página de distribuição dos produtos!</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              É aqui que você conseguirá ver os produtos contratados e também
              fará a distribuição para os formandos.
            </span>
          </div>
        ),
        placement: 'center',
        target: '#itens-disponiveis',
      },
      {
        content: (
          <div>
            <h3>Tipos de itens</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Clique aqui para fazer a distribuição dos itens disponíveis no seu
              contrato.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '#secaoTabs',
      },
      {
        content: (
          <div>
            <h3>Contrate itens extras</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              As quantidades contratadas inicialmente não foram suficientes?
              Clique aqui e aumente a quantidade.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '#tabExtra',
      },
      {
        content: (
          <div>
            <h3>Tabela de produtos contratados</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Aqui você pode visualizar todos os produtos que estão vinculadas
              ao seu contrato.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '#tableItems',
      },
      {
        content: (
          <div>
            <h3>Tabela de distribuição dos produtos contratados</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Aqui você definirá quais são os produtos de cada formando.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '.tableDistribuicao',
      },
      {
        content: (
          <div>
            <h3>Informação sobre o produto a ser distribuido</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Passe o mouse sobre este ícone para saber o item que ele se
              refere.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '.itemInfoCelula',
      },
      {
        content: (
          <div>
            <h3>Download da distribuição de itens</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Caso necessário, clique aqui e baixe a tabela de distribuição de
              itens.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '#btnBaixarTabela',
      },
      {
        content: (
          <div>
            <h3>Finalização da etapa</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Após o preenchimento da tabela de distribuição de produtos,
              finalize a etapa clicando neste botão!
            </span>
          </div>
        ),
        target: '#botaoFinalizarDistribuicao',
      },
    ],
  };

  async componentWillMount() {
    await this.props
      .descobrirContrato(
        this.props.usuario.tipo,
        this.usuarioEhFormando()
          ? this.props.numeroContrato
          : this.props.usuario.id
      )
      .then(async () => {
        if (
          !this.usuarioEhFormando() ||
          this.props.contrato.sistemaPagto === 0
        ) {
          this.props.history.push('./');
        } else {
          this.carregarDados();
        }
      });
  }

  async carregarDados() {
    await this.props.obterItensTipoFTab(this.props.contrato.id, this.state.tab);
    await this.props.obterFormandosPeloContrato(this.props.contrato.id);

    let mapa = new MapaService(),
      formando = this.props.formandos.filter((formando) => {
        return (
          formando.usuario && formando.usuario.id === this.props.usuario.id
        );
      })[0],
      turma = this.props.contrato.turmas.filter((turma) => {
        return turma.id === formando.turma;
      })[0],
      itens = Object.assign([], this.props.itens);

    itens.map((item) => {
      return (item.quantidade = 0);
    });

    if (!formando.documento || formando.documento === '') {
      this.setState({ showModalDocumentoNaoCadastrado: true });
    } else if (!formando.aceitouTermo) {
      mapa
        .getTabela(this.props.contrato.numOrcamento)
        .then((response) => {
          this.setState({
            showModalTermo: true,
            clienteMapa: {
              instituicao: response.Instituicao,
              cidade: response.Cidade,
              turma: turma.curso,
            },
          });
        })
        .catch((erro) => {
          new TelegramService().novo('ERROR', [
            JSON.stringify(erro),
            'Falha no getTabela',
            `usuarioLogado: ${
              JSON.parse(sessionStorage.getItem('usuarioLogadoComissao')).login
            }`,
          ]);
        });
    } else {
      this.executarJoyride();
    }

    this.setState({ formando, itens });
  }

  usuarioEhFormando() {
    return this.props.usuario.tipo === 'formando';
  }

  distribuiuItens() {
    return this.props.contrato.distribuiuItens === 1;
  }

  formatarMoedaBR(valor) {
    if (valor) {
      valor = valor.toFixed(2).split('.');
      valor[0] = valor[0].split(/(?=(?:...)*$)/).join('.');
      return valor.join(',');
    }
    return '0,00';
  }

  abrirModalPagamento() {
    if (this.state.valorPedido < 10 && false) {
      toast.info('Atinja pelo menos R$ 10,00 em compras!');
    } else {
      let data = [
          {
            id: this.state.formando.id,
            nome: this.state.formando.nome,
            itens: [],
          },
        ],
        avulsosLiberados = true,
        itens = Object.assign([], this.props.itens);

      this.state.itens.forEach((item) => {
        if (item.quantidade > 0) {
          data[0].itens.push({
            idProduto: item.id,
            produto: item.produto,
            quantidade: item.quantidade,
            preco: this.ehItemFuturo(item) ? item.valorAdendo : item.preco,
            total:
              (this.ehItemFuturo(item) ? item.valorAdendo : item.preco) *
              item.quantidade,
          });
        }
      });

      if (this.props.contrato.avulsosLiberados === 0) {
        avulsosLiberados = false;

        this.state.formando.itens.forEach((itemComprado) => {
          if (itemComprado.tipo !== 'brinde') {
            let itemPesquisado = itens.filter((i) => {
              return i.id === itemComprado.item;
            })[0];
            if (
              itemPesquisado.observacao === 'kit' &&
              itemPesquisado.produto.substring(0, 3).toLowerCase() === 'kit' &&
              itemComprado.quantidade > 0
            ) {
              avulsosLiberados = true;
            }
          }
        });

        if (!avulsosLiberados) {
          data[0].itens.forEach((itemComprado) => {
            let itemPesquisado = itens.filter((i) => {
              return i.id === itemComprado.idProduto;
            })[0];
            if (
              itemPesquisado.observacao === 'kit' &&
              itemPesquisado.produto.substring(0, 3).toLowerCase() === 'kit'
            ) {
              avulsosLiberados = true;
            }
          });
        }
      }

      if (avulsosLiberados) {
        this.setState({ showModalPagamento: true, data });
      } else {
        toast.error('É obrigatória a compra de pelo menos 1 Kit!');
      }
    }
  }

  ehItemFuturo = (item) => {
    return item.observacao === 'itemFuturo';
  };

  copiarPix() {
    navigator.clipboard.writeText(this.state.pix.copiaCola);
    toast.success('Copiado com sucesso!');
  }

  async handleCloseModalPagamento(resposta) {
    if (resposta) {
      switch (resposta.status) {
        case 201:
          this.carregarDados();
          if (resposta.formaPagamento === 'PIX') {
            this.setState({ pix: { ...this.state.pix, showModal: true } });

            const response = await axios.post(
              `${URL_PAGAMENTO}/gerar-pix`,
              {
                cobrancaId: resposta.data.cobranca.extRef,
                numProxParcela: resposta.data.cobranca.numParcelas
                  ? resposta.data.cobranca.numParcelas
                  : null,
              },
              {
                headers: {
                  Authorization:
                    'Bearer ' + sessionStorage.getItem('tokenPagamento'),
                },
              }
            );

            if (response.data) {
              const data = response.data;
              this.setState({
                pix: {
                  ...this.state.pix,
                  base64: data.encodedImage,
                  copiaCola: data.payload,
                  carregando: false,
                },
              });
            } else {
              this.setState({ showModalVerPedidos: true });
            }
          } else {
            this.setState({ showModalVerPedidos: true });
          }
          break;
        case 500:
          if (resposta.error.response) {
            toast.error(resposta.error.response.data.erro);
          } else {
            toast.error(
              'Não foi possível finalizar a compra do seu pedido. Tente novamente mais tarde!'
            );
          }
          break;
      }
    }
    this.setState({ showModalPagamento: false });
  }

  handleClickTermoAditamento() {
    this.props
      .editarCamposFormando(this.state.formando.id, { aceitouTermo: 1 })
      .then(() => {
        this.setState({ showModalTermo: false });
        this.executarJoyride();
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  handleChangeQuantidadeItem = (id, quantidade) => {
    let { itens } = this.state,
      valorPedido = 0;

    itens.forEach((item) => {
      let valor = this.ehItemFuturo(item) ? item.valorAdendo : item.preco;

      if (item.id === id) {
        item.quantidade = Number(quantidade);
      }

      valorPedido += item.quantidade * valor;
    });

    this.setState({ itens, valorPedido });
  };

  executarJoyride() {
    if (localStorage.getItem('tutorialDistribuicaoItens') === null) {
      this.setState({ run: true });
      localStorage.setItem('tutorialDistribuicaoItens', true);
    }
  }

  _renderAlerta() {
    return this.distribuiuItens() ? (
      <div
        className='alert alert-success'
        style={{ fontWeight: 'bold', fontSize: 14 }}
      >
        <i className='fa fa-check'></i> Etapa concluída!
      </div>
    ) : null;
  }

  render() {
    return (
      <div id='itens-disponiveis'>
        {this._renderAlerta()}
        <div className='row'>
          <div className='col-md-12 col-lg-9'>
            <div className='box box-solid' id='itens-disponiveis'>
              <div className='box-header'>
                <div className='box-title'>Tabela de produtos</div>
              </div>
              <div className='box-body'>
                {this.state.itens.length > 0 ? (
                  <React.Fragment>
                    <div className='table-responsive'>
                      <table id='tableItems' className='table table-bordered'>
                        <thead
                          style={{ backgroundColor: '#222D32', color: 'white' }}
                        >
                          <tr>
                            <th>Produto</th>
                            <th>Preço</th>
                            <th>Quantidade</th>
                            <th>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: 'white' }}>
                          {this.state.itens.map((item, i) => (
                            <tr
                              key={i}
                              style={{ borderBottom: '1px solid #eee' }}
                            >
                              <td style={{ verticalAlign: 'middle' }}>
                                {item.produto}&nbsp;&nbsp;&nbsp;
                                {item.ids !== null &&
                                item.observacao === 'kit' &&
                                item.itens.split(';').length > 2 ? (
                                  <span
                                    className='label label-warning'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.setState({
                                        itemSelecionado: item,
                                        showModalInformacoes: true,
                                      });
                                    }}
                                    title='Clique para ver o que tem dentro deste kit'
                                  >
                                    ver composição
                                  </span>
                                ) : null}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                R${' '}
                                {this.formatarMoedaBR(
                                  this.ehItemFuturo(item)
                                    ? item.valorAdendo
                                    : item.preco
                                )}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                {this.distribuiuItens() ? (
                                  0
                                ) : (
                                  <input
                                    type='number'
                                    min='0'
                                    className='form-control'
                                    value={item.quantidade}
                                    onChange={(e) =>
                                      this.handleChangeQuantidadeItem(
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                R${' '}
                                {this.formatarMoedaBR(
                                  item.quantidade *
                                    (this.ehItemFuturo(item)
                                      ? item.valorAdendo
                                      : item.preco)
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              colSpan={3}
                              style={{ textAlign: 'right', color: 'red' }}
                            >
                              Total
                            </td>
                            <td style={{ color: 'red' }}>
                              R$ {this.formatarMoedaBR(this.state.valorPedido)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {this.distribuiuItens() ? (
                        <button
                          className='btn btn-danger btn-flat'
                          id='botaoFinalizarDistribuicao'
                          style={{ marginTop: 15 }}
                          disabled
                        >
                          {' '}
                          <i className='fa fa-lock'></i> Compra bloqueada{' '}
                        </button>
                      ) : (
                        <button
                          onClick={() => this.abrirModalPagamento()}
                          className='btn btn-danger btn-flat'
                          id='botaoFinalizarDistribuicao'
                          style={{ marginTop: 15 }}
                          disabled={this.state.botaoFinalizar}
                        >
                          {this.state.botaoFinalizar === true ? (
                            <span>
                              <i className='fa fa-spinner fa-spin'></i> Aguarde
                            </span>
                          ) : (
                            'Finalizar compra'
                          )}
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <h4>O seu contrato não possui nenhum produto vinculado!</h4>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showModalPagamento}
          onHide={() => this.setState({ showModalPagamento: false })}
          id='modalPagamento'
        >
          <ModalPagamento
            contrato={this.props.contrato}
            data={this.state.data}
            formando={this.state.formando}
            formatarMoedaBR={this.formatarMoedaBR}
            close={(response) => this.handleCloseModalPagamento(response)}
            usuarioLogado={this.props.usuario}
          />
        </Modal>
        <Modal
          show={this.state.showModalNotificacao}
          onHide={() => {
            this.setState({ showModalNotificacao: false });
          }}
          dialogClassName='modal-80w'
        >
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src='./images/success.png' alt='Sucesso' height='75' />
              <h2 style={{ textAlign: 'center' }}>
                Distribuição de itens finalizada!
              </h2>
              {this.props.contrato.pagamentoIndividual === 'true' ? (
                <h4 style={{ textAlign: 'center' }}>
                  <strong>
                    Enviamos um e-mail para todos os formandos com as
                    informações sobre o pagamento.
                  </strong>
                </h4>
              ) : null}
              {this.props.contrato.contratante &&
              this.props.contrato.contratante.includes('Alpha-e') ? (
                <a
                  href='./entrega'
                  className='btn btn-outline-success btn-lg'
                  style={{ marginTop: 17 }}
                >
                  Acompanhar pagamento
                </a>
              ) : (
                <React.Fragment>
                  <h4 style={{ textAlign: 'center' }}>
                    A próxima etapa agora é o cadastro das informações para
                    faturamento/entrega.
                  </h4>
                  <a
                    href='./entrega'
                    className='btn btn-outline-success btn-lg'
                    style={{ marginTop: 17 }}
                  >
                    Vamos lá!
                  </a>
                </React.Fragment>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalVerPedidos}
          onHide={() => {
            this.setState({ showModalVerPedidos: false });
          }}
          dialogClassName='modal-80w'
        >
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src='./images/success.png' alt='Sucesso' height='75' />
              <h2 style={{ textAlign: 'center' }}>
                Compra efetuada com sucesso!
              </h2>
              {this.usuarioEhFormando() ? (
                <h4 style={{ textAlign: 'center' }}>
                  <strong>
                    Em breve você receberá um e-mail confirmando o seu pedido.
                    Fique ligado (a)!
                  </strong>
                </h4>
              ) : null}
              <Link to='./meus-pedidos'>
                <button className='btn btn-outline-success btn-lg'>
                  Ver pedidos
                </button>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.pix.showModal} dialogClassName='modal-80w'>
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h2 style={{ textAlign: 'center', marginBottom: 0 }}>
                Escaneie o QRCODE abaixo com o aplicativo do seu banco ou
                utilize o Pix Copia e Cola para efetuar o pagamento:
              </h2>
              {this.state.pix.carregando ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 5,
                  }}
                >
                  <i className='fa fa-spin fa-spinner fa-2x'></i>
                </div>
              ) : (
                <React.Fragment>
                  <img
                    src={`data:image/jpeg;base64,${this.state.pix.base64}`}
                    alt='QRCODE Pix'
                    width='200px'
                  />
                  <div
                    style={{
                      backgroundColor: '#ddd',
                      width: '100px',
                      height: '1px',
                      margin: '10px 0px',
                    }}
                  ></div>
                  <div
                    className='row'
                    style={{ width: '100%', margin: '10px 0 20px' }}
                  >
                    <div
                      className='col-xs-8 col-sm-9'
                      style={{ paddingRight: 0 }}
                    >
                      <input
                        type='text'
                        className='form-control'
                        value={this.state.pix.copiaCola}
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        readOnly
                      />
                    </div>
                    <div
                      className='col-xs-4 col-sm-3'
                      style={{ paddingLeft: 0 }}
                    >
                      <button
                        className='btn btn-info btn-block'
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        onClick={this.copiarPix.bind(this)}
                      >
                        <i className='fas fa-clipboard'></i> Copiar
                      </button>
                    </div>
                  </div>
                  <button
                    className='btn btn-outline-success btn-lg'
                    onClick={() =>
                      this.setState({
                        pix: { ...this.state.pix, showModal: false },
                      })
                    }
                  >
                    Feito
                  </button>
                </React.Fragment>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalInformacoes !== false}
          onHide={() => {
            this.setState({ showModalInformacoes: false });
          }}
          dialogClassName='modal-50w'
        >
          <Modal.Header closeButton>
            <Modal.Title>Outras informações</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.itemSelecionado !== null &&
            this.state.itemSelecionado.itens !== undefined ? (
              <div>
                <h4>Composição</h4>
                <ul style={{ marginBottom: 30 }}>
                  {this.state.itemSelecionado.itens
                    .split(';')
                    .map((item, i) =>
                      item !== '' ? <li key={i}>{item}</li> : null
                    )}
                </ul>
              </div>
            ) : null}
            {this.state.itemSelecionado !== null &&
            this.state.itemSelecionado.descricao !== undefined ? (
              <div>
                <h4>Produtos utilizados</h4>
                <ul>
                  {this.state.itemSelecionado.descricao
                    .split(' / ')
                    .map((descricao, i) =>
                      descricao !== ''
                        ? descricao
                            .split(',')
                            .map((desc, j) => (
                              <li key={'desc' + i + j}>{desc}</li>
                            ))
                        : null
                    )}
                </ul>
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalDocumentoNaoCadastrado}
          dialogClassName='modal-80w'
        >
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src='./images/info.png' alt='Sucesso' height='75' />
              <h2 style={{ textAlign: 'center' }}>
                Seus dados não estão completos!
              </h2>
              <h4 style={{ textAlign: 'center' }}>
                Para fazer uma compra é necessário que o seu dados estejam
                cadastrados no sistema.
              </h4>
              <a
                href='./meus-dados'
                className='btn btn-outline-info btn-lg'
                style={{ marginTop: 17 }}
              >
                Cadastrar agora
              </a>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showModalTermo} dialogClassName='modal-80w'>
          <Modal.Body>
            <h3
              style={{
                textAlign: 'center',
                margin: '20px 0px',
                fontWeight: 600,
              }}
            >
              ADITAMENTO DE CONTRATO DE PRESTAÇÃO DE SERVIÇOS
            </h3>
            <div style={{ fontSize: 16 }}>
              <p>
                Pelo presente Aditamento de Contrato de Prestação de Serviços de
                Convites de Formatura, que firmam entre si, de um lado a empresa
                Beta Imagem Empresa Fotográfica Eireli, inscrita no CNPJ/MF sob
                o nº 07.093.119/0001-03, inscrição estadual nº 903.29089-74, com
                sede em Bandeirantes, Estado do Paraná, Rua São Paulo, 1990,
                Centro, CEP 86360-000, doravante denominada somente Alpha
                Convites; E de outro lado como futuro "formando", e que assinam
                o presente contrato, a saber:
              </p>
              <ul style={{ paddingLeft: 17 }}>
                <li>Do curso: {this.state.clienteMapa.turma};</li>
                <li>Da instituição: {this.state.clienteMapa.instituicao};</li>
                <li>Da cidade de: {this.state.clienteMapa.cidade};</li>
                <li>
                  Cuja formatura ocorrerá em:{' '}
                  {this.props.contrato.dataFormatura
                    ? this.props.contrato.dataFormatura
                        .split('T')[0]
                        .split('-')
                        .reverse()
                        .join('/')
                    : null}
                  ;
                </li>
              </ul>
              <p>
                <strong>Cláusula Primeira:</strong> Assume o aluno perante a
                Alpha Convites, os compromissos do CONTRATO DE PRESTAÇÃO DE
                SERVIÇOS, firmado entre a Alpha Convites e a Comissão de
                Formatura que os representa.
              </p>
              <p>
                <strong>Cláusula Segunda:</strong> O aluno abaixo assinado
                declara que tem ciência do contrato assinado com a Comissão de
                Formatura, quanto a aquisição de CONVITES PARA FORMATURA, nas
                formas ali estabelecidas e a este adere, não podendo,
                individualmente, alegar desconhecimento dos termos, haja vista a
                peculiaridade contratual.
              </p>
              <p>
                <strong>§1º:</strong> O aluno que por ventura venha a “REPROVAR”
                terá assegurado o direito de devolução no valor de 30% (trinta
                por cento) sendo esse benefício até o prazo máximo de 30 dias
                antes da entrada de produção e que o mesmo já tenha quitado todo
                o valor. A devolução do valor especificado será efetuada pela
                Alpha Convites, através de depósito ao aluno reprovado, mediante
                documento de solicitação e comprovação emitido pela Diretoria da
                Comissão de Formatura.
              </p>
              <p>
                <strong>Cláusula Terceira:</strong> O aluno está devidamente
                ciente que caso haja atraso no pagamento, implicará no pagamento
                em multa de 2% (dois por cento) do valor da parcela,
                monetariamente corrigida, mais juros pro rata die de 1% (um por
                cento) ao mês.
              </p>
              <p>
                <strong>Cláusula Quarta:</strong> Para adesão é necessário que o
                aluno informe todos seus dados pessoais.
              </p>
              <p>
                <strong>Cláusula Quinta:</strong> O aluno acima identificado têm
                ciência e autoriza a Comissão constante no contrato, a assinar
                outros aditamentos ao Contrato de Prestação de Serviços,
                alterações no orçamento inicial, por ampliação ou diminuição dos
                itens que o compõem, implicando em aumento ou redução do valor
                do contrato e outros que se tornem necessários.
              </p>
              <p>
                <strong>Cláusula Sexta:</strong> Os produtos aqui contratados
                são de ordem individual, tanto a quantidade como o pagamento,
                porém, a contratada produzirá a soma mínima estipulada no
                contrato de prestação de serviço (contrato n°{' '}
                {this.props.contrato.numero} / proposta n°{' '}
                {this.props.contrato.numOrcamento}). Havendo diminuição de
                integrantes ou por inadimplência ou desistência, os convites que
                sobrarem e a diferença do valor restante para a quitação desses
                serviços serão rateadas entre todos os membros da turma que
                constem em contratos individuais, de acordo com esse termo de
                adesão, sendo o envio do produto condicionado a quitação do
                contrato.
              </p>
              <p>
                <strong>§1º:</strong> Os produtos contratados somente serão
                entregues com a confirmação de quitação de todas as parcelas e
                quitação de todos os integrantes aderidos a este contrato, e
                ainda, quando o caso, após a negociação de ajuste quanto a
                quantidade a ratear, conforme descrito nesta cláusula.
              </p>
              {this.props.contrato.numero != 30726 ? (
                <p>
                  E, por estarem assim justos e contratados, o foro da cidade de
                  Bandeirantes - PR fica nomeado para dirimir quaisquer dúvidas
                  que não sejam resolvidas em acordo.
                </p>
              ) : (
                <p>
                  E, por estarem assim justos e contratados, o fórum da cidade
                  de Uberaba - MG fica nomeado para dirimir quaisquer dúvidas
                  que não sejam resolvidas em acordo.
                </p>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 30,
              }}
            >
              <Link to='./'>
                <button
                  className='btn btn-link btn-lg btn-flat'
                  style={{ marginRight: 10 }}
                >
                  Cancelar
                </button>
              </Link>
              <button
                className='btn btn-success btn-lg btn-flat'
                onClick={this.handleClickTermoAditamento.bind(this)}
              >
                Concordo
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Joyride
          continuous={true}
          run={this.state.run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={this.state.steps}
          styles={{ options: { zIndex: 10000 } }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itens: state.item.todosDistribuiveis,
    formandos: state.formando.todos,
    contrato: state.contrato.especifico,
    usuario: state.usuario.autenticado,
    numeroContrato: state.usuario.numeroContrato,
  };
};

export default connect(mapStateToProps, {
  editarCamposContrato,
  editarCamposFormando,
  adicionarItem,
  editarItem,
  cadastrarAcao,
  removerItemFormando,
  adicionarItemFormando,
  obterFormandosPeloContrato,
  obterItensTipoFTab,
  editarContrato,
  descobrirContrato,
})(DistribuirItensFormando);
