import React from 'react';
import { connect } from 'react-redux';

import axios from 'axios';
import { Modal } from 'react-bootstrap';

import {
  descobrirContrato,
  aprovarPlanilhaQuantidades,
} from '../actions/ContratoActions';
import { obterFormandosPeloContrato } from '../actions/FormandoActions';
import TelegramService from '../services/TelegramService';
import { URL_PAGAMENTO } from '../config/urls';
import TabelaItensPedido from '../components/meusPedidos/TabelaItensPedido';
import TabelaDetalhesCobranca from '../components/meusPedidos/TabelaDetalhesCobranca';
import TabelaPedidosFormando from '../components/meusPedidos/TabelaPedidosFormando';
import TabelaItensContratados from '../components/meusPedidos/TabelaItensContratados';
import TabelaParcelasCobranca from '../components/meusPedidos/TabelaParcelasCobranca';
import CardsValores from '../components/meusPedidos/CardsValores';
import AlertaAprovacao from '../components/AlertaAprovacao';
import { toast } from 'react-toastify';
import PedidosPendentes from '../components/meusPedidos/PedidosPendentes';

class MeusPedidos extends React.Component {
  state = {
    valorPago: 0,
    valorPendente: 0,
    valorTotal: 0,
    valorPagoComissao: 0,
    valorPendenteComissao: 0,
    valorTotalComissao: 0,
    data: [],
    pedidoSelecionado: null,
    formandoSelecionado: null,
    exibirMaisInformacoesFormando: 'slideOutRight',
    exibirMaisInformacoesFormandoPedido: 'slideOutRight',
    exibirMaisInformacoesPedido: 'slideOutRight',
    exibirModalCancelamento: false,
    exibirModalPendencias: false,
    exibirModalPendenciasPagamento: false,
    verificandoPedidosPendentes: false,
    pedidosPendentes: [],
    statusModalCancelamento: {},
    carregandoCancelamento: false,
    exibirModalCancelarPedido: false,
    carregandoCancelamentoPedido: false,
    idCancelamentoPedido: 0,
    exibirTabela: false,
  };

  async componentWillMount() {
    await this.iniciar();
  }

  async iniciar() {
    this.setState({ exibirTabela: false });
    await this.props
      .descobrirContrato(
        this.props.usuario.tipo,
        this.props.usuario.tipo === 'formando'
          ? this.props.numeroContrato
          : this.props.usuario.id
      )
      .then(async () => {
        await this.props.obterFormandosPeloContrato(this.props.contrato.id);
        this.setState({ exibirTabela: true });
        if (this.usuarioEhFormando()) {
          axios
            .get(`${URL_PAGAMENTO}/formando/pedidos`, {
              headers: {
                Authorization:
                  'Bearer ' + sessionStorage.getItem('tokenPagamento'),
              },
            })
            .then((response) => {
              this.setState({ data: response.data });
              this.calcularValorDosCards(response.data);
            })
            .catch((erro) => {
              new TelegramService().novo('ERROR', [
                JSON.stringify(erro),
                'Pegar os pedidos do formando',
                `usuarioLogado: ${sessionStorage.getItem(
                  'usuarioLogadoComissao'
                )}`,
              ]);
            });
        } else {
          axios
            .get(`${URL_PAGAMENTO}/pedidos-contrato`, {
              headers: {
                Authorization:
                  'Bearer ' + sessionStorage.getItem('tokenPagamento'),
              },
            })
            .then((response) => {
              this.setState({ data: response.data });
              this.calcularValorDosCardsComissao(response.data);
            })
            .catch((erro) => {
              new TelegramService().novo('ERROR', [
                JSON.stringify(erro),
                'Pegar os pedidos do contrato',
                `usuarioLogado: ${sessionStorage.getItem(
                  'usuarioLogadoComissao'
                )}`,
              ]);
            });
        }
      });
  }

  calcularValorDosCards(data) {
    let valorPago = 0,
      valorPendente = 0,
      valorTotal = 0;

    data.forEach((pedido) => {
      if (pedido.status !== 'Pedido cancelado') {
        valorPago += pedido.cobranca.valorJaPago;
        valorPendente += pedido.cobranca.valor - pedido.cobranca.valorJaPago;
        valorTotal += pedido.cobranca.valor;
      }
    });

    if (valorPendente < 0) {
      valorPendente = 0;
    }

    this.setState({ valorPago, valorPendente, valorTotal });
  }

  calcularValorDosCardsComissao(formandos) {
    let valorPagoComissao = 0,
      valorPendenteComissao = 0,
      valorTotalComissao = 0;

    formandos.forEach((formando) => {
      formando.pedidos.forEach((pedido) => {
        if (pedido.status !== 'Pedido cancelado') {
          valorPagoComissao += pedido.cobranca.valorJaPago;
          valorPendenteComissao +=
            pedido.cobranca.valor - pedido.cobranca.valorJaPago;
          valorTotalComissao += pedido.cobranca.valor;
        }
      });
    });

    if (valorPendenteComissao < 0) {
      valorPendenteComissao = 0;
    }

    this.setState({
      valorPagoComissao,
      valorPendenteComissao,
      valorTotalComissao,
    });
  }

  formatarMoedaBr(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  usuarioEhFormando() {
    return this.props.usuario.tipo === 'formando';
  }

  handleClickPedido(pedido) {
    this.setState({ pedidoSelecionado: pedido }, () => {
      this.setState({ exibirMaisInformacoesPedido: 'slideInRight' });
    });
  }

  handleClickFormando(formando) {
    this.calcularValorDosCards(formando.pedidos);
    this.setState({ formandoSelecionado: formando }, () => {
      this.setState({ exibirMaisInformacoesFormando: 'slideInRight' });
    });
  }

  handleClickFormandoPedido(pedido) {
    this.setState({ pedidoSelecionado: pedido }, () => {
      this.setState({ exibirMaisInformacoesFormandoPedido: 'slideInRight' });
    });
  }

  handleCloseMaisInformacoesPedido() {
    this.setState({ exibirMaisInformacoesPedido: 'slideOutRight' }, () => {
      setTimeout(() => {
        this.setState({ pedidoSelecionado: null });
      }, 800);
    });
  }

  handleCloseMaisInformacoesFormando() {
    this.setState({ exibirMaisInformacoesFormando: 'slideOutRight' }, () => {
      setTimeout(() => {
        this.setState({ formandoSelecionado: null, pedidoSelecionado: null });
      }, 800);
    });
  }

  handleCloseMaisInformacoesFormandoPedido() {
    this.setState(
      {
        exibirMaisInformacoesFormandoPedido: 'slideOutRight',
        exibirMaisInformacoesFormando: 'slideOutRight',
      },
      () => {
        setTimeout(() => {
          this.setState({ formandoSelecionado: null, pedidoSelecionado: null });
        }, 800);
      }
    );
  }

  handleComeBackMaisInformacoesFormando() {
    this.setState(
      { exibirMaisInformacoesFormandoPedido: 'slideOutRight' },
      () => {
        setTimeout(() => {
          this.setState({ pedidoSelecionado: null });
        }, 800);
      }
    );
  }

  handleClickModalCancelamento(id, status) {
    this.setState({
      exibirModalCancelamento: true,
      statusModalCancelamento: { id, status },
    });
  }

  handleClickOpenFormandoEPedido(formando, pedido) {
    this.handleClickFormando(formando);
    this.handleClickFormandoPedido(pedido);
  }

  handleSubmitCancelamento() {
    this.setState({ carregandoCancelamento: true });
    axios
      .delete(
        `${URL_PAGAMENTO}/pedido/cancelar/${this.state.statusModalCancelamento.id}/${this.state.statusModalCancelamento.status}`,
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenPagamento'),
          },
        }
      )
      .then((response) => {
        toast.success(
          `${
            this.state.statusModalCancelamento.status === 0
              ? 'O cancelamento do pedido foi recusado com sucesso!'
              : 'Cancelamento aprovado com sucesso!'
          }`
        );
        this.setState({
          carregandoCancelamento: false,
          exibirModalCancelamento: false,
        });
        this.iniciar();
      })
      .catch((erro) => {
        toast.error('Não foi possível confirmar a ação');
        new TelegramService().novo('ERROR', [
          JSON.stringify(erro),
          'Cancelar pedido do formando - comissão',
          `usuarioLogado: ${sessionStorage.getItem('usuarioLogadoComissao')}`,
        ]);
        this.setState({
          carregandoCancelamento: false,
          exibirModalCancelamento: false,
        });
      });
  }

  handleSubmitCancelamentoPedido() {
    this.setState({ carregandoCancelamentoPedido: true });
    axios
      .delete(
        `${URL_PAGAMENTO}/pedido/${this.state.pedidoSelecionado.pedido}`,
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenPagamento'),
          },
        }
      )
      .then((response) => {
        this.handleCloseMaisInformacoesPedido();
        this.iniciar();
        this.setState({
          carregandoCancelamentoPedido: false,
          exibirModalCancelarPedido: false,
        });
      })
      .catch((erro) => {
        toast.error(
          'Não conseguimos processar a sua solicitação no momento. Em breve entraremos em contato!'
        );
        new TelegramService().novo('ERROR', [
          JSON.stringify(erro),
          'Cancelar pedido do formando - formando',
          `usuarioLogado: ${sessionStorage.getItem('usuarioLogadoComissao')}`,
        ]);
        this.setState({
          carregandoCancelamentoPedido: false,
          exibirModalCancelarPedido: false,
        });
      });
  }

  handleClickAprovarPlanilha() {
    const { cadastrouFormandos, distribuiuBrindes, endereco } =
      this.props.contrato;

    if (
      cadastrouFormandos === 0 ||
      distribuiuBrindes === 0 ||
      endereco === undefined
    ) {
      this.setState({ exibirModalPendencias: true });
    } else {
      this.getPedidosPendentes();
    }
  }

  getPedidosPendentes() {
    this.setState({ verificandoPedidosPendentes: true });
    axios
      .get(`${URL_PAGAMENTO}/pedidos-pendentes-contrato`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('tokenPagamento'),
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.pedidosPendentes.length > 0) {
          this.setState({
            exibirModalPendenciasPagamento: true,
            pedidosPendentes: result.pedidosPendentes,
          });
        } else {
          this.setState({
            exibirModalPendenciasPagamento: false,
            pedidosPendentes: [],
          });
          this.props.aprovarPlanilhaQuantidades(this.props.contrato);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.setState({ verificandoPedidosPendentes: false }));
  }

  render() {
    return (
      <div>
        {this.usuarioEhFormando() ? (
          <div>
            <div className='row'>
              <CardsValores
                formatarMoedaBr={this.formatarMoedaBr}
                valorPago={this.state.valorPago}
                valorPendente={this.state.valorPendente}
                valorTotal={this.state.valorTotal}
              />
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='box box-solid'>
                  <div className='box-header'>
                    <div className='box-title'>Meus pedidos</div>
                  </div>
                  <div className='box-body'>
                    <TabelaPedidosFormando
                      handleClickPedido={(resposta) =>
                        this.handleClickPedido.bind(this, resposta)
                      }
                      formatarMoedaBr={this.formatarMoedaBr}
                      data={this.state.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {this.props.contrato.confirmarPlanilha &&
            this.props.contrato.dtAprPlanilha == null &&
            !this.props.esconderAlertaPlanilha ? (
              <div className='row'>
                <div className='col-sm-6 col-md-6 col-lg-4'>
                  <AlertaAprovacao
                    aprovar={this.handleClickAprovarPlanilha.bind(this)}
                    progress={this.state.verificandoPedidosPendentes}
                  />
                </div>
              </div>
            ) : null}
            <div className='row'>
              <CardsValores
                formatarMoedaBr={this.formatarMoedaBr}
                valorPago={this.state.valorPagoComissao}
                valorPendente={this.state.valorPendenteComissao}
                valorTotal={this.state.valorTotalComissao}
              />
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                {this.state.exibirTabela ? (
                  <TabelaItensContratados
                    handleClickFormando={(resposta) =>
                      this.handleClickFormando.bind(this, resposta)
                    }
                    contrato={this.props.contrato}
                    formandos={this.props.formandos}
                    pedidos={this.state.data}
                    handleClickOpenFormandoEPedido={(formando, pedido) =>
                      this.handleClickOpenFormandoEPedido.bind(
                        this,
                        formando,
                        pedido
                      )
                    }
                    handleClickModalCancelamento={(id, status) =>
                      this.handleClickModalCancelamento.bind(this, id, status)
                    }
                  />
                ) : (
                  <div>
                    <i className='fa fa-spinner fa-spin'></i> Aguarde
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.formandoSelecionado ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,.8)',
              zIndex: 3000,
            }}
          >
            <div
              className={`sidenav animated ${this.state.exibirMaisInformacoesFormando}`}
            >
              <div className='off-canvas-title'>
                <h3>{this.state.formandoSelecionado.nome}</h3>
                <button
                  type='button'
                  onClick={this.handleCloseMaisInformacoesFormando.bind(this)}
                  style={{
                    position: 'absolute',
                    top: 15,
                    right: 15,
                    padding: '7px 12px 5px',
                    borderRadius: 25,
                    boxShadow: '0px 1px 5px rgba(0,0,0,.12)',
                    backgroundColor: 'transparent',
                  }}
                  className='btn btn-default btn-lg'
                >
                  <i className='fas fa-times' />
                </button>
              </div>
              <div className='sidenav-content'>
                <div className='row'>
                  <CardsValores
                    formatarMoedaBr={this.formatarMoedaBr}
                    valorPago={this.state.valorPago}
                    valorPendente={this.state.valorPendente}
                    valorTotal={this.state.valorTotal}
                  />
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='box box-solid'>
                      <div className='box-body'>
                        <TabelaPedidosFormando
                          handleClickPedido={(resposta) =>
                            this.handleClickFormandoPedido.bind(this, resposta)
                          }
                          formatarMoedaBr={this.formatarMoedaBr}
                          data={this.state.formandoSelecionado.pedidos}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.pedidoSelecionado ? (
              <div
                className={`sidenav animated ${this.state.exibirMaisInformacoesFormandoPedido}`}
              >
                <div className='off-canvas-title'>
                  <h3 style={{ display: 'flex' }}>
                    <span style={{ marginRight: 5 }}>
                      Informações do pedido
                    </span>
                    <strong style={{ display: 'flex', alignItems: 'center' }}>
                      #{this.state.pedidoSelecionado.pedido}
                      {this.state.pedidoSelecionado.status ===
                      'Pedido cancelado' ? (
                        <span
                          style={{
                            backgroundColor: '#dd4b39',
                            borderRadius: 25,
                            fontSize: 10,
                            padding: '3px 7px',
                            color: 'white',
                            marginLeft: 5,
                          }}
                        >
                          {' '}
                          CANCELADO
                        </span>
                      ) : this.state.pedidoSelecionado.status ===
                        'Pedido em cancelamento' ? (
                        <span
                          style={{
                            backgroundColor: '#f39c12',
                            borderRadius: 25,
                            fontSize: 10,
                            padding: '3px 7px',
                            color: 'white',
                            marginLeft: 5,
                          }}
                        >
                          {' '}
                          CANCELAMENTO SOLICITADO
                        </span>
                      ) : null}
                    </strong>
                  </h3>
                  <button
                    type='button'
                    onClick={this.handleCloseMaisInformacoesFormandoPedido.bind(
                      this
                    )}
                    style={{
                      position: 'absolute',
                      top: 15,
                      right: 15,
                      padding: '7px 12px 5px',
                      borderRadius: 25,
                      boxShadow: '0px 1px 5px rgba(0,0,0,.12)',
                      backgroundColor: 'transparent',
                    }}
                    className='btn btn-default btn-lg'
                  >
                    <i className='fas fa-times' />
                  </button>
                  <button
                    type='button'
                    onClick={this.handleComeBackMaisInformacoesFormando.bind(
                      this
                    )}
                    style={{
                      position: 'absolute',
                      top: 15,
                      right: 80,
                      padding: '7px 13px 5px 12px',
                      borderRadius: 25,
                      boxShadow: '0px 1px 5px rgba(0,0,0,.12)',
                      backgroundColor: 'transparent',
                    }}
                    className='btn btn-default btn-lg'
                  >
                    <i className='fas fa-chevron-left' />
                  </button>
                </div>
                <div className='sidenav-content'>
                  <TabelaItensPedido
                    formatarMoedaBr={this.formatarMoedaBr}
                    pedidoSelecionado={this.state.pedidoSelecionado}
                  />
                  <TabelaDetalhesCobranca
                    formatarMoedaBr={this.formatarMoedaBr}
                    pedidoSelecionado={this.state.pedidoSelecionado}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : this.state.pedidoSelecionado ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,.8)',
              zIndex: 1000,
            }}
          >
            <div
              className={`sidenav animated ${this.state.exibirMaisInformacoesPedido}`}
            >
              <div className='off-canvas-title'>
                <h3 style={{ display: 'flex' }}>
                  <span style={{ marginRight: 5 }}>Informações do pedido</span>
                  <strong style={{ display: 'flex', alignItems: 'center' }}>
                    #{this.state.pedidoSelecionado.pedido}
                    {this.state.pedidoSelecionado.status ===
                    'Pedido cancelado' ? (
                      <span
                        style={{
                          backgroundColor: '#dd4b39',
                          borderRadius: 25,
                          fontSize: 10,
                          padding: '3px 7px',
                          color: 'white',
                          marginLeft: 5,
                        }}
                      >
                        {' '}
                        CANCELADO
                      </span>
                    ) : this.state.pedidoSelecionado.status ===
                      'Pedido em cancelamento' ? (
                      <span
                        style={{
                          backgroundColor: '#f39c12',
                          borderRadius: 25,
                          fontSize: 10,
                          padding: '3px 7px',
                          color: 'white',
                          marginLeft: 5,
                        }}
                      >
                        {' '}
                        CANCELAMENTO SOLICITADO
                      </span>
                    ) : null}
                  </strong>
                </h3>
                <button
                  type='button'
                  onClick={this.handleCloseMaisInformacoesPedido.bind(this)}
                  style={{
                    position: 'absolute',
                    top: 15,
                    right: 15,
                    padding: '7px 12px 5px',
                    borderRadius: 25,
                    boxShadow: '0px 1px 5px rgba(0,0,0,.12)',
                    backgroundColor: 'transparent',
                  }}
                  className='btn btn-default btn-lg'
                >
                  <i className='fas fa-times' />
                </button>
              </div>
              <div className='sidenav-content'>
                <TabelaItensPedido
                  formatarMoedaBr={this.formatarMoedaBr}
                  pedidoSelecionado={this.state.pedidoSelecionado}
                />
                <TabelaParcelasCobranca
                  dataMaxPagto={this.props.contrato.dataMaximaPagto}
                  pedidoID={this.state.pedidoSelecionado.pedido}
                  pedido={this.state.pedidoSelecionado}
                />
                <TabelaDetalhesCobranca
                  formatarMoedaBr={this.formatarMoedaBr}
                  pedidoSelecionado={this.state.pedidoSelecionado}
                />
                {this.state.pedidoSelecionado.status === 'Pendente' &&
                !this.props.contrato.dtAprPlanilha ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                      className='btn btn-danger btn-flat'
                      onClick={() =>
                        this.setState({ exibirModalCancelarPedido: true })
                      }
                    >
                      Solicitar cancelamento
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <Modal
          show={this.state.exibirModalCancelamento}
          onHide={() => this.setState({ exibirModalCancelamento: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmação de ação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Você tem certeza que{' '}
            <strong>
              {this.state.statusModalCancelamento.status === 0 ? 'não' : ''}{' '}
              quer
            </strong>{' '}
            permitir o cancelamento deste pedido? Essa ação não poderá ser
            desfeita.
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.setState({ exibirModalCancelamento: false })}
              disabled={this.state.carregandoCancelamento}
              className='btn btn-link btn-flat'
            >
              Cancelar
            </button>
            <button
              onClick={this.handleSubmitCancelamento.bind(this)}
              disabled={this.state.carregandoCancelamento}
              className='btn btn-danger btn-flat'
            >
              {this.state.carregandoCancelamento ? (
                <span>
                  <i className='fa fa-spinner fa-spin'></i> Confirmando
                </span>
              ) : (
                'Confirmar'
              )}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.exibirModalCancelarPedido}
          onHide={() => this.setState({ exibirModalCancelarPedido: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmação de ação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Você tem certeza que{' '}
            <strong>
              {this.state.statusModalCancelamento.status === 0 ? 'não' : ''}{' '}
              quer
            </strong>{' '}
            solicitar o cancelamento deste pedido?
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() =>
                this.setState({ exibirModalCancelarPedido: false })
              }
              disabled={this.state.carregandoCancelamentoPedido}
              className='btn btn-link btn-flat'
            >
              Cancelar
            </button>
            <button
              onClick={this.handleSubmitCancelamentoPedido.bind(this)}
              disabled={this.state.carregandoCancelamentoPedido}
              className='btn btn-danger btn-flat'
            >
              {this.state.carregandoCancelamentoPedido ? (
                <span>
                  <i className='fa fa-spinner fa-spin'></i> Confirmando
                </span>
              ) : (
                'Confirmar'
              )}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.exibirModalPendencias}
          onHide={() => this.setState({ exibirModalPendencias: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ações pendentes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Você ainda não pode confirmar as quantidades porque você ainda
            precisa:
            <ul>
              {this.props.contrato.cadastrouFormandos === 0 ? (
                <li>finalizar a etapa do cadastro de formandos.</li>
              ) : null}
              {this.props.contrato.distribuiuBrindes === 0 ? (
                <li>finalizar a etapa da distribuição de brindes.</li>
              ) : null}
              {this.props.contrato.endereco === undefined ? (
                <li>
                  adicionar um endereço para faturamento/entrega dos convites.
                </li>
              ) : null}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.setState({ exibirModalPendencias: false })}
              className='btn btn-link btn-flat'
            >
              Entendi
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.exibirModalPendenciasPagamento}
          onHide={() =>
            this.setState({ exibirModalPendenciasPagamento: false })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Pedidos com pagamento pendentes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PedidosPendentes
              formandos={this.state.pedidosPendentes}
              abrirPedido={(pedido) =>
                this.handleClickPedido.bind(this, pedido)
              }
              refresh={() => {
                this.setState({ pedidosPendentes: [] });
                this.getPedidosPendentes();
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() =>
                this.setState({ exibirModalPendenciasPagamento: false })
              }
              className='btn btn-link btn-flat'
            >
              Entendi
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    contrato: state.contrato.especifico,
    formandos: state.formando.todos,
    numeroContrato: state.usuario.numeroContrato,
    esconderAlertaPlanilha: state.contrato.esconderAlertaPlanilha,
  };
};

export default connect(mapStateToProps, {
  obterFormandosPeloContrato,
  descobrirContrato,
  aprovarPlanilhaQuantidades,
})(MeusPedidos);
