let FTP_URL = "https://alphaplay.alphaconvites.com.br/videos/ALPHAE/publicacoes/",
  URL_API_IP = "https://ipinfo.io/json?token=19977f307683df",
  URL_EMAIL = "https://mail.alphaultrapress.com.br",
  URL_TELEGRAMLOG_API = "https://log.alphaultrapress.com.br/log",
  EMAIL_PESQUISASATISFACAO = "atendimento01@alphaeditora.com.br",
  EMAIL_PLANILHACONFIRMACAOOK = "suporte@alphaeditora.com.br",
  REQUEST_URL = "https://gateway.alphaeditora.com.br/api/agencia",
  URL_MAPA = "https://gateway.alphaeditora.com.br/api/mapavendas",
  URL_ALPHAE_GRAPHQL = "https://gateway.alphaeditora.com.br/graphql",
  URL_PAGAMENTO = "https://pagamento.alphaeditora.com.br/api/agencia-pagamento",
  URL_ALPHAE = "https://api.alphae.com.br";
  // EMAIL_PESQUISASATISFACAO = "vitor@alphaeditora.com.br",
  // REQUEST_URL = "https://comissaoteste.alphaeditora.com.br:25000/api",
  // URL_MAPA = "https://mapavendasteste.alphaeditora.com.br:5122/api/mapavendas",
  // URL_ALPHAE_GRAPHQL = "https://centaurigraphql.alphaultrapress.com.br/graphql",
  // URL_ALPHAE = "https://centauriapi.alphaultrapress.com.br";

if (process.env.NODE_ENV === "development") {
  // EMAIL_PESQUISASATISFACAO = "vitor@alphaeditora.com.br";
  // REQUEST_URL = "http://localhost:6001/api/agencia";
  // URL_PAGAMENTO = "http://localhost:5603/api/agencia-pagamento";
  // URL_MAPA = "http://localhost:5124/api/mapavendas";
  // URL_ALPHAE = "https://centauriapi.alphaultrapress.com.br";
  // URL_ALPHAE_GRAPHQL = "https://centaurigraphql.alphaultrapress.com.br/graphql";
}

export { REQUEST_URL, FTP_URL, URL_API_IP, URL_EMAIL, URL_MAPA, URL_ALPHAE, URL_ALPHAE_GRAPHQL, EMAIL_PESQUISASATISFACAO, URL_TELEGRAMLOG_API, URL_PAGAMENTO, EMAIL_PLANILHACONFIRMACAOOK };
