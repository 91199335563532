import {toast} from "react-toastify";

export function showErrorASAAS(response) {
  if (
    response.responseError !== undefined &&
    response.responseError.errors !== undefined
  ) {
    response.responseError.errors.map((error) => {
      if (error.description !== undefined) {
        toast.error(error.description);
        console.log(error);
      }
    })
  }
}