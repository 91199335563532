import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todos: [],
  carregando: false,
  erro: null,
  ultimo: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_ITEM_FORMANDO':
      return {...state, carregando: true};
    case 'ERRO_ITEM_FORMANDO':
      toast.error(action.erro.message, 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_TODOS_ITENS_FORMANDO':
      return {...state, carregando: false, todos: action.itens};
    case 'NOVO_ITEM_FORMANDO':
      return {...state, carregando: false, todos: state.todos.concat(action.itemFormando), ultimo: action.itemFormando};
    case 'ALTERAR_ITEM_FORMANDO':
      //toast.success("Item alterado com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((item) => {return item.id!==action.itemFormando.id}).concat(action.itemFormando)};
    case 'REMOVER_ITEM_FORMANDO':
      //toast.success("Item removido com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((item) => {return item.id!==action.id})};
    default:
      return state;
  }
};
