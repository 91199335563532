import React from 'react';
import Modal from 'react-responsive-modal';
//import { FTP_URL } from '../../../administracao/src/config/urls';
//import {GOOGLE_DRIVE_URL} from '../config/urls';

export default class Preview extends React.Component {
  state = {
    open: false
  }

  open(){
    this.setState({open: true});
  }

  close(){
    this.setState({open: false});
  }

  componentDidUpdate(){
    if(this.props.paginas.length>0){
      let img = new Image();
      img.addEventListener('load', (event) => {
        let width = 0;
        let height = 0;
        if(window.innerWidth<event.path[0].naturalWidth){
          width = window.innerWidth;
        }else{
          width = event.path[0].naturalWidth
        }
        if(window.innerHeight<event.path[0].naturalHeight){
          height = window.innerHeight;
        }else{
          height = event.path[0].naturalHeight
        }
        window.iniciarWowbook(width, height);
      });
      //img.src = `${FTP_URL}${this.props.paginas[0].nome}`;
      //alert(window.innerWidth);
      //alert(window.innerHeight);

    }
  }

  render(){
    return null;
  }
}
