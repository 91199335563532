import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_COMENTARIO"
  };
}

const erro = (erro) => {
  if(erro.statusCode===401){
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  }else{
    return {
      type: "ERRO_COMENTARIO",
      erro
    }
  }
}

const novoComentario = (comentario) => {
  return {
    type: "NOVO_COMENTARIO",
    comentario
  }
}

const alterarComentario = (comentario) => {
  return {
    type: "ALTERAR_COMENTARIO",
    comentario
  }
}

const removerComentario = (comentario) => {
  return {
    type: "REMOVER_COMENTARIO",
    comentario
  }
}

export const adicionarComentario = (comentario) => {
  return dispatch => {
    dispatch(requisitar());
    comentario.ip = sessionStorage.getItem("ip");
    return axios.post(`${REQUEST_URL}/comentarios`, comentario).then((response) => {
      dispatch(novoComentario(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const editarComentario = (comentario, id) => {
  return dispatch => {
    dispatch(requisitar());
    comentario.ip = sessionStorage.getItem("ip");
    return axios.patch(`${REQUEST_URL}/comentarios/${id}`, comentario).then((response) => {
      dispatch(alterarComentario(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const deletarComentario = (id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.delete(`${REQUEST_URL}/comentarios/${id}`).then((response) => {
      dispatch(removerComentario(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
