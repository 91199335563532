import React from 'react';
import {Link} from 'react-router-dom';

export default class BoxPublicacoes extends React.Component {
  jaAprovada(){
    this.props.publicacoes.map(publicacao => {
      publicacao.paginas.map(pagina => {
        if(pagina.comentarios.length>0){
          return true;
        }
      })
    });
    return false;
  }
  getUrlImg(){
    let tipo = this.props.tipo
    switch (tipo) {
      case "paginacao":
        return "./images/icons-aprov/CONVITE.png"
      case "capa-dura":
        return "./images/icons-aprov/CAPA-DURA.png"
      case "embalagens":
        return "./images/icons-aprov/EMBALAGEM.png"
      case "encarte":
        return "./images/icons-aprov/ENCARTE.png"
      case "convite-simples":
        return "./images/icons-aprov/CONVITE-SIMPLES.png"
      case "senha-de-entrada":
        return "./images/icons-aprov/SENHA.png"
      case "missal":
        return "./images/icons-aprov/MISSAL.png"
      case "outros-produtos":
        return "./images/icons-aprov/OUTROS-PRODUTOS.png"
      case "personalizadas":
        return "./images/icons-aprov/OUTROS-PRODUTOS.png"
      default:

    }
  }
  render(){
    let btn = "";
    let texto = ""
    if(this.props.publicacoes.length>0 && this.props.publicacoes[0].dataAprovacao){
      btn = "btn-success";
      if (this.props.publicacoes[0].status === "aprovado com correções") {
        texto = "Aprovada com Correções";
      } else {
        texto = "Aprovada";
      }
    }else{
      btn = "btn-primary";
      texto = "Em andamento";
      if(this.props.publicacoes.length>0 && this.props.publicacoes[0].status === "aguardando"){
        btn = "btn-warning";
        texto = "Nova publicação";
      }
    }
    return(
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" style={{marginBottom: 10}}>
        <Link onClick={this.props.publicacoes.length===0 ? (event) => event.preventDefault() : null} to={`/publicacoes/${this.props.tipo ? this.props.tipo : ""}`} >
        <div className="small-box bg-aqua">
          <div className="inner" style={{position:"relative",zIndex:2}}>
            <h2 style={{zIndex:1,color:"#fff", WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: "rgb(150, 150, 150)", textShadow: "1px 1px rgb(0, 192, 239)",fontWeight: 700}}>{this.props.titulo}</h2>
          </div>
          <div className="icon" style={{zIndex:1}}>
            {/*<i className="ion ion-md-book"></i>*/}
            {<img src={this.getUrlImg()} style={{height:50,marginBottom:20}} alt="icone"></img>}
          </div>
          <div className="small-box-footer" style={{background:"#0000006e"}}>
            {this.props.publicacoes.length>0 ? `Publicado ${new Date(this.props.publicacoes[0].dataPublicacao).toLocaleString()}` : "Não há publicações"} &nbsp;&nbsp;
            <i className="fa fa-arrow-circle-right"></i>
          </div>
        </div>
        </Link>
        <div style={{marginTop: -15,textAlign:"center"}} className={` btn-flat btn-block btn-xs ${btn}`}>{texto}</div>
        {
          this.props.tipoUsuario && this.props.tipoUsuario === "formando" ?
            null
          : <div style={{textAlign: "center", marginTop: 5}}>
              <button className="btn btn-link" onClick={() => this.props.historico(this.props.publicacoes)} data-toggle="modal" data-target="#modal-historico" type="button" style={{boxShadow: "0 0 0 0", outline: "0 none"}}>
                <i className="fa fa-info-circle"></i> &nbsp; Histórico de Publicações
              </button>
            </div>
        }
      </div>
    )
  }
}
