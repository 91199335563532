import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_CONVITE"
  };
}

const erro = (erro) => {
    return {
      type: "ERRO_CONVITE",
      erro
    }
}

const obterPeloContrato = (convite) =>{
  return {
    type: "OBTER_CONVITE_PELO_CONTRATO",
    convite
  }
}

export const obterConvite = (contrato) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/convites/findOne?filter={"where":{"contrato":${contrato}},"include":{"publicacoes": [{"paginas": ["comentarios"]}]}}`).then((response) => {
      dispatch(obterPeloContrato(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
