import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todos: [],
  especifico: {
    id: 0,
    status: "",
    endereco:{},
    dataInicio: new Date(),
    emailComissao: "",
    dono: {
      nome: ""
    },
    formandos: [],
    itens: []
  },
  esconderAlertaPlanilha: false,
  carregando: false,
  erro: null,
  ultimo: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_CONTRATO':
      return {...state, carregando: true};
    case 'ERRO_CONTRATO':
      toast.error(action.erro.message, {autoClose: false});
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_TODOS_CONTRATOS':
      return {...state, carregando: false, todos: action.contratos};
    case 'OBTER_ESPECIFICO':
      return {...state, carregando: false, especifico: action.contrato};
    case 'OBTER_ESPECIFICO_USUARIO':
      action.contrato.publicacoes = action.contrato.publicacoes.filter(p => p.visivel);
      return {...state, carregando: false, especifico: action.contrato};
    case 'SALVAR_ENDERECO':
      toast.success("Informaçãoes de entrega atualizadas", 5000);
      return {...state, especifico: {...state.especifico, endereco: action.endereco}, carregando: false};
    case 'PLANILHA_DISTRIBUICAO_APROVADA':
      if (action.status) {
        toast.success("Planilha aprovada com sucesso!", 5000);
      }
      return {...state, esconderAlertaPlanilha: true};
    case 'NOVO_CONTRATO':
      toast.success("Contrato cadastrado com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.concat(action.contrato), ultimo: action.contrato};
    case 'ALTERAR_CONTRATO':
      let especifico = action.contrato;
      especifico.formandos = state.especifico.formandos;
      especifico.dono = state.especifico.dono;
      especifico.usuario = state.especifico.usuario;
      especifico.itens = state.especifico.itens;
      return {...state, carregando: false, todos: state.todos.filter((contrato) => {return contrato.id!==action.contrato.id}).concat(action.contrato), especifico};
    case 'REMOVER_CONTRATO':
      toast.success("Contrato removido com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((contrato) => {return contrato.id!==action.id})};
      case 'TRANSFERIR_CONTRATO':
        toast.success("Contrato tranferido com sucesso.", 5000);
        return {...state, carregando: false, todos: state.todos.filter((contrato) => {return contrato.id!==action.contrato.id}).concat(action.contrato)};
    default:
      return state;
  }
};
