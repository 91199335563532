import React from 'react';
import Header from './Header';
import Menu from './Menu';
import {caminhoAtual} from '../actions/CaminhoActions';
import {obterTutorial} from '../actions/TutorialActions';
import {connect} from 'react-redux';

import $ from "jquery";


class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos:{
        Contrato:"https://www.youtube.com/embed/_KDeAMC-AOs",
        Formandos:"https://www.youtube.com/embed/tMWkeBIohBs",
        Itens:"https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
        Entrega:"https://www.youtube.com/embed/tMWkeBIohBs",
        Aprovacao:"https://www.youtube.com/embed/80DeHNNEWyo"
      },
      tituloVideo: "",
      pagina: "",
      showModalBanner: true  
    }
  }

  componentWillMount(){
    this.props.obterTutorial();
  }

  componentDidMount() {
    window.dispatchEvent(new Event('resize'));
    window.pararVideo();
    this.setState({showModalBanner: true});
    this.atualizarVideoTutorial();
  }

  componentDidUpdate () {
    if (this.state.pagina !== this.props.title) {
      this.atualizarVideoTutorial();
    }
  }

  atualizarVideoTutorial() {
    let titulo = "";
    switch (this.props.title) {
      case "Contrato":
        titulo = "Contrato";
        this.setState({showModalBanner: true});
        break;
      case "Formandos":
        titulo = "Formandos";
        break;
      case "Itens":
        titulo = "Itens";
        break;
      case "Entrega":
        titulo = "Entrega";
        break;
      case "Aprovação do Convite":
        titulo = "Aprovacao";
        this.setState({showModalBanner: true});
        break;
      case "Publicação":
        titulo = "Aprovacao";
        break;
    }
    this.setState({tituloVideo: titulo, pagina: this.props.title});
  }

  fecharMenu () {
    $("#overlay-menuPrincipal").css("display", "none");
    $("body").removeClass("sidebar-open");
  }

  render() {
    this.props.title
      ? document.title = this.props.title + " :: Alpha Ultrapress"
      : document.title = "Página não encontrada :: Alpha Ultrapress";
    return (
      <div>
        <Header/>
        <Menu path={this.props.location.pathname}/>
        <div id="overlay-menuPrincipal" onClick={this.fecharMenu.bind(this)} style={{display: "none", height: "calc(100% - 102px)", width: "100vw", backgroundColor: "rgba(0,0,0,.5)", position: "absolute", zIndex: 20}}></div>
        <div className="content-wrapper height-full" style={{position: "relative !important"}}>
          <div className="content-header">
            <h1>
              {this.props.titulo}
              <span className="pull-right">{this.props.subtitle}</span>            
            </h1>
          </div>
          <section className="container-fluid">
            { this.props.children }
          </section>
        </div>
        <div className="modal modal-success fade" id="modal-video" >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span></button>
                <h4 className="modal-title">Video tutorial desta pagina!</h4>
              </div>
              <div className="modal-body">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe title={this.props.tutorial[this.state.tituloVideo]} className="embed-responsive-item" src={this.state.tituloVideo ? this.props.tutorial[this.state.tituloVideo.toLowerCase()] : this.state.videos[this.state.tituloVideo]}  allowFullScreen></iframe>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline pull-left flat" data-dismiss="modal">Sair</button>
                <a href={this.props.tutorial ? this.props.tutorial[this.state.tituloVideo.toLowerCase()] : this.state.videos[this.state.tituloVideo.toLowerCase()]} className="btn btn-outline pull-right flat">Assistir no Youtube</a>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showModalBanner && ((this.props.location.pathname === "/aprovacao-convite" && sessionStorage.getItem("bannerSac") === null) || (sessionStorage.getItem("bannerInstagram") === null)) ?
            <div id="modalBanner">
              { 
                this.props.location.pathname === "/aprovacao-convite" ?
                  sessionStorage.getItem("bannerSac") === null ?
                    <div>
                      <img src="images/banner_sac.jpg" id="imgModalBanner"/>
                      {sessionStorage.setItem("banner", "bannerSac")}
                    </div>
                  : null
                : sessionStorage.getItem("bannerInstagram") === null ? 
                  <div>
                    <a href="https://www.instagram.com/alphaconvites/" target="_blank"><img src="images/insta_div.jpg" id="imgModalBanner"/></a>
                    {sessionStorage.setItem("banner", "bannerInstagram")}
                  </div>
                : null
              }
              <div id="botaoModalBanner" onClick={() => {this.setState({showModalBanner: false}); sessionStorage.setItem(sessionStorage.getItem("banner"), true)}}><strong>X</strong></div>
            </div>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    titulo: state.caminho.titulo,
    tutorial: state.tutorial.tutorial
  }
}

export default connect(mapStateToProps, {obterTutorial, caminhoAtual})(Layout);
