import React from 'react';

import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class TabelaFormandos extends React.Component {
  
  state = {
    searchedColumn: {}
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render () {
    const columns = [
      {
        title: 'Nome',
        key: 'nome',
        ...this.getColumnSearchProps('nome'),
        dataIndex: 'nome'
      },
      {
        title: 'E-mail',
        key: 'email',
        ...this.getColumnSearchProps('email'),
        dataIndex: 'email'
      },
      {
        title: 'Celular',
        key: 'celular',
        ...this.getColumnSearchProps('celular'),
        dataIndex: 'celular'
      },
      {
        title: 'Curso',
        key: 'curso',
        ...this.getColumnSearchProps('curso'),
        dataIndex: 'curso'
      },
      {
        title: 'Ações',
        key: 'action',
        render: (record) => (
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <button type="button" className="btn btn-link" onClick={() => this.props.editarFormando(record)}><i className="fas fa-edit"></i> Editar</button>
            { record.usuario ? <button type="button" className="btn btn-link" onClick={() => this.props.reenviarEmail(record)}><i className="fas fa-envelope"></i> Reenviar acesso</button> : null }
            { this.props.cadastrouFormandos || record.usuario ? null : <button type="button" className="btn btn-link" onClick={() => this.props.excluirFormando(record.id)}><i className="fas fa-trash"></i> Excluir</button> }
          </div>
        ),
      }
    ];
    return (   
      <div className="table-responsive">
        <Table columns={columns} dataSource={this.props.data} rowKey={record => record.id} bordered />
      </div>
    );
  }
}

export default TabelaFormandos;