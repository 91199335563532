import {toast} from 'react-toastify';

const INITIAL_STATE = {
  mensagem: "",
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'ERRO_EMAIL':
      toast.error(action.erro.message, 5000);
      return {...state, erro: action.erro};
    case 'ENVIAR_EMAIL':
      return {...state, mensagem: "Enviando email..."};
    case 'ENVIADO_EMAIL':
      // toast.success("Email enviado com sucesso!", 5000);
      return {...state, mensagem: "Email enviado com suceso!"};
    default:
      return state;
  }
};
