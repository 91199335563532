import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import axios from 'axios';
import {toast} from 'react-toastify';
import {Modal} from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Joyride from 'react-joyride';

import {obterItensTipoFTab, editarItem, adicionarItem} from '../actions/ItemActions';
import {obterFormandosPeloContrato, editarCamposFormando} from '../actions/FormandoActions';
import {cadastrarAcao} from '../actions/AcaoActions';
import {adicionarItemFormando, removerItemFormando} from '../actions/ItemFormandoActions';
import {editarContrato, descobrirContrato, editarCamposContrato} from '../actions/ContratoActions';
import EmailService from '../services/EmailService';
import TabelaDistribuicao from '../components/TabelaDistribuicao';
import TelegramService from '../services/TelegramService';
import {REQUEST_URL} from '../config/urls';

class DistribuirBrindes extends React.Component {
  state = {
    newData: [],
    colHeaders: [],
    itens: [],
    formandos: [],
    itemSelecionado: {},
    showModalInformacoes: false,
    showModalNotificacao: false,
    botaoFinalizar: false,
    quantidadeNegativa: false,
    run: false,
    qualDadoCarregar: "brinde",
    tab: ["brinde"],
    steps: [
      {
        content: <div><h3>Bem-vindo(a) a página de distribuição dos brindes!</h3> <span style={{textAlign: "center"}}>É aqui que você conseguirá ver os brindes e também fará a distribuição para os formandos.</span></div>,
        placement: 'center',
        target: '#itens-disponiveis'
      },
      {
        content: <div><h3>Tabela de brindes disponíveis</h3> <span style={{textAlign: "center"}}>Aqui você pode visualizar todos os brindes que estão vinculados ao seu contrato.</span></div>,
        placement: 'bottom',
        target: '#tableItems'
      },
      {
        content: <div><h3>Tabela de distribuição dos brindes</h3> <span style={{textAlign: "center"}}>Aqui você definirá quem receberá os brindes.</span></div>,
        placement: 'bottom',
        target: '.tableDistribuicao'
      },
      {
        content: <div><h3>Download da distribuição de itens</h3> <span style={{textAlign: "center"}}>Caso necessário, clique aqui e baixe a tabela de distribuição de itens.</span></div>,
        placement: 'bottom',
        target: '#btnBaixarTabelaDistribuicao'
      },
      {
        content: <div><h3>Finalização da etapa</h3> <span style={{textAlign: "center"}}>Após o preenchimento da tabela de distribuição de produtos, finalize a etapa clicando neste botão!</span></div>,
        target: '#botaoFinalizarDistribuicao'
      }
    ]
  }

  async componentWillMount () {
    await this.props.descobrirContrato(this.props.usuario.tipo, this.usuarioEhFormando() ? this.props.numeroContrato : this.props.usuario.id).then(async () => {
      await this.carregarDados();
    });
  }

  async carregarDados () { 
    let newData = [],
      formandos = [];
   
    await this.props.obterItensTipoFTab(this.props.contrato.id, this.state.tab);
    await this.props.obterFormandosPeloContrato(this.props.contrato.id);
    
    formandos = this.props.formandos;
    this.executarJoyride();
        
    formandos.map(formando => {
      let auxFormando = {id: formando.id, nome: formando.nome, itens: []};
      this.props.itens.forEach((item, i) => {
        let auxItem = {id: 0, idProduto: item.id, produto: item.produto, quantidade: 0, preco: item.preco, valorAdendo: item.valorAdendo};

        if (formando.itens.length === 0) {
          auxItem.id = auxItem.id === 0 ? auxItem.id - i : auxItem.id;
        } else {
          formando.itens.forEach(itemFormando => {
            if (itemFormando.item === item.id && itemFormando.tipo === this.state.qualDadoCarregar) {
              auxItem.id = itemFormando.id; 
              auxItem.quantidade = itemFormando.quantidade;
            } else {
              auxItem.id = auxItem.id === 0 ? auxItem.id - i : auxItem.id; 
            }
          });
        }

        auxFormando.itens.push(auxItem);
      });
      newData.push(auxFormando);
    });

    this.setState({ newData, itens: this.props.itens, formandos }, () => {
      this.atualizarColHeaders();
    });
  }

  executarJoyride () {
    if (localStorage.getItem("tutorialDistribuicaoBrindes") === null) {
      this.setState({run: true});
      localStorage.setItem("tutorialDistribuicaoBrindes", true);
    }
  }

  atualizarColHeaders () {
    let index = 0,
      newData = this.state.newData,
      quantidadeNegativa = false,
      colHeaders = this.props.itens.map((item) => {
        let texto = `Item ${++index}`,
          quantidadeDisponivel = Number(item.quantidade);

        newData.forEach((formando) => {
          formando.itens.forEach((itemFormando) => {
            if (itemFormando.idProduto === item.id) {
              quantidadeDisponivel -= itemFormando.quantidade;
            }
          });
        });

        if (quantidadeDisponivel < 0) {
          quantidadeNegativa = true;
        }

        return { descricao: texto, qtd: quantidadeDisponivel, produto: item.produto };
      });

    if (quantidadeNegativa) {
      toast.error("Existem mais produtos distribuídos que contratados. Por favor, confirme a distribuição!");
    }

    this.setState({ colHeaders, quantidadeNegativa });
  }

  async atualizarDadosTabela (response) {
    switch (response.funcao) {
      case "remover":
        await this.props.removerItemFormando(response.item.id).then(async () => {
          await this.props.obterItensTipoFTab(this.props.contrato.id, this.state.tab).then(async () => {
            await this.props.obterFormandosPeloContrato(this.props.contrato.id).then(async () => {
              await this.carregarDados();
            });
          });
        });
        break;
      case "adicionar": 
        await this.props.adicionarItemFormando(response.item).then(async () => {
          await this.props.obterItensTipoFTab(this.props.contrato.id, this.state.tab).then(async () => {
            await this.props.obterFormandosPeloContrato(this.props.contrato.id).then(async () => {
              await this.carregarDados();
            });
          });
        });
        break;
      case "atualizar": 
        await this.props.obterItensTipoFTab(this.props.contrato.id, this.state.tab).then(async () => {
          await this.props.obterFormandosPeloContrato(this.props.contrato.id).then(async () => {
            await this.carregarDados();
          });
        });
        break;
    }
  }

  getQuantidadeItensDistribuidos (itemId) {
    let soma = 0;
    this.props.formandos.map(formando => {
      formando.itens.map(itemFormando => {
        if (itemFormando.item === itemId) {
          soma += itemFormando.quantidade;
        }
      });
    });
    return soma;
  }

  async finalizarDistribuicao () {
    let distribuiuTodos = true,
      { itens } = this.state;

    this.setState({botaoFinalizar: true});

    console.log(itens);

    itens.forEach(item => {
      if (item.observacao === "brinde") {
        let quantidade = (item.quantidade + item.adendo) - this.getQuantidadeItensDistribuidos(item.id);
        if (quantidade > 0) {
          distribuiuTodos = "Ainda existem produtos que não foram distribuídos!";
          return 0;
        } else if (quantidade < 0) {
          distribuiuTodos = "Existem produtos distribuídos que não foram contratados. Confirme as quantidades!";
          return 0;
        }
      }
    });

    if (distribuiuTodos !== true) {
      toast.info(distribuiuTodos);
      this.setState({botaoFinalizar: false});
    } else {
      let email = new EmailService(),
        acao = { ator: this.props.contrato.dono.id, texto: "Comissão distribuiu os itens brindes para o contrato " + this.props.contrato.numero, data: new Date(), contrato: this.props.contrato.id, tipo: "comissao", visualizado: false};
      email.enviar("updateAcaoComissaoToDesigner", {numero: this.props.contrato.numero, mensagem: "a distribuição de itens brinde", designer: {email: this.props.contrato.dono.email}});
      this.props.editarCamposContrato(this.props.contrato.id, {distribuiuBrindes: 1});
      this.props.cadastrarAcao(acao);
      this.setState({botaoFinalizar: false, showModalNotificacao: true});
    }
  }

  baixarTabela () {
    let btn = document.querySelector("#btnBaixarTabela");

    if (btn) {
      btn.click();
    }
  }

  usuarioEhFormando () {
    return this.props.usuario.tipo === "formando";
  }

  formatarMoedaBR (valor) {
    if (valor) {
      valor = valor.toFixed(2).split('.');
      valor[0] = valor[0].split(/(?=(?:...)*$)/).join('.');
      return valor.join(',');
    } 
    return "0,00";
  }

  _renderAlerta () {
    return (
      this.props.contrato.distribuiuBrindes === 1 ?
        <div className="alert alert-success" style={{fontWeight: "bold", fontSize: 14}}>
          <i className="fa fa-check"></i>
          {" "} Etapa concluída!
        </div>
      : null
    );
  }

  _renderTabelaProdutos () {
    let { itens } = this.state;
    return (
      <div className="row">
        <div className="col-md-8 col-lg-4">
          <div className="box box-solid">
            <div className="box-header" style={{paddingBottom: 0}}>
              <div className="box-title">Produtos contratados</div>
            </div>
            <div className="box-body">
              <div className="table-responsive">
                <table id="tableItems" className="table" style={{marginBottom: 0}}>
                  <thead style={{backgroundColor: "#222D32", color: "white"}}>
                    <tr>
                      <th>Produto</th>
                      <th>Qtd. contratada</th>                       
                    </tr>
                  </thead>
                  <tbody style={{backgroundColor: "white"}}>
                    {
                      itens.length > 0 ?
                        itens.map((item, i) => (
                          <tr key={i} style={{borderBottom: "1px solid #eee"}}>
                            <td>{item.produto}&nbsp;&nbsp;&nbsp;{item.ids !== null && item.ids.split(";").length > 2 ? <span className="label label-warning" style={{cursor: "pointer"}} onClick={() => {this.setState({itemSelecionado: item, showModalInformacoes: true})}} title="Clique para ver o que tem dentro deste kit">ver composição</span> : null}</td>
                            <td>{item.quantidade}</td>
                          </tr>
                        ))
                      :
                      <tr style={{borderBottom: "1px solid #eee"}}>
                        <td colSpan={4} style={{textAlign: "center"}}>O seu contrato não possui itens deste tipo para serem distribuídos!</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderTabelaDistribuicao () {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="box box-solid">
            <ReactHTMLTableToExcel className="none" table="tabelaDistribuicaoItens" filename="Planilha de itens distribuidos" sheet="tablexls" id="btnBaixarTabela" buttonText="download" />
            <div className="box-header" style={{paddingBottom: 0}}>
              <div className="box-title" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                Tabela de distribuição
                <a className="btnSemFundo" id="btnBaixarTabelaDistribuicao" onClick={this.baixarTabela.bind(this)} style={{fontSize: 14}}><i className="fa fa-download"></i> baixar</a>
              </div>
            </div>
            <div className="box-body">
              {
                this.props.formandos.length > 0 ?
                  <div className="tableDistribuicao" style={{display: "flex", flexDirection: "column"}}>
                    <TabelaDistribuicao
                      block={this.props.contrato.distribuiuBrindes !== 0} 
                      formatarMoedaBR={this.formatarMoedaBR}
                      colHeaders={this.state.colHeaders}
                      newData={this.state.newData}
                      qualDadoCarregar={this.state.qualDadoCarregar}
                      atualizarTabela={(response) => this.atualizarDadosTabela(response)}
                    />
                    {
                      this.props.contrato.distribuiuBrindes === 0 ? 
                        <button className="btn btn-danger btn-lg btn-flat botaoFinalizar" id="botaoFinalizarDistribuicao" data-toggle="modal" data-target="#modal-danger" style={{marginTop: 15}} disabled={this.state.botaoFinalizar}>
                          {this.state.botaoFinalizar === true ? <span><i className="fa fa-spinner fa-pulse fa-fw"></i> Finalizando etapa</span> : "Finalizar etapa"}
                        </button>
                      : null
                    }
                  </div>
                : <h6 className="tableDistribuicao"><strong>Será possível distribuir os itens quando existir, ao menos, 1 formando cadastrado.</strong></h6>
              }
            </div>
          </div>
        </div>
      </div> 
    );
  }

  render () {
    if (this.usuarioEhFormando() || this.props.contrato.distribuiuBrindes === null) {
      return <Redirect to="./"/>;
    }
    return (
      <div id="itens-disponiveis">
        { this._renderAlerta() }
        { this._renderTabelaProdutos() }
        { this.state.itens.length > 0 ? this._renderTabelaDistribuicao() : null }
        <div className="modal fade" id="modal-danger">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span></button>
                <h4 className="modal-title">Tem certeza que deseja finalizar essa etapa?</h4>
              </div>
              <div className="modal-body">
                <p>Ao clicar no botão confirmar você comprova que todos os brindes foram distribuidos corretamente.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default pull-left flat" data-dismiss="modal">Cancelar</button>
                <button type="button" className="btn btn-danger flat" data-dismiss="modal" onClick={this.finalizarDistribuicao.bind(this)}>{this.state.botaoFinalizar ? <span><i className="fas fa-spinner fa-pulse"></i></span> : null }Confirmar</button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModalNotificacao} onHide={()=>{this.setState({showModalNotificacao: false})}} dialogClassName="modal-80w">
          <Modal.Body>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
              <img src="./images/success.png" alt="Sucesso" height="75"/>
              <h2 style={{textAlign: "center"}}>Distribuição de brindes finalizada!</h2>
              <h4 style={{textAlign: "center"}}>A próxima etapa agora é o cadastro das informações para faturamento/entrega.</h4>
              <a href="./entrega" className="btn btn-outline-success btn-lg" style={{marginTop: 17}}>Vamos lá!</a>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showModalInformacoes !== false} onHide={()=>{this.setState({showModalInformacoes: false})}} dialogClassName="modal-50w">
          <Modal.Header closeButton>
            <Modal.Title>Outras informações</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.state.itemSelecionado !== null && this.state.itemSelecionado.itens !== undefined ?
                <div>
                  <h4>Composição</h4>
                  <ul style={{marginBottom: 30}}>
                    {
                      this.state.itemSelecionado.itens.split(";").map((item, i) => (
                        item !== "" ?
                          <li key={i}>{item}</li>
                          : null
                      ))
                    }
                  </ul>
                </div>
                : null
            }
            {
              this.state.itemSelecionado !== null && this.state.itemSelecionado.descricao !== undefined ?
                <div>
                  <h4>Produtos utilizados</h4>
                  <ul>
                    {
                      this.state.itemSelecionado.descricao.split(" / ").map((descricao, i) => (
                        descricao !== "" ?
                          descricao.split(",").map((desc, j) => (
                            <li key={"desc" + i + j}>{desc}</li>
                          ))
                          : null
                      ))
                    }
                  </ul>
                </div>
                : null
            }
          </Modal.Body>
        </Modal>
        <Joyride continuous={true} run={this.state.run} scrollToFirstStep={true} showProgress={true} showSkipButton={true} steps={this.state.steps} styles={{options: {zIndex: 10000}}} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    itens: state.item.todosDistribuiveis,
    formandos: state.formando.todos,
    contrato: state.contrato.especifico,
    usuario: state.usuario.autenticado,
    numeroContrato: state.usuario.numeroContrato
  }
}

export default connect(mapStateToProps, {
  editarCamposContrato,
  editarCamposFormando,
  adicionarItem,
  editarItem,
  cadastrarAcao,
  removerItemFormando,
  adicionarItemFormando,
  obterFormandosPeloContrato,
  obterItensTipoFTab,
  editarContrato,
  descobrirContrato
})(DistribuirBrindes)
