import React from 'react';
import { BrowserRouter as ReactRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import axios from 'axios';
import PrivateRoute from '../components/PrivateRoute';
import { setUsuario } from '../actions/UsuarioActions';
import { ToastContainer } from 'react-toastify';

import Login from '../views/Login';
import Contrato from '../views/Contrato';
import NotFound from '../views/NotFound';
import Formandos from '../views/Formandos';
import DistribuirBrindes from '../views/DistribuirBrindes';
import DistribuirItensFormando from '../views/DistribuirItensFormando';
import Instagram from '../views/Instagram';
import InformacoesEntrega from '../views/InformacoesEntrega';
import Publicacoes from '../views/Publicacoes';
import Publicacao from '../views/Publicacao';
import Deletado from '../views/Deletado';
import AprovacaoConvite from '../views/AprovacaoConvite';
import HistoricoPublicacao from '../views/HistoricoPublicacao';
import TermoAceite from '../views/TermoAceite';
import OrientacoesFotograficas from '../views/OrientacoesFotograficas';
import MeusDados from '../views/MeusDados';
import MeusPedidos from '../views/MeusPedidos';
import DistribuirItensContratados from "../views/DistribuirItensContratados";
import PedidosComissao from "../views/PedidosComissao";

class Router extends React.Component {
  componentWillMount(){
    let usuario = JSON.parse(sessionStorage.getItem('usuarioLogadoComissao')),
      token = sessionStorage.getItem('token'),
      numeroContrato = sessionStorage.getItem('dsakjh');
    if (usuario) {
      this.props.setUsuario(usuario, numeroContrato);
    }
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    }
  }

  render(){
    return (
      <ReactRouter>
        <div>
          <ToastContainer />
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Contrato} title="Contrato" />
            <PrivateRoute exact path="/formandos" component={Formandos} title="Formandos" />
            <PrivateRoute exact path="/distribuir-brindes" component={DistribuirBrindes} title="Distribuição de Brindes" />
            <PrivateRoute exact path="/distribuir-itens" component={DistribuirItensContratados} title="Distribuição de Itens" contrato={this.props.contrato} />
            <PrivateRoute exact path="/comprar" component={DistribuirItensFormando} title="Distribuição de Itens" />
            <PrivateRoute exact path="/meus-dados" component={MeusDados} title="Meus dados" />
            <PrivateRoute exact path="/meus-pedidos" component={MeusPedidos} title="Meus pedidos" />
            <PrivateRoute exact path="/pedidos-comissao" component={PedidosComissao} title="Pedidos da comissão" />
            <PrivateRoute exact path="/instagram" component={Instagram} title="Instagram" />
            <PrivateRoute exact path="/entrega" component={InformacoesEntrega} title="Entrega" />
            <PrivateRoute exact path="/publicacoes" component={Publicacoes} title="Publicações"/>
            <PrivateRoute exact path="/publicacoes/:tipo" component={Publicacao} title="Publicação"/>
            <PrivateRoute exact path="/publicacoes/historico/:id" component={HistoricoPublicacao} title="Publicação" />
            <PrivateRoute exact path="/aprovacao-convite" component={AprovacaoConvite} title="Aprovação do Convite"/>
            <PrivateRoute exact path="/orientacoes-fotograficas" component={OrientacoesFotograficas} title="Orientações Fotográficas"/>
            <Route exact path="/termo-aceite" component={TermoAceite} title="Termo de Aceite"/>
            <Route path="/deletado" component={Deletado} title="Contrato Deletado"/>
            <PrivateRoute component={NotFound} title="Página não encontrada" />
          </Switch>
        </div>
      </ReactRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    carregando: state.usuario.carregando,
    contrato: state.contrato.especifico,
    erro: state.usuario.erro,
    caminho: state.caminho.atual
  };
}

export default connect(mapStateToProps, {setUsuario})(Router);
