import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_PUBLICACAO"
  };
}

const erro = (erro) => {
    return {
      type: "ERRO_PUBLICACAO",
      erro
    }
}

const obterEspecifico = (publicacao) =>{
  return {
    type: "OBTER_PUBLICACAO",
    publicacao
  }
}

export const obterPublicacao = (publicacao) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/publicacoes/findOne?filter={"where":{"id":${publicacao}}, "include": [{"paginas": ["comentarios"]}, "pesquisas"]}`).then((response) => {
      dispatch(obterEspecifico(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const obterPublicacoesPeloTipo = (tipo, contrato) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/publicacoes?filter={"order": "dataPublicacao DESC", "where": {"and": [{"contrato": ${contrato}}, {"tipo": "${tipo}"}]}, "include": [{"paginas": ["comentarios"]}, "pesquisas"]}`).then((response) => {
      dispatch({
        type: "OBTER_PUBLICACOES",
        publicacoes: response.data
      });
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}

export const editarPublicacao = (id, publicacao) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/publicacoes/${id}`, publicacao).then(response => {
      dispatch({
        type: "EDITAR_PUBLICACOES",
        publicacao: response.data
      })
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    })
  }
}

export const obterUltimaPublicacaoPeloContrato = (contrato) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/publicacoes/findOne?filter={"where": {"contrato": ${contrato}}, "order": "dataPublicacao DESC", "include": ["pesquisas"]}`).then(response => {
      dispatch({
        type: "OBTER_PUBLICACAO",
        publicacao: response.data
      });
    }).catch(error => {
      dispatch(error(error.response.data.error));
    })
  }
}
