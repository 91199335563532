import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todos: [],
  carregando: false,
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_ARROBAS':
      return {...state, carregando: true};
    case 'ERRO_ARROBA':
      toast.error(action.erro.message, 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_TODOS_ARROBAS':
      return {...state, carregando: false, todos: action.arrobas};
    case 'NOVO_ARROBA':
      toast.success("Instagram cadastrado com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.concat(action.arroba)};
    case 'ALTERAR_ARROBA':
      toast.success("Instagram alterado com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((arroba) => {return arroba.id !== action.arroba.id}).concat(action.arroba)};
    case 'REMOVER_ARROBA':
      toast.success("Instagram removido com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((arroba) => {return arroba.id !== action.id})};
    default:
      return state;
  }
};
