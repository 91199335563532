const INITIAL_STATE = {
  todos: [],
  especifico: null,
  carregando: false,
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_COMENTARIO':
      return {...state, carregando: true};
    case 'ERRO_COMENTARIO':
      return {...state, carregando: false, erro: action.erro};
    case 'NOVO_COMENTARIO':
      return {...state, carregando: false, todos: state.todos.concat(action.comentario), erro: null};
    case 'ALTERAR_COMENTARIO':
      return {...state, carregando: false, todos: state.todos.filter(c => c.id!==action.comentario.id).concat(action.comentario), erro: null};
    case 'REMOVER_COMENTARIO':
      return {...state, carregando: false, todos: state.todos.filter(c => c.id!==action.comentario.id), erro: null};
    default:
      return state;
  }
};
