import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todos: [],
  carregando: false,
  usuarioDoFormando: {},
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_FORMANDO':
      return {...state, carregando: true};
    case 'ERRO_FORMANDO':
      toast.error("Erro ao executar esta ação", 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_TODOS_FORMANDOS':
      return {...state, carregando: false, todos: action.formandos};
    case 'OBTER_PELO_CONTRATO':
      return {...state, carregando: false, todos: action.formandos};
    case 'NOVO_FORMANDO':
      let formando = action.formando;
      formando.itens = [];
      return {...state, carregando: false, todos: state.todos.concat(formando)};
    case 'ALTERAR_FORMANDO':
      return {...state, carregando: false, todos: state.todos.filter((formando) => {return formando.id!==action.formando.id}).concat(action.formando)};
    case 'REMOVER_FORMANDO':
      toast.success("Formando removido com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((formando) => {return formando.id!==action.id})};
    default:
      return state;
  }
};
