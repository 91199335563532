import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
import InputMask from 'react-input-mask';
import readXlsxFile from 'read-excel-file';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Joyride from 'react-joyride';
import Select from 'react-select';
import validator from 'validator';

import {
  editarCamposContrato,
  obterContratoPeloUsuario,
} from '../actions/ContratoActions';
import { consultarTurmas } from '../actions/TurmaActions';
import { cadastrarAcao } from '../actions/AcaoActions';
import ConfirmationModal from '../components/ConfirmationModal';
import {
  deletarFormando,
  obterFormandosPeloContrato,
  adicionarFormando,
  editarFormando,
  editarFormandoEUsuario,
  adicionarFormandoECriarUsuario,
} from '../actions/FormandoActions';
import EmailService from '../services/EmailService';
import TabelaFormandos from '../components/TabelaFormandos';
import { REQUEST_URL } from '../config/urls';

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? '#3c8dbc' : '#ccc',
    borderRadius: 0,
    '&:hover': {
      borderColor: state.isFocused ? '#ccc' : '#ccc',
    },
  }),
};

class Formandos extends React.Component {
  state = {
    contratos: [],
    iniciar: true,
    inputFile: null,
    formandoSelecionado: null,
    showModalFormando: false,
    showModalNotificacao: false,
    showModalCursoNaoCadastrado: false,
    operacao: 'adicionar',
    botaoFinalizar: false,
    formando: {
      nome: '',
      email: '',
      celular: '',
      contrato: 0,
      turma: null,
      membroComissao: false,
      usuario: 0,
    },
    formandos: [],
    cursoSelecionadoPlanilha: [],
    botaoCadastrarFormandosPorPlanilha: false,
    turmasSelect: [],
    run: false,
    steps: [
      {
        content: (
          <div>
            <h3>Bem-vindo(a) a página de cadastro dos formandos!</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              É aqui que você conseguirá gerenciar as informações dos formandos.
            </span>
          </div>
        ),
        placement: 'center',
        target: '#divContent',
      },
      {
        content: (
          <div>
            <h3>Cadastrar formandos pela planilha</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Você pode adicionar vários formandos de uma vez clicando aqui.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '#formandoPlanilha',
      },
      {
        content: (
          <div>
            <h3>Cadastrar formandos manualmente</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Você também pode adicioná-los de forma manual clicando aqui.
            </span>
          </div>
        ),
        placement: 'bottom',
        target: '#formandoManual',
      },
      {
        content: (
          <div>
            <h3>Finalização da etapa</h3>{' '}
            <span style={{ textAlign: 'center' }}>
              Após adição de todos os formandos, finalize a etapa clicando neste
              botão!
            </span>
          </div>
        ),
        target: '#btnFinalizarEtapa',
      },
    ],
  };

  async componentWillMount() {
    if (this.props.usuario_a.tipo === 'comissao') {
      await this.iniciar();
    }
  }

  async iniciar() {
    await this.props
      .obterContratoPeloUsuario(this.props.usuario_a.id)
      .then(() => {
        if (this.props.contrato) {
          let turmas = [],
            formandos = [];
          if (
            this.props.contrato.turmas &&
            this.props.contrato.turmas.length > 0
          ) {
            if (this.props.contrato.cadastrouFormandos === 0) {
              this.setState({ run: true });
            }
            this.props.contrato.turmas.forEach((turma) => {
              turmas.push({ value: turma.id, label: turma.curso });
              turma.formandos.forEach((formando) => {
                formandos.push({
                  id: formando.id,
                  nome: formando.nome,
                  email: formando.email,
                  celular: formando.celular,
                  turma: turma.id,
                  curso: turma.curso,
                  usuario: formando.usuario,
                  membroComissao: formando.membroComissao,
                });
              });
            });
            formandos.sort((a, b) => {
              return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1;
            });
            this.setState({ turmasSelect: turmas, formandos });
          } else {
            this.setState({ showModalCursoNaoCadastrado: true });
          }
        }
        this.setState({ iniciar: false });
      });
  }

  finalizar() {
    let email = new EmailService(),
      data = { cadastrouFormandos: 1 };
    this.setState({ botaoFinalizar: true });
    this.props.editarCamposContrato(this.props.contrato.id, data);
    //cadastrar notificação para admistração
    let acao = {
      ator: this.props.contrato.dono.id,
      texto: `Comissão confirmou o cadastro de formandos para o contrato ${this.props.contrato.numero}`,
      data: new Date(),
      contrato: this.props.contrato.id,
      tipo: 'comissao',
      visualizado: false,
    };
    email.enviar('updateAcaoComissaoToDesigner', {
      numero: this.props.contrato.numero,
      mensagem: 'o cadastro de formandos',
      designer: { email: this.props.contrato.dono.email },
    });
    this.props.cadastrarAcao(acao);
    this.setState({ botaoFinalizar: false, showModalNotificacao: true });
  }

  async salvar(event) {
    event.preventDefault();
    let formando = this.state.formando;

    formando.turma = formando.turma.value;
    formando.celular = Number(
      String(formando.celular).replace(/[()-.+\s]/g, '')
    );

    if (formando.turma) {
      if (validator.isEmail(formando.email)) {
        if (this.state.operacao === 'adicionar') {
          formando.contrato = this.props.contrato.id;
          formando.continuarDepois = false;
          if (
            !this.props.contrato.contratante.includes('Alpha-e') &&
            this.props.contrato.sistemaPagto === 1
          ) {
            this.props.adicionarFormandoECriarUsuario(formando).then(() => {
              toast.success('Formando cadastrado com sucesso!');
              let acao = {
                ator: this.props.contrato.dono.id,
                texto: `O formando ${formando.nome} foi adicionando no contrato ${this.props.contrato.numero} e o usuário foi gerado automaticamente.`,
                data: new Date(),
                contrato: this.props.contrato.id,
                tipo: 'atrasado',
                visualizado: false,
              };
              this.props.cadastrarAcao(acao);
              this.resetar();
              this.iniciar();
            });
          } else {
            delete formando.usuario;
            this.props.adicionarFormando(formando).then(() => {
              toast.success('Formando cadastrado com sucesso!');
              let acao = {
                ator: this.props.contrato.dono.id,
                texto: `O formando ${formando.nome} foi adicionando no contrato ${this.props.contrato.numero}.`,
                data: new Date(),
                contrato: this.props.contrato.id,
                tipo: 'atrasado',
                visualizado: false,
              };
              this.props.cadastrarAcao(acao);
              this.resetar();
              this.iniciar();
            });
          }
        } else {
          if (formando.usuario) {
            await this.props.editarFormandoEUsuario(formando);
          } else {
            delete formando.usuario;
            await this.props.editarFormando(formando);
          }
          await this.resetar();
          await this.iniciar();
          toast.success('Formando alterado com sucesso!');
        }
      } else {
        toast.info(`Informe um e-mail válido!`);
      }
    } else {
      toast.info(`Selecione um curso!`);
    }
  }

  async resetar() {
    this.setState({
      showModalFormando: false,
      operacao: 'adicionar',
      formandoSelecionado: null,
      formando: { nome: '', email: '', celular: '', turma: {}, usuario: 0 },
    });
  }

  async deletar() {
    await this.props.deletarFormando(this.state.formandoSelecionado);
    await this.resetar();
    await this.iniciar();
  }

  handleClickEditar = (formando) => {
    formando = {
      id: formando.id,
      nome: formando.nome,
      email: formando.email,
      celular: formando.celular,
      contrato: this.props.contrato.id,
      usuario: formando.usuario,
      membroComissao: formando.membroComissao,
      turma: {
        value: formando.turma,
        label: formando.curso,
      },
    };
    this.setState({ operacao: 'editar', formando, showModalFormando: true });
  };

  handleClickDelete = (id) => {
    this.setState({ formandoSelecionado: id });
    this.refs.modal.open();
  };

  async carregarListaFormandos() {
    let input = document.querySelector('#upload'),
      erroAoCadastrarFormandos = '';
    if (input.files[0]) {
      if (this.state.cursoSelecionadoPlanilha.value) {
        this.setState({ botaoCadastrarFormandosPorPlanilha: true });
        await readXlsxFile(input.files[0]).then(async (cols) => {
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i];
            if (i !== 0) {
              if (col[0] && col[1] && col[2] && col[3]) {
                let celularFormando = Number(
                    String(col[2]).replace(/[()-.+\s]/g, '')
                  ),
                  formando = {
                    nome: col[0],
                    email: col[1],
                    celular: celularFormando,
                    turma: this.state.cursoSelecionadoPlanilha.value,
                    membroComissao:
                      col[3].includes('s') || col[3].includes('S') ? 1 : 0,
                    contrato: this.props.contrato.id,
                    continuarDepois: true,
                  };
                if (validator.isEmail(col[1])) {
                  if (
                    !this.props.contrato.contratante.includes('Alpha-e') &&
                    this.props.contrato.sistemaPagto === 1
                  ) {
                    this.props
                      .adicionarFormandoECriarUsuario(formando)
                      .then(() => {
                        this.iniciar();
                      })
                      .catch(() => {
                        toast.error(
                          `Não foi possível adicionar o formando ${col[1]}`
                        );
                        erroAoCadastrarFormandos = true;
                      });
                  } else {
                    this.props
                      .adicionarFormando(formando)
                      .then(() => {
                        this.iniciar();
                      })
                      .catch(() => {
                        toast.error(
                          `Não foi possível adicionar o formando ${col[1]}`
                        );
                        erroAoCadastrarFormandos = true;
                      });
                  }
                } else {
                  toast.error(
                    `Erro ao cadastrar o formando ${col[0]}: o e-mail informado não existe!`
                  );
                  erroAoCadastrarFormandos = true;
                }
              } else {
                toast.error(
                  `Erro ao cadastrar o formando ${col[0]}: todos os campos precisam ser preenchidos!`
                );
                erroAoCadastrarFormandos = true;
              }
            }
          }
        });
        if (erroAoCadastrarFormandos) {
          toast.info('Alguns formandos não puderam ser cadastrados');
        } else {
          toast.success('Formandos cadastrados com sucesso!');
        }
        input.value = '';
        document.querySelector('#fakeInput').value = '';
        this.setState({ botaoCadastrarFormandosPorPlanilha: false });
      } else {
        toast.error('Selecione o curso que os formandos pertencem!');
      }
    } else {
      toast.error(
        'Insira a planilha preenchida com as informações dos formandos!'
      );
    }
  }

  handleChangeCurso(turmaSelecionada) {
    this.setState({
      formando: { ...this.state.formando, turma: turmaSelecionada },
    });
  }

  handleChangeMembroComissao() {
    this.setState({
      formando: {
        ...this.state.formando,
        membroComissao: !this.state.formando.membroComissao,
      },
    });
  }

  handleChangeCursoSelecionadoPlanilha(cursoSelecionadoPlanilha) {
    this.setState({ cursoSelecionadoPlanilha });
  }

  handleChangeFormando = (e) => {
    const { name, value } = e.target;

    this.setState({ formando: { ...this.state.formando, [name]: value } });
  };

  handleClickReenviarEmail = async (formando) => {
    await axios
      .get(`${REQUEST_URL}/formandos/${formando.usuario}/pegar-usuario`)
      .then((response) => {
        new EmailService().enviar('formando', {
          usuario: { ...response.data[0] },
        });
        toast.success('E-mail enviado com sucesso!');
      })
      .catch((err) => {
        toast.error('Este usuário não possui acesso ao sistema!');
      });
  };

  uploadSpreadsheet() {
    var file = document.querySelector('#upload');
    file.click();
    file.addEventListener('change', function () {
      file = document.querySelector('#upload');
      document.querySelector('#fakeInput').value = file.value.replace(
        /C:\\fakepath\\/i,
        ''
      );
    });
  }

  _renderCards() {
    let numUsuarios = this.state.formandos.length,
      numUsuariosComAcesso = this.state.formandos.filter((f) => {
        return f.usuario;
      }).length;
    return (
      <div className='row'>
        <div className='col-md-6 col-lg-4'>
          <div className='small-box bg-aqua'>
            <div className='inner' style={{ minHeight: 123 }}>
              <h3 style={{ color: 'white' }}>
                {numUsuariosComAcesso}/{numUsuarios}
              </h3>
              <p style={{ marginBottom: 5 }}>
                Formandos cadastrados com acesso ao sistema
              </p>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-lg-4'>
          <div className='small-box bg-yellow'>
            <div className='inner' style={{ minHeight: 123 }}>
              <h3 style={{ color: 'white' }}>
                {this.state.turmasSelect.length}
              </h3>
              <p style={{ marginBottom: 5 }}>
                Turmas vinculadas a este contrato.{' '}
                <a
                  style={{ color: 'white', textDecoration: 'underline' }}
                  onClick={() => this.setState({ showModalTurmas: true })}
                >
                  Clique aqui para visualizar!
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.usuario_a.tipo === 'formando') {
      return <Redirect to='/' />;
    }
    return (
      <div id='divContent'>
        {this.props.contrato.cadastrouFormandos === 1 ? (
          <div
            className='alert alert-success'
            style={{ fontWeight: 'bold', fontSize: 14 }}
          >
            <i className='fa fa-check'></i> Etapa concluída!
          </div>
        ) : (
          <button
            type='submit'
            style={{ zIndex: 2 }}
            className='btn btn-danger btn-lg btn-flat botaoFinalizar'
            id='btnFinalizarEtapa'
            data-toggle='modal'
            data-target='#modal-danger'
            disabled={this.state.botaoFinalizar}
          >
            {this.state.botaoFinalizar ? (
              <span>
                <i className='fa fa-spinner fa-pulse fa-fw'></i> Finalizando
                etapa
              </span>
            ) : (
              'Finalizar etapa'
            )}
          </button>
        )}
        <ConfirmationModal
          ref='modal'
          confirmButtonStyle='btn-danger'
          title='Deletar Formando'
          confirmButton='Deletar usuário'
          body='Tem certeza que deseja deletar este formando?'
          onConfirm={this.deletar.bind(this)}
        />
        {this._renderCards()}
        <div className='box box-solid'>
          {this.props.contrato.cadastrouFormandos === 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                paddingTop: 10,
              }}
            >
              <button
                id='formandoManual'
                className='btn btn-success flat pull-right'
                onClick={() => this.setState({ showModalFormando: true })}
              >
                Adicionar
              </button>
              <button
                id='formandoPlanilha'
                style={{ margin: '0px 10px' }}
                data-toggle='modal'
                data-target='#modal-info'
                className='btn btn-primary flat'
              >
                Adicionar por planilha
              </button>
            </div>
          ) : null}
          <div className='box-body'>
            <TabelaFormandos
              data={this.state.formandos}
              cadastrouFormandos={this.props.contrato.cadastrouFormandos}
              editarFormando={(formando) => this.handleClickEditar(formando)}
              excluirFormando={(id) => this.handleClickDelete(id)}
              reenviarEmail={(formando) =>
                this.handleClickReenviarEmail(formando)
              }
            />
          </div>
        </div>
        <Modal
          show={this.state.showModalFormando}
          onHide={this.resetar.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Formando</Modal.Title>
          </Modal.Header>
          <form onSubmit={this.salvar.bind(this)}>
            <Modal.Body>
              <div className='form-group'>
                <label htmlFor='nome'>
                  <span className='perguntaObrigatoria'>*</span>Nome completo
                </label>
                <input
                  autoFocus
                  ref='nome'
                  required
                  type='text'
                  value={this.state.formando.nome}
                  className='form-control'
                  name='nome'
                  onChange={(e) => this.handleChangeFormando(e)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='email'>
                  <span className='perguntaObrigatoria'>*</span>E-mail
                </label>
                <input
                  required
                  type='email'
                  value={this.state.formando.email}
                  className='form-control'
                  name='email'
                  onChange={(e) => this.handleChangeFormando(e)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='senha'>
                  <span className='perguntaObrigatoria'>*</span>Celular
                </label>
                <InputMask
                  mask='(99) 99999-9999'
                  required
                  type='text'
                  value={this.state.formando.celular}
                  className='form-control'
                  name='celular'
                  onChange={(e) => this.handleChangeFormando(e)}
                />
              </div>
              <div className='form-group'>
                {this.state.turmasSelect.length > 0 ? (
                  <div>
                    <label htmlFor='turma'>
                      <span className='perguntaObrigatoria'>*</span>Curso
                    </label>
                    <Select
                      required
                      placeholder='Selecione a turma'
                      name='turma'
                      value={this.state.formando.turma}
                      onChange={this.handleChangeCurso.bind(this)}
                      options={this.state.turmasSelect}
                      styles={customStyles}
                    />
                  </div>
                ) : null}
              </div>
              <div className='switch'>
                <input
                  type='checkbox'
                  defaultChecked={this.state.formando.membroComissao}
                  onClick={this.handleChangeMembroComissao.bind(this)}
                  id='switch2'
                  className='switch__input'
                ></input>
                <label
                  htmlFor='switch2'
                  className='switch__label'
                  style={{ fontWeight: 400 }}
                >
                  {' '}
                  Membro da comissão
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                name='adicionar'
                type='submit'
                className='btn btn-primary flat'
              >
                Salvar
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <div className='modal fade' id='modal-danger'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>
                  Tem certeza que deseja finalizar essa etapa?
                </h4>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>
                  Confirme se todas as pessoas que personalizarão o convite
                  (formandos, homenageados e professores) foram cadastradas e
                  possuem e-mails válidos antes de finalizar essa etapa!
                </p>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-default pull-left flat'
                  data-dismiss='modal'
                >
                  Cancelar
                </button>
                <button
                  type='button'
                  className='btn btn-danger flat'
                  data-dismiss='modal'
                  onClick={this.finalizar.bind(this)}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal fade' id='modal-info'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>×</span>
                </button>
                <h4 className='modal-title'>
                  Cadastro de formandos pela planilha
                </h4>
              </div>
              <div className='modal-body'>
                <div className='alert alert-warning'>
                  <h4>ATENÇÃO</h4>
                  <p>
                    Baixe a planilha modelo (
                    <a
                      href='planilha-modelo.xlsx'
                      download='planilha-modelo.xlsx'
                    >
                      clicando aqui
                    </a>
                    ) e atente-se aos pontos abaixo:
                  </p>
                  1. Todos os campos são obrigatórios.
                  <br />
                  2. Cada formando deve ficar em uma linha.
                </div>
                <div className='form-group'>
                  <input
                    type='file'
                    className='file'
                    id='upload'
                    name='spreadsheet'
                    style={{ display: 'none' }}
                  />
                  <label>Arquivo</label>
                  <div className='input-group'>
                    <input
                      type='text'
                      id='fakeInput'
                      className='form-control bcole'
                      disabled
                      placeholder='Clique para adicionar'
                    />
                    <span className='input-group-btn'>
                      <button
                        className='browse btn btn-info'
                        type='button'
                        onClick={this.uploadSpreadsheet.bind(this)}
                      >
                        <i className='fa fa-search' aria-hidden='true'></i>{' '}
                        Pesquisar
                      </button>
                    </span>
                  </div>
                  <a
                    href='planilha-modelo.xlsx'
                    download='planilha-modelo.xlsx'
                  >
                    Baixar planilha modelo
                  </a>
                </div>
                <div className='form-group'>
                  <label>Curso</label>
                  <Select
                    required
                    placeholder='Selecione a turma'
                    name='turma'
                    value={this.state.cursoSelecionadoPlanilha}
                    onChange={this.handleChangeCursoSelecionadoPlanilha.bind(
                      this
                    )}
                    options={this.state.turmasSelect}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className='modal-footer' style={{ padding: 10 }}>
                <button
                  className='btn btn-success'
                  disabled={this.state.botaoCadastrarFormandosPorPlanilha}
                  onClick={this.carregarListaFormandos.bind(this)}
                >
                  {this.state.botaoCadastrarFormandosPorPlanilha ? (
                    <span>
                      <i className='fa fa-spinner fa-pulse fa-fw' /> Adicionando
                    </span>
                  ) : (
                    'Adicionar formandos'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showModalNotificacao !== false}
          onHide={() => {
            this.setState({ showModalNotificacao: false });
          }}
          dialogClassName='modal-80w'
        >
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src='./images/success.png' alt='Sucesso' height='75' />
              <h2 style={{ textAlign: 'center' }}>
                Cadastro de formandos finalizado!
              </h2>
              {/* <h4 style={{textAlign: "center"}}>A próxima etapa agora é a distribuição de itens.</h4> */}
              {/* <a href="./itens" className="btn btn-outline-success btn-lg" style={{marginTop: 17}}>Vamos lá!</a> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalCursoNaoCadastrado}
          dialogClassName='modal-80w'
        >
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src='./images/info.png' alt='Sucesso' height='75' />
              <h2 style={{ textAlign: 'center' }}>
                Não conseguimos encontrar o seu curso
              </h2>
              <h4 style={{ textAlign: 'center' }}>
                Para cadastrar os formandos, é necessário que o seu curso esteja
                cadastrado no sistema. Por favor, solicite ao seu atendente que
                faça o cadastro.
              </h4>
              <a
                href='./'
                className='btn btn-outline-info btn-lg'
                style={{ marginTop: 17 }}
              >
                Voltar
              </a>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalTurmas}
          onHide={() => this.setState({ showModalTurmas: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Turmas cadastradas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.state.turmasSelect.map((curso, i) => (
                <div
                  key={i}
                  className='label label-default'
                  style={{
                    fontSize: 14,
                    margin: '0px 5px 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                  title={`${
                    this.state.formandos.filter((formando) => {
                      return formando.curso === curso.label;
                    }).length
                  } formandos vinculados a esta turma`}
                >
                  {curso.label}
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <Joyride
          continuous={true}
          run={this.state.run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={this.state.steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario_a: state.usuario.autenticado,
    contrato: state.contrato.especifico,
    formandos: state.formando.todos,
    carregando: state.formando.carregando,
    turmas: state.turmas.turmas,
  };
};

export default connect(mapStateToProps, {
  cadastrarAcao,
  adicionarFormandoECriarUsuario,
  consultarTurmas,
  editarCamposContrato,
  deletarFormando,
  adicionarFormando,
  editarFormando,
  editarFormandoEUsuario,
  obterFormandosPeloContrato,
  obterContratoPeloUsuario,
})(Formandos);
