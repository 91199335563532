import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import InputMask from 'react-input-mask';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import validator from 'validator';

import { descobrirContrato } from '../actions/ContratoActions';
import { consultarTurmas } from '../actions/TurmaActions';
import { obterFormandosPeloContrato, editarFormandoEASAAS } from '../actions/FormandoActions';
import { toast } from 'react-toastify';

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? '#3c8dbc' : '#ccc',
    borderRadius: 0,
    '&:hover': {
      borderColor: state.isFocused ? '#ccc' : '#ccc'
    }
  })
}

class MeusDados extends React.Component {

  state = {
    showModalCursoNaoCadastrado: false,
    formando: {},
    turmasSelect: [],
    tipoPessoa: ""
  }

  async componentWillMount () {
    await this.props.descobrirContrato(this.props.usuario.tipo, this.props.usuario.tipo === "formando" ? this.props.numeroContrato : this.props.usuario.id).then(async () => {
      if (this.props.contrato.turmas && this.props.contrato.turmas.length > 0) {
        let turmas = [];
        await this.carregarDadosFormando();
        this.props.contrato.turmas.forEach((turma) => { turmas.push({value: turma.id, label: turma.curso}) });
        this.setState({turmasSelect: turmas});
      } else {
        this.setState({showModalCursoNaoCadastrado: true});
      }   
    });
  }

  async carregarDadosFormando () {
    await this.props.obterFormandosPeloContrato(this.props.contrato.id);

    let formando = this.props.formandos.filter((formando) => { return formando.usuario && formando.usuario.id === this.props.usuario.id})[0],
      turmaSelecionada = this.props.contrato.turmas.filter((turma) => {return turma.id === formando.turma})[0];

    formando = { 
      id: formando.id,
      nome: formando.nome,
      email: formando.email,
      documento: formando.documento,
      celular: formando.celular,
      turma: { value: turmaSelecionada.id, label: turmaSelecionada.curso } 
    };

    this.setState({ formando, tipoPessoa: !formando.documento || formando.documento.length === 14 || formando.documento.length === 0 ? "fisica" : "juridica" });
  }

  handleChangeUsuario (e) {
    const { name, value } = e.target;
    this.setState({formando: {...this.state.formando, [name]: value}});
  }
  
  handleChangeTurma (turmaSelecionada) {
    this.setState({formando: {...this.state.formando, turma: turmaSelecionada}});
  }

  async handleSubmitUsuario (e) {
    e.preventDefault();
    this.setState({salvando: false});

    let formando = Object.assign({}, this.state.formando),
      validadorDocumento = require("br-doc-validator");
    
    await validadorDocumento.documentValidatorProm(formando.documento).then(() => { 
      if (validator.isEmail(formando.email)) {
        formando.turma = formando.turma.value;
        formando.celular = Number(String(formando.celular).replace(/[()-.+\s]/g, ""))
        this.props.editarFormandoEASAAS(formando, this.props.contrato.numero);
        toast.success("Formando alterado com sucesso!");
        this.props.history.push("./");
        this.setState({salvando: false}); 
      } else {
        toast.error("O e-mail inserido é inválido!");
      }
    }, () => {
      toast.error("O documento inserido é inválido!");
    });
  }

  render () {
    if (this.props.usuario.tipo === "comissao") {
      return <Redirect to="./"/>;
    }
    return (
      <div className="row">
        <div className="col-sm-12 col-md-6">
        <div className="box box-solid">
          <div className="box-header"><div className="box-title">Meus dados</div></div>
          <div className="box-body">
            <form onSubmit={this.handleSubmitUsuario.bind(this)} onChange={this.handleChangeUsuario.bind(this)}>
              <div className="form-group">
                <label><span className="perguntaObrigatoria">*</span>Eu quero me cadastrar como: </label><br/>
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <label style={{fontWeight: 100}}><input type="radio" name="pessoa" value="fisica" onChange={(e) => this.setState({ tipoPessoa: e.target.value })} checked={this.state.tipoPessoa === "fisica"}/> pessoa física</label>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <label style={{fontWeight: 100}}><input type="radio" name="pessoa" value="juridica" onChange={(e) => this.setState({ tipoPessoa: e.target.value })} checked={this.state.tipoPessoa === "juridica"}/> pessoa jurídica</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="nome"><span className="perguntaObrigatoria">*</span>Nome completo</label>
                <input type="text" className="form-control" id="nome" name="nome" value={this.state.formando.nome} required />
              </div>
              <div className="form-group">
                <label htmlFor="documento"><span className="perguntaObrigatoria">*</span>{this.state.tipoPessoa === "fisica" ? "CPF" : "CNPJ"}</label>
                <InputMask className="form-control" id="documento" name="documento" value={this.state.formando.documento} mask={this.state.tipoPessoa === "fisica" ? "999.999.999-99" : "99.999.999/9999-99"} required />
              </div>
              <div className="form-group">
                <label htmlFor="email"><span className="perguntaObrigatoria">*</span>E-mail</label>
                <input type="email" className="form-control" id="email" name="email" value={this.state.formando.email} required />
              </div>
              <div className="form-group">
                <label htmlFor="celular"><span className="perguntaObrigatoria">*</span>Celular</label>
                <InputMask className="form-control" id="celular" name="celular" value={this.state.formando.celular} mask="(99) 9 9999-9999" required />
              </div>
              <div className="form-group">
                <label htmlFor="turma"><span className="perguntaObrigatoria">*</span>Curso</label>
                <Select
                  required
                  placeholder="Selecione o curso"
                  name="turma"
                  value={this.state.formando.turma}
                  onChange={this.handleChangeTurma.bind(this)}
                  options={this.state.turmasSelect}
                  styles={customStyles}
                />
              </div>
              <br/>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button type="submit" className="btn btn-success btn-flat" disabled={this.state.salvando}>
                  {this.state.salvando ? <span><i className="fa fa-spinner fa-pulse fa-fw"></i> Carregando</span> : "Atualizar"}
                </button>  
              </div>
            </form>
            </div>
          </div>
        </div>
      
        <Modal show={this.state.showModalCursoNaoCadastrado} dialogClassName="modal-80w">
          <Modal.Body>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
              <img src="./images/info.png" alt="Sucesso" height="75"/>
              <h2 style={{textAlign: "center"}}>Não conseguimos encontrar o seu curso</h2>
              <h4 style={{textAlign: "center"}}>Para atualizar suas informações é necessário que o seu curso esteja cadastrado no sistema. Por favor, solicite ao seu atendente que faça o cadastro.</h4>
              <a href="./" className="btn btn-outline-info btn-lg" style={{marginTop: 17}}>Voltar</a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    contrato: state.contrato.especifico,
    formandos: state.formando.todos,
    turmas: state.turmas.turmas,
    numeroContrato: state.usuario.numeroContrato
  }
}

export default connect(mapStateToProps, { consultarTurmas, editarFormandoEASAAS, obterFormandosPeloContrato, descobrirContrato })(MeusDados);
