import React from 'react';
import {descobrirContrato} from '../../actions/ContratoActions';
import {obterFormandosPeloContrato} from '../../actions/FormandoActions';
import {enviarEmail} from '../../actions/EmailActions';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import './styles.css'
import Joyride from 'react-joyride';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import EmailServices from '../../services/EmailService';
class Contrato extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      contratos:[],
      usuarios:[],
      iniciouTabela:false,
      novosItens:[],
      quantidade:0,
      run: false,
      datatables: ["datatableFormandos"],
      steps: [
        {
          content: <div><h3>Bem-vindo(a) a página do contrato!</h3> <span style={{textAlign: "center"}}>É aqui que você poderá ver as informações gerais do seu contrato.</span></div>,
          placement: 'center',
          target: '#divContent'
        },
        {
          content: <div><h3>Informações do contrato</h3> <span style={{textAlign: "center"}}>Você poderá ver as informações do seu contrato aqui.</span></div>,
          placement: 'bottom',
          target: '#dadosGerais'
        }
        // ,
        // {
        //   content: <div><h3>Tabela de formandos cadastrados</h3> <span style={{textAlign: "center"}}>Você poderá ver todos os formandos cadastrados, bem como a sua senha de acesso.</span></div>,
        //   placement: 'bottom',
        //   target: '#datatableFormandos'
        // }
      ]
    }
  }

  componentWillMount(){
    if (localStorage.getItem("tutorialContrato") === null) {
      this.setState({run: true});
      localStorage.setItem("tutorialContrato", true);
    }
    this.props.descobrirContrato(this.props.usuario.tipo, this.props.usuario.tipo === "formando" ? this.props.numeroContrato : this.props.usuario.id).then(() => {
      this.props.obterFormandosPeloContrato(this.props.contrato.id);
    });
  }

  enviarAcesso(){
    let email = new EmailServices();
    email.enviar("getTemplateReenviarSenhaAcesso", {email: this.props.contrato.usuario.email, senha: this.props.contrato.usuario.senha, nome: this.props.contrato.usuario.nome});
  }
  
  componentDidMount(){
    window.iniciarVariosDataTables(this.state.datatables);
  }

  componentWillUpdate(){
    window.destruirVariosDataTables(this.state.datatables);
  }

  componentDidUpdate(){
    window.desenharVariosDataTables(this.state.datatables);
  }

  render () {
    if (this.props.contrato.status === "deletado") {
      return (
        <Redirect to="/deletado" />
      );
    }
    return (
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="box box-solid" id="divContent">
            <div className="box-header"><div className="box-title">Dados gerais</div></div>
            <div className="box-body">  
              <ul className="list-group" id="dadosGerais">
                <li className="list-group-item">Número do contrato:<span className="pull-right">{this.props.contrato.numero}</span></li>
                <li className="list-group-item">E-mail principal:<span className="pull-right">{this.props.contrato.emailComissao}</span></li>
                <li className="list-group-item">Gestor(a) contratual:<span className="pull-right">{this.props.contrato.dono.nome ? this.props.contrato.dono.nome : "Ainda não foi definido"}</span></li>
                <li className="list-group-item">Data de início:<span className="pull-right">{new Date(this.props.contrato.dataInicio).toLocaleDateString()}</span></li>
              </ul>
            </div>
            <Joyride steps={this.state.steps} continuous={true} run={this.state.run} scrollToFirstStep={true} showProgress={true} showSkipButton={true} steps={this.state.steps} styles={{options: { zIndex: 10000}}}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado,
    contrato: state.contrato.especifico,
    formandos: state.formando.todos,
    numeroContrato: state.usuario.numeroContrato
  }
}

export default connect(mapStateToProps, {enviarEmail, descobrirContrato, obterFormandosPeloContrato})(Contrato);
