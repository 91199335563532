import React, { useState, useEffect } from 'react';

import {
  Modal,
  ModalBody,
  FormGroup,
  Col,
  Row,
  Form,
  ModalHeader,
} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import DatePicker, { registerLocale } from 'react-datepicker';
import validator from 'validator';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import el from 'date-fns/locale/pt-BR';

import MapaService from '../services/MapaService';
import TelegramService from '../services/TelegramService';
import { URL_PAGAMENTO } from '../config/urls';
import { showErrorASAAS } from '../utils/asaasError';

const box = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 137px)',
  },
  itemMain = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 20px',
  },
  itemOrder = {
    color: '#575757',
    backgroundColor: '#eee',
    boxShadow: '0px 1px 4px rgba(0,0,0,.2)',
    fontSize: 20,
    textAlign: 'center',
    borderRadius: 25,
    padding: 7,
    fontWeight: 'bolder',
    maxWidth: 45,
    width: 45,
  },
  itemOrderActive = {
    color: '#575757',
    backgroundColor: 'white',
    boxShadow: '0px 2px 10px rgba(0,0,0,.2)',
    fontSize: 20,
    textAlign: 'center',
    borderRadius: 25,
    padding: 7,
    fontWeight: 'bolder',
    maxWidth: 45,
    width: 45,
  },
  itemOrderDone = {
    color: 'white',
    backgroundColor: '#28a745',
    boxShadow: '0px 1px 4px rgba(0,0,0,.2)',
    fontSize: 20,
    textAlign: 'center',
    borderRadius: 25,
    padding: 7,
    fontWeight: 'bolder',
    maxWidth: 45,
    width: 45,
  },
  itemSeparator = { width: 75, height: 2, backgroundColor: 'gray' },
  itemSeparatorTitle = {
    width: 75,
    height: 2,
    backgroundColor: 'gray',
    marginBottom: 25,
  };

export default function ModalPagamento(props) {
  const [contrato, setContrato] = useState(null);
  const [pedido, setPedido] = useState(null);
  const [anos, setAnos] = useState([]);
  const [valorTotal, setValorTotal] = useState(null);
  const passos = [
    'Confirmação do pedido',
    'Forma de pagamento',
    'Dados para pagamento',
  ];
  const [membroComissao, setMembroComissao] = useState(0);
  const [descontoComissao, setDescontoComissao] = useState(0);
  const [numeroParcelasMapa, setNumeroParcelasMapa] = useState(0);
  const [passoSelecionado, setPassoSelecionado] = useState(1);
  const [formaPagamento, setFormaPagamento] = useState('');
  const [parcelasCalculadas, setParcelasCalculadas] = useState([]);
  const [parcelaSelecionada, setParcelaSelecionada] = useState({});
  const [agendamento, setAgendamento] = useState(null);
  const [showModalAlterarData, setShowModalAlterarData] = useState(false);
  const [novaDataPagamento, setNovaDataPagamento] = useState(null);
  const [dataMinimaPagamento, setDataMinimaPagamento] = useState('');
  const [dataMaximaPagamento, setDataMaximaPagamento] = useState('');
  const [podeAlterarAData, setPodeAlterarAData] = useState(true);
  const [showDataTo, setShowDataTo] = useState('fisica');
  const [carregandoFinalizarPedido, setCarregandoFinalizarPedido] =
    useState(false);
  const [stepDadosPagamento, setStepDadosPagamento] = useState('');
  const [infoTitularCartaoCredito, setInfoTitularCartaoCredito] = useState({
    name: '',
    email: '',
    cpfCnpj: '',
    postalCode: '',
    addressNumber: '',
    phone: '',
  });
  const [infoCartaoCredito, setInfoCartaoCredito] = useState({
    holderName: '',
    number: '',
    expiryMonth: '',
    expiryYear: '',
    ccv: '',
  });
  const [exibirAlertaDataInvalida, setExibirAlertaDataInvalida] =
    useState(false);

  useEffect(() => {
    const { contrato, data, formando } = props,
      windowWidth = window.innerWidth;

    let modalDialog = document.querySelector('#modalPagamento .modal-dialog'),
      modalContent = document.querySelector('#modalPagamento .modal-content'),
      modalBody = document.querySelector('#modalPagamento .modal-body'),
      anoAtual = new Date().getFullYear(),
      auxAnos = [];

    modalDialog.style.height = '97vh';
    modalContent.style.height = '100%';
    modalBody.style.height = '100%';

    if (windowWidth > 990) {
      modalDialog.style.width = '55%';
      modalDialog.style.height = '92vh';
    }

    for (let i = 0; i <= 10; i++) {
      auxAnos.push(anoAtual + i);
    }

    setContrato(contrato);
    setPedido(data);
    setAnos(auxAnos);
    setInfoCartaoCredito({
      ...infoCartaoCredito,
      expiryMonth: '01',
      expiryYear: auxAnos[0],
    });
    registerLocale('el', el);
    setMembroComissao(formando.membroComissao);
  }, [props]);

  useEffect(() => {
    if (pedido) {
      let total = 0,
        desconto = 0,
        mapa = new MapaService();

      mapa
        .consultarDadosPagamentoOrcamento(contrato.numOrcamento)
        .then((response) => {
          if (pedido[0].id === 8978) {
            desconto = 50;
          } else {
            desconto = response[0].descontoComissao;
          }

          pedido.forEach((formando) => {
            formando.itens.forEach((item) => {
              total += item.preco * item.quantidade;
            });
          });

          setDescontoComissao(desconto);
          setNumeroParcelasMapa(response[0].NParcelasBoleto);
          setValorTotal(total);
        })
        .catch((erro) => {
          new TelegramService().novo('ERROR', [
            JSON.stringify(erro),
            'Falha ao obter as informações de pagamento',
            `usuarioLogado: ${JSON.parse(
              sessionStorage.getItem('usuarioLogadoComissao')
            )}`,
          ]);
        });
    }
  }, [pedido]);

  useEffect(() => {
    if (parcelaSelecionada > 0) {
      let dataMaxima = new Date(contrato.dataMaximaPagto);
      dataMaxima.setDate(dataMaxima.getDate() - 30 * (parcelaSelecionada - 1));

      if (
        dataMaxima.getTime() <= new Date().getTime() ||
        ['PIX', 'CARTAO_CREDITO'].includes(formaPagamento)
      ) {
        setPodeAlterarAData(false);
      } else {
        setPodeAlterarAData(true);
      }

      setDataMaximaPagamento(
        splitReverseJoin(
          '/',
          '-',
          new Date(dataMaxima).toLocaleDateString('pt-BR')
        )
      );
      criarAgendamento();
    } else {
      setAgendamento(null);
    }
  }, [parcelaSelecionada]);

  useEffect(() => {
    setAgendamento(null);
    setStepDadosPagamento(
      formaPagamento === 'CARTAO_CREDITO' ? 'infoTitularCartao' : ''
    );
    setParcelaSelecionada(formaPagamento === 'BOLETO' ? 1 : 0);
    if (formaPagamento) {
      handleParcelas();
    }
  }, [formaPagamento]);

  function splitReverseJoin(split, join, data) {
    return data.split(split).reverse().join(join);
  }

  function setUTCHour(data) {
    return new Date(data).setUTCHours(3);
  }

  function criarAgendamento(data = null) {
    let auxParcelaSelecionada = parcelasCalculadas.filter((parcela) => {
        return parcela.numParcelas === parcelaSelecionada;
      })[0],
      dataAtual = data
        ? data
        : {
            ano: new Date().getFullYear(),
            mes: new Date().getMonth(),
            dia: new Date().getDate(),
          },
      auxAgendamento = [];

    for (let i = 0; i < auxParcelaSelecionada.numParcelas; i++) {
      let mes = dataAtual.mes + i,
        dia = dataAtual.dia,
        ultimoDiaMes = new Date(dataAtual.ano, mes + 1, 0).getDate();

      if ((mes === 1 || mes === 13) && dia >= ultimoDiaMes) {
        dia = ultimoDiaMes;
      }

      auxAgendamento.push({
        parcela: i + 1,
        valor: auxParcelaSelecionada.valorParcela,
        data: new Date(dataAtual.ano, mes, dia).toLocaleDateString('pt-BR'),
      });
    }

    setAgendamento(auxAgendamento);
  }

  function validarCpf(cpf) {
    var exp = /\.|\-/g;

    cpf = cpf.replace(exp, '').toString();

    if (cpf.length == 11) {
      var v = [];

      //Calcula o primeiro dígito de verificação.
      v[0] = 1 * cpf[0] + 2 * cpf[1] + 3 * cpf[2];
      v[0] += 4 * cpf[3] + 5 * cpf[4] + 6 * cpf[5];
      v[0] += 7 * cpf[6] + 8 * cpf[7] + 9 * cpf[8];
      v[0] = v[0] % 11;
      v[0] = v[0] % 10;

      //Calcula o segundo dígito de verificação.
      v[1] = 1 * cpf[1] + 2 * cpf[2] + 3 * cpf[3];
      v[1] += 4 * cpf[4] + 5 * cpf[5] + 6 * cpf[6];
      v[1] += 7 * cpf[7] + 8 * cpf[8] + 9 * v[0];
      v[1] = v[1] % 11;
      v[1] = v[1] % 10;

      //Retorna Verdadeiro se os dígitos de verificação são os esperados.
      if (v[0] != cpf[9] || v[1] != cpf[10]) {
        return false;
      } else if (
        cpf[0] == cpf[1] &&
        cpf[1] == cpf[2] &&
        cpf[2] == cpf[3] &&
        cpf[3] == cpf[4] &&
        cpf[4] == cpf[5] &&
        cpf[5] == cpf[6] &&
        cpf[6] == cpf[7] &&
        cpf[7] == cpf[8] &&
        cpf[8] == cpf[9] &&
        cpf[9] == cpf[10]
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  function validarCnpj(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) {
      return false;
    }

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    // Cáculo de validação
    let t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0)),
      d2 = parseInt(d.charAt(1)),
      calc = (x) => {
        let n = cnpj.substring(0, x),
          y = x - 7,
          s = 0,
          r = 0;

        for (let i = x; i >= 1; i--) {
          s += n.charAt(x - i) * y--;
          if (y < 2) y = 9;
        }

        r = 11 - (s % 11);
        return r > 9 ? 0 : r;
      };

    return calc(t) === d1 && calc(t + 1) === d2;
  }

  function validarInputs(campos) {
    limparHelpBlock();

    let status = true;

    campos.forEach((campo) => {
      let nome = String(campo.name),
        valor = String(campo.value);

      if (nome === 'cpfCnpj') {
        if (
          (!validarCpf(valor) && valor.length === 14) ||
          (!validarCnpj(valor) && valor.length === 18) ||
          valor.length === 0
        ) {
          campo
            .closest('div')
            .appendChild(
              criarSpan(
                `O ${
                  showDataTo === 'fisica' ? 'CPF' : 'CNPJ'
                } inserido é inválido!`
              )
            );
          status = false;
        }
      } else if (nome === 'email' && !validator.isEmail(valor)) {
        campo
          .closest('div')
          .appendChild(criarSpan('O e-mail inserido é inválido!'));
        status = false;
      } else {
        if (!campo.checkValidity()) {
          campo.closest('div').appendChild(criarSpan(campo.validationMessage));
          status = false;
        }
      }
    });

    return status;
  }

  function criarSpan(texto) {
    let span = document.createElement('span');
    span.textContent = texto;
    span.classList.add('help-block');

    return span;
  }

  function limparHelpBlock() {
    document.querySelectorAll('.help-block').forEach((item) => {
      item.remove();
    });
  }

  function handleSubmitFormaPagamento(e) {
    e.preventDefault();
    setPassoSelecionado(passoSelecionado + 1);
  }

  function handleSubmitPagamento() {
    setCarregandoFinalizarPedido(true);

    if (
      (['BOLETO', 'PARCELAMENTO_BOLETO', 'PIX'].includes(formaPagamento) &&
        new Date(
          splitReverseJoin('/', '-', agendamento[agendamento.length - 1].data)
        ).getTime() <= new Date(contrato.dataMaximaPagto).getTime()) ||
      formaPagamento === 'CARTAO_CREDITO'
    ) {
      let payload = {
        contrato: contrato.numero,
        formaPagamento: formaPagamento === 'PIX' ? 'BOLETO' : formaPagamento,
        dataVencimento: splitReverseJoin('/', '-', agendamento[0].data),
        formandoId: pedido[0].id,
        itens: [],
        numParcelas: parcelaSelecionada,
        remoteIp: sessionStorage.getItem('ip'),
      };

      pedido[0].itens.forEach((item) => {
        let preco = item.quantidade * item.preco;

        if (membroComissao) {
          preco = preco - preco * (descontoComissao / 100);
        }

        payload.itens.push({ id: item.idProduto, quantidade: item.quantidade });
      });

      if (formaPagamento === 'CARTAO_CREDITO') {
        payload.creditCard = infoCartaoCredito;
        payload.creditCardHolderInfo = infoTitularCartaoCredito;
      }

      axios
        .post(`${URL_PAGAMENTO}/novo-pedido`, payload, {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('tokenPagamento'),
          },
        })
        .then((response) => {
          let auxPayload = Object.assign({}, payload);
          auxPayload.creditCard = '';
          auxPayload.creditCardHolderInfo = '';
          // new TelegramService().novo("INFO", [ "----- NOVO PEDIDO -----", JSON.stringify(auxPayload), "----- PARCELAS -----", JSON.stringify(agendamento), `usuarioLogado: ${sessionStorage.getItem("usuarioLogadoComissao")}` ]);
          props.close({ ...response, formaPagamento });
        })
        .catch((erro) => {
          let auxPayload = Object.assign({}, payload);
          auxPayload.creditCard = '';
          auxPayload.creditCardHolderInfo = '';
          new TelegramService().novo('ERROR', [
            erro.message,
            'Novo pedido - formando',
            '\n',
            'Payload',
            JSON.stringify(auxPayload),
            '\n',
            'Payload de erro',
            erro.response
              ? JSON.stringify(erro.response.data)
              : 'Sem response de erro',
            '\n',
            'Usuário logado',
            `${sessionStorage.getItem('usuarioLogadoComissao')}`,
          ]);
          props.close({ status: 500, error: erro });
          showErrorASAAS(erro.response.data);
        });
    } else {
      setPassoSelecionado(passoSelecionado - 1);
      setCarregandoFinalizarPedido(false);
      setExibirAlertaDataInvalida(true);
    }
  }

  function handleSubmitAlterarDataPagamento(e) {
    e.preventDefault();

    let dataSeparada = new Date(setUTCHour(novaDataPagamento)),
      data = {
        ano: dataSeparada.getFullYear(),
        mes: dataSeparada.getMonth(),
        dia: dataSeparada.getDate(),
      };

    criarAgendamento(data);
    setShowModalAlterarData(false);
  }

  function handleCreateFormaPagamento() {
    let dataMaximaParcelamento = new Date(setUTCHour(contrato.dataMaximaPagto));
    dataMaximaParcelamento = new Date(
      new Date(dataMaximaParcelamento).setHours(0, 0, 0, 0)
    );

    const dataAtual = new Date().getTime();
    const dataAtualEhAnteriorQueARecente =
      dataAtual < dataMaximaParcelamento.getTime();

    const dataMaximaParcelamentoMais30Dias = dataMaximaParcelamento;

    dataMaximaParcelamentoMais30Dias.setDate(
      dataMaximaParcelamentoMais30Dias.getDate() + 30
    );

    if (
      valorTotal >= 50 &&
      (dataAtualEhAnteriorQueARecente ||
        dataAtual <= dataMaximaParcelamentoMais30Dias.getTime())
    ) {
      let auxFormaPagamento = [
        { label: 'PIX', value: 'PIX' },
        { label: 'Cartão de crédito', value: 'CARTAO_CREDITO' },
        { label: 'Boleto à vista', value: 'BOLETO' },
      ];

      if (contrato.parcelamentoNoBoleto === 1) {
        auxFormaPagamento = [
          ...auxFormaPagamento,
          { label: 'Parcelamento no boleto', value: 'PARCELAMENTO_BOLETO' },
        ];
      }
      return auxFormaPagamento;
    }
    return [{ label: 'PIX', value: 'PIX' }];
  }

  function handleParcelas() {
    let total = membroComissao
        ? valorTotal - valorTotal * (descontoComissao / 100)
        : valorTotal,
      parcelas = numeroParcelasMapa,
      parcelasAcimaDe50 = total / 50,
      parcelasArr = [{ numParcelas: 1, valorParcela: total }],
      dataMaximaParcelamento = new Date(setUTCHour(contrato.dataMaximaPagto)),
      dataMaximaParcelamentoMais30Dias = new Date(
        setUTCHour(contrato.dataMaximaPagto)
      );

    dataMaximaParcelamento = new Date(
      new Date(dataMaximaParcelamento).setHours(0, 0, 0, 0)
    );

    dataMaximaParcelamentoMais30Dias = new Date(
      new Date(dataMaximaParcelamentoMais30Dias).setHours(0, 0, 0, 0)
    );

    dataMaximaParcelamentoMais30Dias.setDate(
      dataMaximaParcelamentoMais30Dias.getDate() + 30
    );

    if (parcelas > parcelasAcimaDe50) {
      parcelas = Math.trunc(parcelasAcimaDe50);
    }

    if (formaPagamento !== 'PIX') {
      for (let i = 2; i <= parcelas; i++) {
        let dataInicialParcelamento = new Date(new Date().setHours(0, 0, 0, 0));

        dataInicialParcelamento.setDate(
          dataInicialParcelamento.getDate() + 30 * (i - 1)
        );

        if (
          dataInicialParcelamento.getTime() <= dataMaximaParcelamento.getTime()
        ) {
          parcelasArr.push({ numParcelas: i, valorParcela: total / i });
        } else if (
          formaPagamento === 'CARTAO_CREDITO' &&
          dataInicialParcelamento.getTime() <=
            dataMaximaParcelamentoMais30Dias.getTime()
        ) {
          parcelasArr.push({ numParcelas: i, valorParcela: total / i });
        }
      }
    }

    setDataMinimaPagamento(
      splitReverseJoin('/', '-', new Date().toLocaleDateString('pt-BR'))
    );
    setParcelasCalculadas(parcelasArr);
  }

  function handleChangeFormaPagamento(e) {
    const { value } = e.target;
    setFormaPagamento(value);
    setParcelaSelecionada(0);
  }

  function handleChangeNumeroParcelasSelecionadas(e) {
    const { value } = e.target;
    setParcelaSelecionada(Number(value));
  }

  function handleChangeInfoTitularCartao(e) {
    const { name, value } = e.target;

    setInfoTitularCartaoCredito({ ...infoTitularCartaoCredito, [name]: value });
  }

  function handleChangeInfoCartao(e) {
    const { name, value } = e.target;

    setInfoCartaoCredito({ ...infoCartaoCredito, [name]: value });
  }

  function handleFinishedInfoTitularCartao() {
    let campos = document.querySelectorAll('#boxInfoTitularCartao input');

    if (validarInputs(campos)) {
      setStepDadosPagamento('infoCartao');
    }
  }

  function handleFinishedInfoCartao() {
    let campos = document.querySelectorAll('#boxInfoCartao input');

    if (validarInputs(campos)) {
      handleSubmitPagamento();
    }
  }

  function _renderStepsHeader() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: window.innerWidth > 990 ? 'center' : 'flex-start',
          padding: '15px 0px',
          margin: '-15px -15px 25px',
          overflow: 'auto',
          boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
        }}
      >
        {passos.map((item, i) => {
          if (i !== passos.length - 1) {
            return (
              <div
                key={i}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={
                    (itemMain,
                    {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: '0px 20px',
                    })
                  }
                >
                  <div
                    style={
                      i + 1 === passoSelecionado
                        ? itemOrderActive
                        : passoSelecionado > i + 1
                        ? itemOrderDone
                        : itemOrder
                    }
                  >
                    {i + 1}
                  </div>
                  <div style={{ textAlign: 'center' }}>{item}</div>
                </div>
                <div style={itemSeparator}></div>
              </div>
            );
          }
          return (
            <div key={i} style={itemMain}>
              <div
                style={
                  i + 1 === passoSelecionado
                    ? itemOrderActive
                    : passoSelecionado > i + 1
                    ? itemOrderDone
                    : itemOrder
                }
              >
                {i + 1}
              </div>
              <div style={{ textAlign: 'center' }}>{item}</div>
            </div>
          );
        })}
      </div>
    );
  }

  function _renderPedido() {
    return (
      <div style={box}>
        {pedido ? (
          <React.Fragment>
            <div>
              <h4>Confirme o seu pedido:</h4>
              <div style={itemSeparatorTitle}></div>
              <div className='table-responsive fixed'>
                <table className='table table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th>Formando</th>
                      <th>Produto</th>
                      <th>Quantidade</th>
                      <th>Valor unitário</th>
                      <th>Valor total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pedido.map((formando) => {
                      return formando.itens.map((item, i) => (
                        <tr key={i}>
                          <td> {formando.nome} </td>
                          <td> {item.produto} </td>
                          <td> {item.quantidade} </td>
                          <td> R$ {props.formatarMoedaBR(item.preco)} </td>
                          <td> R$ {props.formatarMoedaBR(item.total)} </td>
                        </tr>
                      ));
                    })}
                  </tbody>
                  {membroComissao && descontoComissao > 0 ? (
                    <tfoot>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            color: 'red',
                            fontWeight: 600,
                            textAlign: 'right',
                          }}
                        >
                          {' '}
                          Subtotal{' '}
                        </td>
                        <td style={{ color: 'red' }}>
                          {' '}
                          R$ {props.formatarMoedaBR(valorTotal)}{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            color: 'blue',
                            fontWeight: 600,
                            textAlign: 'right',
                          }}
                        >
                          {' '}
                          Desconto{' '}
                        </td>
                        <td style={{ color: 'blue' }}>
                          {' '}
                          R${' '}
                          {props.formatarMoedaBR(
                            valorTotal * (descontoComissao / 100)
                          )}{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            color: 'red',
                            fontWeight: 600,
                            textAlign: 'right',
                          }}
                        >
                          {' '}
                          Total{' '}
                        </td>
                        <td style={{ color: 'red' }}>
                          {' '}
                          R${' '}
                          {props.formatarMoedaBR(
                            valorTotal - valorTotal * (descontoComissao / 100)
                          )}{' '}
                        </td>
                      </tr>
                    </tfoot>
                  ) : (
                    <tfoot>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            color: 'red',
                            fontWeight: 600,
                            textAlign: 'right',
                          }}
                        >
                          {' '}
                          Total{' '}
                        </td>
                        <td style={{ color: 'red' }}>
                          {' '}
                          R$ {props.formatarMoedaBR(valorTotal)}{' '}
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className='btn btn-info btn-lg'
                onClick={() => setPassoSelecionado(passoSelecionado + 1)}
              >
                Próximo
              </button>
            </div>
          </React.Fragment>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            <i className='fa fa-spinner fa-spin' /> Aguarde
          </div>
        )}
      </div>
    );
  }

  function _renderFormaPagamento() {
    return (
      <React.Fragment>
        {exibirAlertaDataInvalida ? (
          <div className='alert alert-danger'>
            {' '}
            <i className='fa fa-info-circle' /> A data de pagamento selecionada
            excede o prazo permitido (
            {new Date(setUTCHour(contrato.dataMaximaPagto)).toLocaleDateString(
              'pt-BR'
            )}
            )
          </div>
        ) : null}
        <Form style={box} onSubmit={handleSubmitFormaPagamento}>
          <div>
            <h4>Escolha a forma de pagamento:</h4>
            <div style={itemSeparatorTitle}></div>
            <Row>
              <Col sm={12} md={4}>
                <FormGroup>
                  <label>
                    <span className='perguntaObrigatoria'>*</span>Tipo de
                    pagamento:
                  </label>
                  <br />
                  <select
                    className='form-control'
                    value={formaPagamento}
                    onChange={(e) => handleChangeFormaPagamento(e)}
                    required
                  >
                    <option value=''>Selecione</option>
                    {_renderItensFormaPagamento()}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            {formaPagamento ? (
              <Row>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <label>
                      <span className='perguntaObrigatoria'>*</span>Parcelar em:
                    </label>
                    <select
                      className='form-control'
                      value={parcelaSelecionada}
                      onChange={(e) =>
                        handleChangeNumeroParcelasSelecionadas(e)
                      }
                      disabled={formaPagamento === 'BOLETO'}
                      required
                    >
                      <option value=''>Selecione</option>
                      {parcelasCalculadas.map((item, i) => (
                        <option key={i} value={item.numParcelas}>
                          {item.numParcelas} parcela
                          {item.numParcelas === 1 ? '' : 's'} de R$
                          {props.formatarMoedaBR(item.valorParcela)} sem juros
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            {agendamento ? (
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <label>
                      O pagamento será agendado da seguinte maneira
                      {podeAlterarAData ? (
                        <span style={{ marginLeft: 5 }}>
                          (
                          <a
                            onClick={() => setShowModalAlterarData(true)}
                            style={{ cursor: 'pointer' }}
                          >
                            alterar data
                          </a>
                          )
                        </span>
                      ) : null}
                      :
                    </label>
                    {agendamento.map((item, i) => (
                      <div key={i}>
                        {' '}
                        {item.parcela}° parcela: R$
                        {props.formatarMoedaBR(item.valor)} agendada para o dia{' '}
                        {item.data}{' '}
                      </div>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <button
              style={{ marginRight: 5 }}
              className='btn btn-link btn-lg'
              onClick={() => setPassoSelecionado(passoSelecionado - 1)}
            >
              Voltar
            </button>
            <button className='btn btn-info btn-lg' type='submit'>
              Próximo
            </button>
          </div>
        </Form>
        <Modal
          show={showModalAlterarData}
          onHide={() => setShowModalAlterarData(false)}
        >
          <ModalHeader closeButton>Alteração da data de pagamento</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmitAlterarDataPagamento}>
              <div className='alert alert-info' style={{ fontSize: 14 }}>
                <p style={{ marginBottom: 5 }}>
                  <i
                    style={{ marginRight: 5 }}
                    className='fa fa-info-circle'
                  ></i>{' '}
                  ATENÇÃO:
                </p>
                <p style={{ marginBottom: 5 }}>
                  Escolha a data da primeira parcela no campo abaixo. Você
                  poderá escolher uma data que esteja entre{' '}
                  <strong>
                    {splitReverseJoin('-', '/', dataMinimaPagamento)}
                  </strong>{' '}
                  e{' '}
                  <strong>
                    {splitReverseJoin('-', '/', dataMaximaPagamento)}
                  </strong>
                  .
                </p>
                <p>
                  OBS: Caso você tenha mais de uma parcela, elas serão agendadas
                  para pagamento nos meses seguintes.
                </p>
              </div>

              <label>
                <span className='perguntaObrigatoria'>*</span>Data da primeira
                parcela
              </label>
              <Row>
                <FormGroup>
                  <Col xs={12}>
                    <DatePicker
                      selected={novaDataPagamento}
                      className='form-control'
                      onChange={(date) => setNovaDataPagamento(date)}
                      dateFormat='dd/MM/yyyy'
                      locale='el'
                      filterDate={(date) => {
                        return (
                          new Date(setUTCHour(date)).getTime() >=
                            new Date(
                              setUTCHour(dataMinimaPagamento)
                            ).getTime() &&
                          new Date(setUTCHour(date)).getTime() <=
                            new Date(setUTCHour(dataMaximaPagamento)).getTime()
                        );
                      }}
                      placeholderText='Selecione uma data'
                      required
                    />
                    <button
                      style={{ margin: '0 0 4px 15px' }}
                      className='btn btn-success btn-flat'
                      button='submit'
                    >
                      Alterar
                    </button>
                  </Col>
                </FormGroup>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }

  function _renderItensFormaPagamento() {
    const itens = handleCreateFormaPagamento();
    return itens.map((item) => (
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    ));
  }

  function _renderDadosPagamento() {
    return (
      <React.Fragment>
        {stepDadosPagamento === 'infoTitularCartao' ? (
          <div style={box} id='boxInfoTitularCartao'>
            <div>
              <h4>
                Preencha os campos abaixo com as informações do titular do
                cartão de crédito para finalizar o pagamento:
              </h4>
              <div style={itemSeparatorTitle}></div>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <label>
                      <span className='perguntaObrigatoria'>*</span>Eu vou pagar
                      como:{' '}
                    </label>
                    <br />
                    <Row>
                      <Col sm={12} md={3}>
                        <label style={{ fontWeight: 100 }}>
                          <input
                            type='radio'
                            name='pessoa'
                            onChange={() => setShowDataTo('fisica')}
                            checked={showDataTo === 'fisica'}
                          />{' '}
                          pessoa física
                        </label>
                      </Col>
                      <Col sm={12} md={3}>
                        <label style={{ fontWeight: 100 }}>
                          <input
                            type='radio'
                            name='pessoa'
                            onChange={() => setShowDataTo('juridica')}
                            checked={showDataTo === 'juridica'}
                          />{' '}
                          pessoa jurídica
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8}>
                  <FormGroup>
                    <label htmlFor='nome'>
                      <span className='perguntaObrigatoria'>*</span>Nome:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='name'
                      id='nome'
                      value={infoTitularCartaoCredito.name}
                      onChange={(e) => handleChangeInfoTitularCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <label htmlFor='email'>
                      <span className='perguntaObrigatoria'>*</span>E-mail:
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      name='email'
                      id='email'
                      value={infoTitularCartaoCredito.email}
                      onChange={(e) => handleChangeInfoTitularCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <label htmlFor='cpfCnpj'>
                      <span className='perguntaObrigatoria'>*</span>
                      {showDataTo === 'fisica' ? 'CPF' : 'CNPJ'}:
                    </label>
                    <InputMask
                      className='form-control'
                      name='cpfCnpj'
                      id='cpfCnpj'
                      value={infoTitularCartaoCredito.cpfCnpj}
                      mask={
                        showDataTo === 'fisica'
                          ? '999.999.999-99'
                          : '99.999.999/9999-99'
                      }
                      maskChar=''
                      onChange={(e) => handleChangeInfoTitularCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <label htmlFor='phone'>
                      <span className='perguntaObrigatoria'>*</span>Celular:
                    </label>
                    <InputMask
                      className='form-control'
                      name='phone'
                      id='phone'
                      value={infoTitularCartaoCredito.phone}
                      mask='(99) 9 9999-9999'
                      maskChar=''
                      onChange={(e) => handleChangeInfoTitularCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <label htmlFor='postalCode'>
                      <span className='perguntaObrigatoria'>*</span>CEP:
                    </label>
                    <InputMask
                      className='form-control'
                      name='postalCode'
                      id='postalCode'
                      value={infoTitularCartaoCredito.postalCode}
                      mask='99999-999'
                      maskChar=''
                      onChange={(e) => handleChangeInfoTitularCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <label htmlFor='addressNumber'>
                      <span className='perguntaObrigatoria'>*</span>Número da
                      residência:
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      name='addressNumber'
                      id='addressNumber'
                      value={infoTitularCartaoCredito.addressNumber}
                      onChange={(e) => handleChangeInfoTitularCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <button
                style={{ marginRight: 5 }}
                className='btn btn-link btn-lg btn-flat'
                onClick={() => setPassoSelecionado(passoSelecionado - 1)}
                type='button'
              >
                Voltar
              </button>
              <button
                className='btn btn-info btn-lg btn-flat'
                onClick={() => handleFinishedInfoTitularCartao()}
                type='button'
              >
                Próximo
              </button>
            </div>
          </div>
        ) : stepDadosPagamento === 'infoCartao' ? (
          <div style={box} id='boxInfoCartao'>
            <div>
              <h4>
                Preencha os campos abaixo com as informações do cartão de
                crédito para finalizar o pagamento:
              </h4>
              <div style={itemSeparatorTitle}></div>
              <Row>
                <Col sm={12} md={8}>
                  <FormGroup>
                    <label htmlFor='holderName'>
                      <span className='perguntaObrigatoria'>*</span>Nome do
                      cartão:
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='holderName'
                      id='holderName'
                      value={infoCartaoCredito.holderName}
                      onChange={(e) => handleChangeInfoCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8}>
                  <FormGroup>
                    <label htmlFor='number'>
                      <span className='perguntaObrigatoria'>*</span>Número:
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      name='number'
                      id='number'
                      value={infoCartaoCredito.number}
                      onChange={(e) => handleChangeInfoCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <label htmlFor='expiryMonth'>
                      <span className='perguntaObrigatoria'>*</span>Mês
                    </label>
                    <select
                      className='form-control'
                      name='expiryMonth'
                      id='expiryMont'
                      value={infoCartaoCredito.expiryMonth}
                      onChange={(e) => handleChangeInfoCartao(e)}
                      required
                    >
                      <option value='01'>01</option>
                      <option value='02'>02</option>
                      <option value='03'>03</option>
                      <option value='04'>04</option>
                      <option value='05'>05</option>
                      <option value='06'>06</option>
                      <option value='07'>07</option>
                      <option value='08'>08</option>
                      <option value='09'>09</option>
                      <option value='10'>10</option>
                      <option value='11'>11</option>
                      <option value='12'>12</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <label htmlFor='expiryMonth'>
                      <span className='perguntaObrigatoria'>*</span>Ano
                    </label>
                    <select
                      className='form-control'
                      name='expiryYear'
                      id='expiryYear'
                      value={infoCartaoCredito.expiryYear}
                      onChange={(e) => handleChangeInfoCartao(e)}
                      required
                    >
                      {anos.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <label htmlFor='ccv'>
                      <span className='perguntaObrigatoria'>*</span>Código de
                      segurança
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      name='ccv'
                      id='ccv'
                      value={infoCartaoCredito.ccv}
                      onChange={(e) => handleChangeInfoCartao(e)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <button
                style={{ marginRight: 5 }}
                className='btn btn-link btn-lg btn-flat'
                onClick={() => setStepDadosPagamento('infoTitularCartao')}
                type='button'
              >
                Voltar
              </button>
              <button
                className='btn btn-success btn-lg btn-flat'
                onClick={(e) => handleFinishedInfoCartao()}
                disabled={carregandoFinalizarPedido}
                type='button'
              >
                {carregandoFinalizarPedido ? (
                  <span>
                    <i className='fa fa-spin fa-spinner'></i> Finalizando
                  </span>
                ) : (
                  'Finalizar'
                )}
              </button>
            </div>
          </div>
        ) : formaPagamento === 'PIX' ? (
          <div style={box}>
            <div>
              <h4>Finalize a operação</h4>
              <div style={itemSeparatorTitle}></div>
              <p>
                Clique em Finalizar para ter acesso ao QRCODE e ao Pix Copia e
                Cola.
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <button
                style={{ marginRight: 5 }}
                className='btn btn-link btn-lg btn-flat'
                onClick={() => setPassoSelecionado(passoSelecionado - 1)}
                type='button'
              >
                Voltar
              </button>
              <button
                className='btn btn-success btn-lg btn-flat'
                onClick={() => handleSubmitPagamento()}
                disabled={carregandoFinalizarPedido}
                type='button'
              >
                {carregandoFinalizarPedido ? (
                  <span>
                    <i className='fa fa-spin fa-spinner'></i> Finalizando
                  </span>
                ) : (
                  'Finalizar'
                )}
              </button>
            </div>
          </div>
        ) : (
          <div style={box}>
            <div>
              <h4>Finalize a operação</h4>
              <div style={itemSeparatorTitle}></div>
              <p>
                Para finalizar a compra, clique no botão Finalizar. Um e-mail
                com as informações de pagamento será enviado para{' '}
                <span style={{ fontStyle: 'oblique' }}>
                  {props.usuarioLogado.email}
                </span>
                .
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <button
                style={{ marginRight: 5 }}
                className='btn btn-link btn-lg btn-flat'
                onClick={() => setPassoSelecionado(passoSelecionado - 1)}
                type='button'
              >
                Voltar
              </button>
              <button
                className='btn btn-success btn-lg btn-flat'
                onClick={() => handleSubmitPagamento()}
                disabled={carregandoFinalizarPedido}
                type='button'
              >
                {carregandoFinalizarPedido ? (
                  <span>
                    <i className='fa fa-spin fa-spinner'></i> Finalizando
                  </span>
                ) : (
                  'Finalizar'
                )}
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ModalBody>
        {_renderStepsHeader()}
        {passoSelecionado === 1
          ? _renderPedido()
          : passoSelecionado === 2
          ? _renderFormaPagamento()
          : passoSelecionado === 3
          ? _renderDadosPagamento()
          : null}
      </ModalBody>
    </React.Fragment>
  );
}
