import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {logout} from '../actions/UsuarioActions';

import $ from "jquery";

class Header extends React.Component {

  state = {
    dropdown: false
  }
  
  logout () {
    localStorage.clear();
    this.props.logout();
  }

  abrirOverlay () {
    if (window.innerWidth <= 767) {
      if (document.querySelector("body").classList.contains("sidebar-open")) {
        $("#overlay-menuPrincipal").css("display", "block");
      } else {
        $("#overlay-menuPrincipal").css("display", "none");
      }
    }
  } 

  render () {
    return (
      <header className="main-header">
        <Link to="/" className="logo">
          <span className="logo-mini"><img src="/images/logo-simbolo.png" alt="Logo da Alpha" width="45" height="40"/></span>
          <span className="logo-lg"><strong>Alpha Convites</strong></span>
        </Link>
        <nav className="navbar navbar-static-top">
          <a className="sidebar-toggle" onClick={this.abrirOverlay.bind(this)} data-toggle="offcanvas" role="button">
            <span className="sr-only">Toggle navigation</span>
          </a>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav" style={{marginRight: 15, height: 50, display: "flex", alignItems: "center"}}>
              <li className="user user-menu">
                <button type="button" className="btn btn-warning pull-right" data-toggle="modal" data-target="#modal-video" style={{display: "flex", alignItems: "center", borderRadius: 25}}>
                  <span className="glyphicon glyphicon-question-sign" aria-hidden="true" style={{marginRight: 5}}></span>Tutorial
                </button>
              </li>
              <li className="user user-menu" style={{marginLeft: 15}}>
                <button onClick={() => {this.setState({dropdown: !this.state.dropdown})}} className="btn btn-default" type="button" style={{backgroundColor: "white", border: "0 none"}}>
                  {this.props.usuario.nome ? this.props.usuario.nome.includes("@") ? this.props.usuario.nome.split("@")[0] : this.props.usuario.nome.split(" ")[0] : ""}
                  {' '}<span className="caret"></span>
                </button>
              </li>
            </ul>
            <ul className="dropdown-menu" style={{display: this.state.dropdown ? "block" : "none", left: "auto", right: 15}} aria-labelledby="dropdownMenu1">
              { 
                this.props.usuario.tipo === "formando" ? 
                  <React.Fragment>
                    <li><Link to="/meus-dados">Meus dados</Link></li>
                    <li role="separator" className="divider"></li>
                  </React.Fragment>
                : null
              }
              <li><a role="button" onClick={this.logout.bind(this)} href="#"><i className="fas fa-sign-out-alt"></i> Sair</a></li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario.autenticado
  };
};

export default connect(mapStateToProps, {logout})(Header);
