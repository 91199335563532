import axios from 'axios';
import {REQUEST_URL} from '../config/urls';

const requisitar = () => {
  return {
    type: "REQUISITAR_ITEM"
  };
}
const novoItem = (item) => {
  return {
    type: "NOVO_ITEM",
    item
  }
}
const alterarItem = (item) => {
  return {
    type: "ALTERAR_ITEM",
    item
  }
}
const obterTodosContrato = (itens) => {
  return {
    type: "OBTER_TODOS_ITENS",
    itens
  }
}
const obterTodosDistribuiveisContrato = (itens) => {
  return {
    type: "OBTER_TODOS_ITENS_DISTRIBUIVEIS",
    itens
  }
}
/*
const obterTodos = (itens) => {
  return {
    type: "OBTER_TODOS_ITENS",
    itens
  }
}

const removerItem = (id) => {
  return {
    type: "REMOVER_ITEM",
    id
  }
}
*/

const erro = (erro) => {
  if(erro.statusCode===401){
    axios.defaults.headers.common['Authorization'] = "";
    return {
      type: "LOGOUT"
    }
  }else{
    return {
      type: "ERRO_ITEM",
      erro
    }
  }
}

export const adicionarItem = (item) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.post(`${REQUEST_URL}/itens`, item).then((response) => {
      dispatch(novoItem(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const editarItem = (item, id) => {
  return dispatch => {
    dispatch(requisitar());
    return axios.patch(`${REQUEST_URL}/itens/${id}`, item).then((response) => {
      dispatch(alterarItem(response.data));
    }).catch(error => {
      dispatch(erro(error.response.data.error));
    });
  }
}
export const obterItensPeloContrato = (contratoId) =>{
  return dispatch =>{
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/itens?filter={"where":{"contrato":${contratoId}}}`).then((response) => {
      dispatch(obterTodosContrato(response.data));
    }).catch(error => {
      //console.log("erro******************************************************8");
      //console.log(error);
      dispatch(erro(error));
    });
  }
}
export const obterItensTipoFormandoPeloContrato = (contratoId) =>{
  return dispatch =>{
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/itens?filter={"where":{"contrato":${contratoId},"tipo":"F"}}`).then((response) => {
      dispatch(obterTodosDistribuiveisContrato(response.data));
    }).catch(error => {
      dispatch(erro(error));
    });
  }
}
export const obterItensTipoFTab = (contratoId, tab) =>{
  let tabs = [];
  tab.forEach((item) => {
    tabs.push(`{"observacao": "${item}"}`);
  });
  return dispatch => {
    dispatch(requisitar());
    return axios.get(`${REQUEST_URL}/itens?filter={"where":{"and":[{"or":[${tabs}]},{"contrato":${contratoId}},{"tipo":"F"}]}}`).then((response) => {
      dispatch(obterTodosDistribuiveisContrato(response.data));
    }).catch(error => {
      dispatch(erro(error));
    });
  }
}