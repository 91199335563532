import React, { useEffect, useState } from "react";
import { Modal, ModalBody, FormGroup, Col, Row, Form, ModalHeader } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import axios from 'axios';
import { URL_PAGAMENTO } from '../../config/urls';
import {toast} from "react-toastify";

export default function TabelaParcelasCobranca(props) {

  const [parcelas, setParcelas] = useState([]);
  const [alterarDataParcela, setAlterarDataParcela] = useState(false);
  const [dataMaxPagto, setDataMaxPagto] = useState(null);
  const [dataMinPagto, setDataMinPagto] = useState(null);
  const [novaDataPagamento, setNovaDataPagamento] = useState(null);
  const [parcelaInicialAlteracao, setParcelaInicial] = useState(0);
  const [alterandoParcelas, setAlterandoParcelas] = useState(false);

  useEffect(() => {
    getParcelas();
    console.log("buscar parcelas")
  }, []);

  const getParcelas = () => {
    axios.get(`${URL_PAGAMENTO}/pedido/parcelas/${props.pedidoID}`, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
      }
    })
      .then((response) => {
        setParcelas(response.data);
      })
      .catch((error) => {
        toast.error("Não foi possível buscar os itens a serem distribuídos do contrato.");
      })
      .finally(() => {
        setAlterandoParcelas(false);
      });
  }

  const setUTCHour = (data) => {
    return new Date(data).setUTCHours(3);
  }

  const calcularDataMaxPagto = (qtdParcelasAEditar, dtParcela) => {
    let dataMaxima = new Date(setUTCHour(props.dataMaxPagto));
    const diasRemover = (30 * qtdParcelasAEditar) + 60;
    dataMaxima.setDate(dataMaxima.getDate() - diasRemover);
    setDataMaxPagto(dataMaxima);
    setDataMinPagto(new Date(setUTCHour(new Date())));
    setNovaDataPagamento(new Date(setUTCHour(dtParcela)));
  }

  function splitReverseJoin (split, join, data) {
    return data.split(split).reverse().join(join);
  }

  const alterarDataPagto = () => {
    setAlterandoParcelas(true);
    let mes = (novaDataPagamento.getUTCMonth() + 1);
    mes = mes < 10 ? "0"+mes : mes;
    axios.put(`${URL_PAGAMENTO}/alterar-data-cobranca`, {
      pedidoID: props.pedido.pedido,
      parcelaInicial: parcelaInicialAlteracao,
      dataInicial: novaDataPagamento.getUTCFullYear() + "-" + mes + "-" + novaDataPagamento.getUTCDate()
    }, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
      }
    })
      .then((response) => {
        alert("Data de pagamneto alterado");
        setParcelas(response.data);
        setNovaDataPagamento(null);
        setAlterarDataParcela(false);
      })
      .catch((error) => {
        alert("Erro ao alterar a data de pagamento. Tente novamente mais tarde!");
      })
      .finally(() => {
        setAlterandoParcelas(false);
      });
  }

  return (
    <React.Fragment>
      <div>
        {
          parcelas !== undefined && parcelas.length > 0 ?
            <div className="row">
              <div className="col-sm-12">
                <div className="box box-solid">
                  <div className="box-body">
                    <div className="table-responsive fixed">
                      <table className="table table-bordered table-striped">
                        <thead>
                        <tr>
                          <th>Nº</th>
                          <th>Valor</th>
                          <th>Data de vencimento</th>
                          <th>Status pagamento</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          parcelas.map((parcela, i) =>
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{parcela.valor}</td>
                              <td>
                                {splitReverseJoin("-", "/", parcela.dataVencimento)} {!parcela.pago ? <a onClick={() => {
                                setParcelaInicial(i + 1);
                                calcularDataMaxPagto(props.pedido.cobranca.parcelas.length - i, parcela.dataVencimento);
                                setAlterarDataParcela(true);
                              }}>Alterar data</a> : ''}
                              </td>
                              <td>{parcela.pago ? 'Pago' : 'Pendente'}</td>
                            </tr>
                          )
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ''
        }

        <Modal show={alterarDataParcela} onHide={() => setAlterarDataParcela(false)}>
          <ModalHeader closeButton>Alteração da data de pagamento</ModalHeader>
          <ModalBody>
            <Form>
              <div className="alert alert-info" style={{ fontSize: 14 }}>
                <p style={{ marginBottom: 5 }}><i style={{marginRight: 5}} className="fa fa-info-circle"/> ATENÇÃO:</p>
                <p style={{ marginBottom: 5 }}>Escolha a data da parcela no campo abaixo. Você poderá escolher uma data até o dia <strong>{dataMaxPagto !== null ? dataMaxPagto.getUTCDate() + "/" + (dataMaxPagto.getUTCMonth() + 1) + "/" + dataMaxPagto.getUTCFullYear() : ''}</strong>.</p>
                <p>OBS: Caso você tenha mais de uma parcela, elas serão agendadas para pagamento nos meses seguintes.</p>
              </div>

              <label><span className="perguntaObrigatoria">*</span>Data da parcela</label>
              <Row>
                <FormGroup>
                  <Col xs={12}>
                    <DatePicker
                      selected={novaDataPagamento}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      onChange={date => setNovaDataPagamento(date)}
                      locale="el"
                      filterDate={date => {
                        return new Date(setUTCHour(date)).getTime() >= new Date(setUTCHour(
                            dataMinPagto.getUTCFullYear() + "-" + (dataMinPagto.getUTCMonth() + 1) + "-" + dataMinPagto.getUTCDate()
                          )).getTime() &&
                          new Date(setUTCHour(date)).getTime() <= new Date(setUTCHour(dataMaxPagto)).getTime()
                      }}
                      placeholderText="Selecione uma data"
                      required
                    />
                    <button style={{ margin: "0 0 4px 15px" }}
                            disabled={alterandoParcelas}
                            onClick={(e) => {
                              e.preventDefault();
                              alterarDataPagto();
                            }}
                            className="btn btn-success btn-flat" button="button"> Alterar</button>
                  </Col>
                </FormGroup>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
}