import {toast} from 'react-toastify';

const INITIAL_STATE = {
  do_contrato: [],
  carregando: false,
  do_usuario:[],
  do_usuario_n_visualizado:[],
  erro: null,
  ultimo: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_ACAO':
      return {...state, carregando: true};
    case 'ERRO_ACAO':
      toast.error(action.erro.message, 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_ACOES_CONTRATO':
      return {...state, carregando: false, do_contrato: action.acoes};
    case 'NOVA_ACAO':
      return {...state, carregando: false};
    case 'OBTER_ACOES_ATOR':
      return {...state, carregando: false, do_usuario: action.acoes};
    case 'VISUALIZAR_ACAO':
      toast.success("marcado como visto!", 5000);
      console.log(action);
      return {...state, carregando: false, do_usuario_n_visualizado: state.do_usuario_n_visualizado.filter((acao) => {return acao.id!==action.acao.id}).concat(action.acao)};
    case 'OBTER_ACOES_ATOR_NV':
      //toast.success("obteve as notificacoes", 5000);
      return {...state, carregando: false, do_usuario_n_visualizado: action.acoes};
    default:
      return state;
  }
};
