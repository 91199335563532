const INITIAL_STATE = {
  atual: "/",
  titulo: ""
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case "CAMINHO_ATUAL":
      return {...state, atual: action.caminho};
    case "DEFINIR_TITULO":
      return {...state, titulo: action.titulo};
    default:
      return state;
  }
};
