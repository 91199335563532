const INITIAL_STATE = {
  carregando: false,
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR':
      return {...state, carregando: true};
    case 'ERRO':
      return {...state, carregando: false, erro: action.erro};
    case 'FINALIZAR':
      return {...state, carregando: false};
    default:
      return state;
  }
};