import React, {useEffect, useState} from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {URL_PAGAMENTO} from "../config/urls";
import TelegramService from "../services/TelegramService";
import {connect} from "react-redux";

function DistribuirItensContratados (props) {

  const [header, setHeader] = useState([]);
  const [pedidosVinculados, setPedidosVinculados] = useState([]);
  const [linhasBloqueadas, setLinhasBloqueadas] = useState([]);
  const [etapaConcluida, setEtapaConcluida] = useState(false);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    buscarItensDistribuicao();
    setEtapaConcluida(props.contrato.compras_comissao_distribuidas);
  }, [])

  const buscarItensDistribuicao = () => {
    axios.get(`${URL_PAGAMENTO}/comissao/itens-distrbuicao`, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
      }
    })
      .then((response) => {
        let itens = [];
        response.data.map((item)=> {
          itens.push({
            ...item,
            quantidadeDisponivel: 0
          })
        });
        setHeader(itens);
        buscarPedidosVinculados(itens);
      })
      .catch((erro) => {
        toast.error("Não foi possível buscar os itens a serem distribuídos do contrato.");
        new TelegramService().novo("ERROR", [
          JSON.stringify(erro),
          "Erro ao buscar os itens para distribuição da comissão",
          `usuarioLogado: ${sessionStorage.getItem("usuarioLogadoComissao")}`
        ]);
      });
  }

  const buscarPedidosVinculados = (itens) => {
    axios.get(`${URL_PAGAMENTO}/comissao/pedidos-vinculados`, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
      }
    })
      .then((response) => {
        let pedidos = [...response.data];

        pedidos.map((pedido) => {
          let novosItens = Array.apply(null, Array(itens.length)).map(function() { return {} });
          itens.map((item, index) => {
            let itensPedido = pedido.itens.filter((itemPedido) => {
              return itemPedido.id !== undefined && itemPedido.id === item.id
            });

            let qtdDistribuida = 0;
            if (itensPedido.length > 0) {
              qtdDistribuida = itensPedido[0].quantidade;
            }

            novosItens[index] = {
              id: item.id,
              nome: item.nome,
              quantidade: qtdDistribuida,
            };

            if (item.quantidadeDisponivel === 0) {
              item.quantidadeDisponivel = item.quantidade - qtdDistribuida;
            } else {
              item.quantidadeDisponivel -= qtdDistribuida;
            }
          });
          pedido.itens = novosItens;
        });
        setHeader(itens);
        setLinhasBloqueadas(Array.apply(null, Array(pedidos.length)).map(function() { return false }));
        setPedidosVinculados(pedidos);
      })
      .catch((erro) => {
        console.log(erro);
        toast.error("Não foi possível buscar os pedidos vinculados do contrato.");
        new TelegramService().novo("ERROR", [
          JSON.stringify(erro),
          "Erro ao buscar os itens para distribuição da comissão",
          `usuarioLogado: ${sessionStorage.getItem("usuarioLogadoComissao")}`
        ]);
      });
  }

  const alterarQuantidade = (pedidoIndex, itemID, novaQuantidade) => {
    if (!linhasBloqueadas[pedidoIndex]) {
      if (isNaN(novaQuantidade)) {
        novaQuantidade = 0;
      }
      let pedidos = [...pedidosVinculados];
      pedidos[pedidoIndex].itens.map((item, index) => {
        if (item.id === itemID) {
          pedidos[pedidoIndex].itens[index].quantidade = novaQuantidade
        }
      });
      setPedidosVinculados(pedidos);
    }
  }

  const ajustarQuantidadeDisponivel = async (itemID, pedidoIndex) => {
    bloquearRegistro(pedidoIndex, true);
    const itens = [...header];

    let novaQuantidade = 0;
    pedidosVinculados.map((pedido) => {
      pedido.itens.map((item) => {
        if (item.id === itemID) {
          novaQuantidade += item.quantidade;
        }
      });
    });

    const formandoID = pedidosVinculados[pedidoIndex].formandoID;
    let qtdDistribuida = 0;
    pedidosVinculados[pedidoIndex].itens.map((item) => {
      if (item.id === itemID) {
        qtdDistribuida = item.quantidade;
      }
    });
    let pedidoVinculado = await distribuirItem(formandoID, itemID, qtdDistribuida);
    pedidoVinculado = addItensNaoDistribuidos(itens, pedidoVinculado);

    bloquearRegistro(pedidoIndex, false);

    itens.map(async (item) => {
      if (item.id === itemID) {
        if (novaQuantidade > item.quantidade) {
          toast.error("Você tentou distribuir uma quantidade superior a que está disponível!");
        } else {
          item.quantidadeDisponivel = item.quantidade - novaQuantidade;
        }

        if (pedidoVinculado != null) {
          let pedidos = [...pedidosVinculados];
          pedidos[pedidoIndex] = pedidoVinculado;
          setPedidosVinculados(pedidos);
        }
      }
    });
    setHeader(itens);
  }

  const addItensNaoDistribuidos = (itens, pedido) => {
    let novosItens = Array.apply(null, Array(itens.length)).map(function() { return {} });
    itens.map((item, index) => {
      let itensPedido = pedido.itens.filter((itemPedido) => {
        return itemPedido.id !== undefined && itemPedido.id === item.id
      });

      let qtdDistribuida = 0;
      if (itensPedido.length > 0) {
        qtdDistribuida = itensPedido[0].quantidade;
      }

      novosItens[index] = {
        id: item.id,
        nome: item.nome,
        quantidade: qtdDistribuida,
      };
    });
    pedido.itens = novosItens;
    return pedido;
  }

  const bloquearRegistro = (index, bloquear) => {
    const linhas = [...linhasBloqueadas];
    linhas[index] = bloquear;
    setLinhasBloqueadas(linhas);
  }

  const distribuirItem = async (formandoID, itemID, quantidade) => {
    const pedidoVinculado = await axios.post(`${URL_PAGAMENTO}/comissao/distribuir-item`,
      {
        formandoID,
        itemID,
        quantidade
      },
      {
        headers: {
          "Authorization": "Bearer " + sessionStorage.getItem("tokenPagamento")
        }
      })
      .then((response) => {
        return response.data;
      })
      .catch((erro) => {
        toast.error("Não foi possível distribuir o item ao formnado.");
        new TelegramService().novo("ERROR", [
          JSON.stringify(erro),
          "Erro ao distribuir o item ao formando",
          `usuarioLogado: ${sessionStorage.getItem("usuarioLogadoComissao")}`
        ]);
        return null;
      });
    return pedidoVinculado;
  }

  function _renderTabelaProdutos () {
    const itens = header;
    return (
      <>
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <div className="box box-solid">
              <div className="box-header" style={{paddingBottom: 0}}>
                <div className="box-title">Produtos contratados</div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <table id="tableItems" className="table" style={{marginBottom: 0}}>
                    <thead style={{backgroundColor: "#222D32", color: "white"}}>
                    <tr>
                      <th>Produto</th>
                      <th>Qtd. contratada</th>
                    </tr>
                    </thead>
                    <tbody style={{backgroundColor: "white"}}>
                    {
                      itens.length > 0 ?
                        itens.map((item, i) => (
                          <tr key={i} style={{borderBottom: "1px solid #eee"}}>
                            <td>{item.nome}</td>
                            <td>{item.quantidade}</td>
                          </tr>
                        ))
                        :
                        <tr style={{borderBottom: "1px solid #eee"}}>
                          <td colSpan={4} style={{textAlign: "center"}}>Não há itens para distribuição!</td>
                        </tr>
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function finalizarDistribuicao() {
    setProgress(true);
    axios.post(`${URL_PAGAMENTO}/comissao/concluir-distrbuicao`, {
      concluir: true
    },{
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem("tokenPagamento")
      }
    })
      .then((response) => {
        toast.success("Etapa concluída!");
        setEtapaConcluida(true);
      })
      .catch((erro) => {
        console.log(erro);
        toast.error("Não foi possível buscar os pedidos vinculados do contrato.");
        new TelegramService().novo("ERROR", [
          JSON.stringify(erro),
          "Erro ao buscar os itens para distribuição da comissão",
          `usuarioLogado: ${sessionStorage.getItem("usuarioLogadoComissao")}`
        ]);
      })
      .finally(() => {
        setProgress(false);
      })
  }

  return (
    <>
      { _renderTabelaProdutos() }

      <div className="row">
        <div className="col-md-12">
          <div className="box box-solid">
            <div className="box-body">
              <div className="table-responsive fixed">
                <table id="tabelaDistribuicaoItens" className="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Formando</th>
                    {
                      header.map((colHeader, i) => (
                        <th key={i} title={colHeader.nome}>
                          <div style={{ padding: "0px 2px" }} className="alinhar-linha-around">
                            <div style={{ textTransform: "uppercase", fontSize: 10 }}>{colHeader.nome}</div>
                            <div style={{ marginLeft: 15 }}>{colHeader.quantidadeDisponivel} </div>
                          </div>
                        </th>
                      ))
                    }
                  </tr>
                  </thead>
                  <tbody>
                  {
                    pedidosVinculados.map((pedidoVinculado, pedidoIndex) => (
                      <tr key={pedidoIndex} className={linhasBloqueadas[pedidoIndex] ? "atualizando-registro" : ""}>
                        <td>
                          {pedidoVinculado.nomeFormando}
                        </td>
                        {
                          pedidoVinculado.itens.map((item, itemIndex) => (
                            <td id="noPadding" key={itemIndex}>
                              {
                                progress || etapaConcluida?
                                  <div className="inputNumberPersonalizado blocked">{item.quantidade}</div>
                                  :
                                  <input type="number" className="inputNumberPersonalizado"
                                         value={item.quantidade}
                                         onChange={(e) => alterarQuantidade(
                                           pedidoIndex, item.id, parseInt(e.target.value, 10)
                                         )}
                                         onBlur={(e) => ajustarQuantidadeDisponivel(item.id, pedidoIndex)} />
                              }
                            </td>
                          ))
                        }
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {
          !etapaConcluida ?
            <button className="btn btn-danger btn-lg btn-flat botaoFinalizar" data-toggle="modal" data-target="#modal-conclusao" style={{marginTop: 15}} disabled={progress}>
              {progress ? <span><i className="fa fa-spinner fa-pulse fa-fw"></i> Finalizando etapa</span> : "Finalizar etapa"}
            </button>
            :
            null
        }
      </div>

      <div className="modal fade" id="modal-conclusao">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
              <h4 className="modal-title">Tem certeza que deseja finalizar essa etapa?</h4>
            </div>
            <div className="modal-body">
              <p>Ao clicar no botão confirmar você comprova que todos os itens contratados foram distribuidos corretamente.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default pull-left flat" data-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-danger flat" data-dismiss="modal" onClick={() => finalizarDistribuicao()}>Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    contrato: state.contrato.especifico,
  }
}
export default connect(mapStateToProps, {})(DistribuirItensContratados)