import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todos: [],
  carregando: false,
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_PRODUTO':
      return {...state, carregando: true};
    case 'ERRO_PRODUTO':
      toast.error(action.erro.message, 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_TODOS_PRODUTOS':
      return {...state, carregando: false, todos: action.produtos};
    case 'NOVO_PRODUTO':
      toast.success("Produto cadastrado com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.concat(action.produto)};
    case 'ALTERAR_PRODUTO':
      toast.success("Produto alterado com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((produto) => {return produto.id!==action.produto.id}).concat(action.produto)};
    case 'REMOVER_PRODUTO':
      toast.success("Produto removido com sucesso.", 5000);
      return {...state, carregando: false, todos: state.todos.filter((produto) => {return produto.id!==action.id})};
    default:
      return state;
  }
};
