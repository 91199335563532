import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCHZNyuJAYORaRXofGNBd3Tn22li-24aXU",
  authDomain: "comissao-ce634.firebaseapp.com",
  databaseURL: "https://comissao-ce634.firebaseio.com",
  projectId: "comissao-ce634",
  storageBucket: "",
  messagingSenderId: "966425035848",
  appId: "1:966425035848:web:8f1b37e7696b62a3ec047d"
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseDatabase = firebase.database();