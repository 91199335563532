import axios from 'axios';
import {URL_MAPA} from '../config/urls';
import TelegramService from './TelegramService';

export default class MetricsServices {
	atualizarOrcamentoEKits (data) {
		return new Promise((resolve,reject) => {
			axios.post(`${URL_MAPA}/orcamento/alterar-kit`, data, {headers: {"Authorization": sessionStorage.getItem("tokenMapaVendas")}}).then((response)=>{
				resolve(response.data);
			}).catch((erro) => {
				new TelegramService().novo("ERROR", [
					JSON.stringify(erro), 
					`Não foi possível atualizar o orçamento e os kits | Data: ${JSON.stringify(data)}`, 
					`usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogadoComissao")).login}`
				]);
				reject(erro);
			});
		});			
	}

	consultarOrcamento (numero) {
		return new Promise((resolve,reject) => {
			axios.get(`${URL_MAPA}/orcamento/${numero}`, {headers: {"Authorization": sessionStorage.getItem("tokenMapaVendas")}}).then((response)=>{
				resolve(response.data);
			}).catch((erro) => {
				new TelegramService().novo("ERROR", [
					JSON.stringify(erro), 
					`Não foi possível consultar o orçamento de número ${numero}`, 
					`usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogadoComissao")).login}`
				]);
				reject(erro);
			});
		});			
	}

	consultarContrato (numero) {
		return new Promise((resolve,reject) => {
			axios.get(`${URL_MAPA}/contrato/parcelas/${numero}`, {headers: {"Authorization": sessionStorage.getItem("tokenMapaVendas")}}).then((response)=>{
				resolve(response.data);
			}).catch((erro) => {
				new TelegramService().novo("ERROR", [
					JSON.stringify(erro), 
					`Não foi possível consultar o contrato de número ${numero}`, 
					`usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogadoComissao")).login}`
				]);
				reject(erro);
			});
		});			
	}

	consultarParcelasDoOrcamento (numero) {
		return new Promise((resolve,reject) => {
			axios.get(`${URL_MAPA}/orcamento/${numero}/numero-parcelas`, {headers: {"Authorization": sessionStorage.getItem("tokenMapaVendas")}}).then((response)=>{
				resolve(response.data);
			}).catch((erro) => {
				new TelegramService().novo("ERROR", [
					JSON.stringify(erro), 
					`Não foi possível consultar as parcelas do orçamento de número ${numero}`, 
					`usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogadoComissao")).login}`
				]);
				reject(erro);
			});
		});	
	}
	
	consultarDadosPagamentoOrcamento (numero) {
		return new Promise((resolve,reject) => {
			axios.get(`${URL_MAPA}/orcamento/${numero}/info-pagamento`, {headers: {"Authorization": sessionStorage.getItem("tokenMapaVendas")}}).then((response)=>{
				resolve(response.data);
			}).catch((erro) => {
				new TelegramService().novo("ERROR", [
					JSON.stringify(erro), 
					`Não foi possível consultar as informações de pagamento do orçamento de número ${numero}`, 
					`usuarioLogado: ${JSON.parse(sessionStorage.getItem("usuarioLogadoComissao")).login}`
				]);
				reject(erro);
			});
		});	
	}

	getTabela (idOrcamento) {
		return new Promise((resolve,reject)=>{
			axios.get(`${URL_MAPA}/orcamento/${idOrcamento}/tabela`, {headers: {"Authorization": sessionStorage.getItem("tokenMapaVendas")}}).then((response)=>{
				resolve(response.data);
			}).catch((erro)=>{
				reject(erro);
			});
		});	
	}

	getItemKitById (id) {
		return new Promise((resolve,reject)=>{
			axios.get(`${URL_MAPA}/orcamentos/kit/item/${id}`, {headers: {"Authorization": sessionStorage.getItem("tokenMapaVendas")}}).then((response)=>{
				resolve(response.data);
			}).catch((erro)=>{
				reject(erro);
			});
		});			
	}
}