import {toast} from 'react-toastify';

const INITIAL_STATE = {
  todos: [],
  especifico: null,
  carregando: false,
  erro: null
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case 'REQUISITAR_CONVITE':
      return {...state, carregando: true};
    case 'ERRO_CONVITE':
      toast.error(action.erro.message, 5000);
      return {...state, carregando: false, erro: action.erro};
    case 'OBTER_CONVITE_PELO_CONTRATO':
      return {...state, carregando: false, especifico: action.convite, erro: null};
    default:
      return state;
  }
};
